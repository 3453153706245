import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import {
  operatorSelectedAction, cancelSelectedOperator, cancelRideByTraveler,
  getRideDetails, clearRideDetails, handleExtraStopByTraveler, choosePreferredOperator, saveRatingReview, sendChatMessage
} from '../../../store/actions/projectActions';
// import { callOperatorAction } from '../../../store/actions/authActions';
import OperatorProfile from './OperatorProfile';
import SelectedOperator from './SelectedOperator';
import M from "materialize-css";
import MapWithADirectionsRenderer from "../../maps/MapWithADirectionsRenderer";
import Loader1 from '../../loader/Loader1';
import Breadcrumb from "../../common/Breadcrumb";
import RideChatTimeline from '../RideChatTimeline';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { TiLocationOutline, TiLocation } from "react-icons/ti";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getVehicleTypeName } from '../../util/rideUtils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class TravelerRideDetails extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.selectOperatorFn = this.selectOperatorFn.bind(this);
    this.agreeBtnClicked = this.agreeBtnClicked.bind(this);
    this.cancelSelectOperatorFn = this.cancelSelectOperatorFn.bind(this);
    this.cancelOperatorBtnClicked = this.cancelOperatorBtnClicked.bind(this);
    this.cancelRideByTraveler = this.cancelRideByTraveler.bind(this);
    this.choosePreferredOperatorFn = this.choosePreferredOperatorFn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ratingChanged = this.ratingChanged.bind(this);
    this.submitRatingReview = this.submitRatingReview.bind(this);
    this.editReviewRatingFn = this.editReviewRatingFn.bind(this);
    this.sendChatMessage = this.sendChatMessage.bind(this);
  }

  initialState() {
    return {
      projectId: '',
      acceptedByOperatorId: '',
      selectedOperatorId: '',
      selectedOperatorName: '',
      selectedOperatorPhone: '',
      currentExtraStop: {},
      rating: 0,
      review: '',
      editReviewRating: false,
      newChatMessage: ''
    }
  }

  // callOperator = (async () => {
  //   await this.props.callOperatorAction(this.props.project.confirmedByOperatorId);
  // })

  cancelRideByTraveler = () => {
    this.setState({
      projectId: this.props.projectId,
      requestorId: this.props.auth.uid
    }, () => {
      var elems = document.getElementById('cancelRideModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToCancelRide = () => {
    this.props.cancelRideByTravelerAction(this.state);
  }

  acceptExtraStop = (index) => {
    let extraStop = this.props.project.operatorData[this.props.project.confirmedByOperatorId].extraStops[index];
    extraStop.index = index;
    this.setState({
      currentExtraStop: extraStop,
    }, () => {
      var elems = document.getElementById('acceptExtraChargeModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToAcceptExtraStop = () => {
    this.props.handleExtraStopAction({
      extraStop: this.state.currentExtraStop,
      action: 'accept',
      projectId: this.props.projectId
    })
  }

  denyExtraStop = (index) => {
    let extraStop = this.props.project.operatorData[this.props.project.confirmedByOperatorId].extraStops[index];
    extraStop.index = index;
    this.setState({
      currentExtraStop: extraStop,
    }, () => {
      var elems = document.getElementById('denyExtraChargeModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToDenyExtraStop = () => {
    this.props.handleExtraStopAction({
      extraStop: this.state.currentExtraStop,
      action: 'deny',
      projectId: this.props.projectId
    });
  }

  selectOperatorFn = (name, operatorId, phone) => {
    this.setState({
      projectId: this.props.projectId,
      selectedOperatorName: name,
      selectedOperatorId: operatorId,
      selectedOperatorPhone: phone
    }, () => {
      var elems = document.getElementById('selectOperatorModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeBtnClicked = () => {
    this.props.operatorSelectedAction(this.state);
  }

  cancelSelectOperatorFn = (name, operatorId, phone) => {
    this.setState({
      projectId: this.props.projectId,
      selectedOperatorName: name,
      selectedOperatorId: operatorId,
      selectedOperatorPhone: phone
    }, () => {
      var elems = document.getElementById('cancelOperatorModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  cancelOperatorBtnClicked = () => {
    this.props.cancelSelectedOperator(this.state);
  }

  choosePreferredOperatorFn = (operatorId) => {
    this.props.choosePreferredOperator({
      operatorId: operatorId,
      requestorId: this.props.auth.uid
    })
  }

  ratingChanged = (rating) => {
    this.setState({
      rating: rating
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  submitRatingReview = () => {
    var elems = document.getElementById('confirmRatingReviewModal');
    M.Modal.init(elems, {});
    M.Modal.getInstance(elems).open();
  }

  confirmRatingReview = async (operatorId, reviewId) => {
    if (reviewId) {
      await this.props.saveRatingReview({
        reviewId: reviewId,
        rating: this.state.rating,
        review: this.state.review,
        operatorId: operatorId,
        requestorId: this.props.auth.uid,
        rideId: this.props.projectId
      })
    } else {
      await this.props.saveRatingReview({
        rating: this.state.rating,
        review: this.state.review,
        operatorId: operatorId,
        requestorId: this.props.auth.uid,
        rideId: this.props.projectId
      })
    }
    //reset the edit mode to false
    this.setState({
      editReviewRating: false
    })
  }

  editReviewRatingFn = (editMode, currentRating, currentReview) => {
    if (currentRating || currentReview) {
      this.setState({
        editReviewRating: editMode,
        rating: currentRating || this.state.rating,
        review: currentReview || this.state.review
      })
    } else {
      this.setState({
        editReviewRating: editMode,
      })
    }
  }

  sendChatMessage = (msg) => {
    this.setState({
      newChatMessage: '',
    }, () => {
      this.props.sendChatMessage({
        projectId: this.props.projectId,
        msg: msg,
        userId: this.props.auth.uid,
        userName: this.props.profile.firstName,
      })
    })
  }

  componentDidMount() {
    if (this.props.projectId) {
      this.props.getRideDetails(this.props.projectId);
    }
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    this.props.clearRideDetails();
  }

  render() {
    const { project, auth, projectId, profile } = this.props;
    let rideStatus = null;
    if (!auth.uid) return <Redirect to='/login' />
    if (project && auth && (project === 'badData' || project.authorId !== auth.uid)) {
      return (
        <Redirect to='/404' />
      )
    }
    if (auth && project && profile) {

      let rideCancellation = (project.status && project.status === 'CANCELLED') ?
        null
        : (
          <p className="smallerFont75 red-text">If you want to cancel this ride, please click <span style={{ textDecoration: 'underline' }} onClick={this.cancelRideByTraveler}> here </span>.</p>
        );

      let chatWithOperator = (
        <>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="newChatMessage">Enter your message</label>
              <textarea id="newChatMessage" className="materialize-textarea has-character-counter"
                value={this.state.newChatMessage}
                onChange={this.handleChange}
                data-length="120"
                maxLength="120"></textarea>
            </div>
            <div className="col-lg-6 mt--20">
              <button className="rn-button-style--2 btn-solid"
                disabled={this.state.newChatMessage === '' || this.state.newChatMessage.trim() === ''}
                onClick={() => { this.sendChatMessage(this.state.newChatMessage) }}>Send</button>
            </div>
          </div>
          <div className="row mt--20">
            <div className="col-lg-12">
              <RideChatTimeline
                uid={this.props.auth.uid}
                chats={project.chats} />
            </div>
          </div>
        </>
      );

      let acceptedPreferredOperatorDiv = null;
      let acceptedRidesDiv = null;
      if (project.acceptedBy && project.acceptedBy.length > 0) {
        //ride is accepted
        if (project.status.toUpperCase() === 'PENDING') {
          let acceptedPreferredOperatorsDiv = project.acceptedBy && project.acceptedBy.map(operator => {
            if (profile.preferredOperators && profile.preferredOperators.includes(operator)) {
              return (
                <OperatorProfile
                  key={operator}
                  operatorId={operator}
                  selectOperatorFn={this.selectOperatorFn}
                  cancelSelectOperatorFn={this.cancelSelectOperatorFn}
                  selectedOperator={project.selectedOperatorId}
                  confirmedByOperatorId={project.confirmedByOperatorId}
                  operatorData={project.operatorData && project.operatorData[operator] ? project.operatorData[operator] : null}
                  operatorProfile={project.operatorProfile && project.operatorProfile[operator] ? project.operatorProfile[operator] : null}
                  project={project}
                  preferredOperator={true} />
              )
            } else {
              return null;
            }
          });

          let acceptedOperatorsDiv = project.acceptedBy && project.acceptedBy.map(operator => {
            if ((!profile.preferredOperators) || (profile.preferredOperators && !profile.preferredOperators.includes(operator))) {
              return (
                <OperatorProfile
                  key={operator}
                  operatorId={operator}
                  selectOperatorFn={this.selectOperatorFn}
                  cancelSelectOperatorFn={this.cancelSelectOperatorFn}
                  selectedOperator={project.selectedOperatorId}
                  confirmedByOperatorId={project.confirmedByOperatorId}
                  operatorData={project.operatorData && project.operatorData[operator] ? project.operatorData[operator] : null}
                  operatorProfile={project.operatorProfile && project.operatorProfile[operator] ? project.operatorProfile[operator] : null}
                  project={project}
                  preferredOperator={false} />
              )
            } else {
              return null;
            }
          })

          acceptedPreferredOperatorDiv = (
            <>
              {acceptedPreferredOperatorsDiv}
            </>
          )

          acceptedRidesDiv = (
            <>
              {acceptedOperatorsDiv}
            </>
          )
        }

        let selectedOperatorData = project.operatorData ? project.operatorData[project.confirmedByOperatorId] : null;
        let selectedOperatorDiv = selectedOperatorData ?
          (
            <SelectedOperator
              project={project}
              cancelSelectOperatorFn={this.cancelSelectOperatorFn}
              selectedOperator={project.selectedOperatorId}
              confirmedByOperatorId={project.confirmedByOperatorId}
              operatorData={selectedOperatorData}
              choosePreferredOperatorFn={this.choosePreferredOperatorFn}
              preferredOperator={profile.preferredOperators && profile.preferredOperators.includes(project.confirmedByOperatorId)}
              operatorProfile={project.operatorProfile && project.operatorProfile[project.confirmedByOperatorId] ? project.operatorProfile[project.confirmedByOperatorId] : null}
              rating={this.state.rating}
              review={this.state.review}
              ratingChanged={this.ratingChanged}
              handleChange={this.handleChange}
              submitRatingReview={this.submitRatingReview}
              confirmRatingReview={this.confirmRatingReview}
              editReviewRating={this.state.editReviewRating}
              editReviewRatingFn={this.editReviewRatingFn}
              newChatMessage={this.state.newChatMessage}
              sendChatMessage={this.sendChatMessage}
              uid={this.props.auth.uid}
            />
          ) : null;


        let extraStopsSection = selectedOperatorData && selectedOperatorData.extraStops
          ? Object.entries(selectedOperatorData.extraStops).map(([key, extraStop]) => {
            return (
              <TableRow key={key}>
                <TableCell>${extraStop.extraStopsCharges}</TableCell>
                <TableCell>{extraStop.extraStopsNotes}</TableCell>
                <TableCell>{extraStop.status}</TableCell>
                {
                  extraStop.status === 'PENDING TRAVELER APPROVAL'
                    ? <>
                      <TableCell>
                        <button className="rn-button-style--2 btn-solid modal-close btn-margin-5"
                          onClick={() => this.acceptExtraStop(key)}>Accept</button>
                      </TableCell>
                      <TableCell>
                        <button className="rn-button-style--2 btn-solid modal-close btn-margin-5"
                          onClick={() => this.denyExtraStop(key)}>Deny</button>
                      </TableCell>
                    </>
                    : <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                }

              </TableRow>
            )
          })
          : null; //<div className="grey-text">No extra stops added yet</div>;

        // let callOperatorSection = null;
        var operatorSection = null;
        var preferredOperatorSection = null;
        var rideStatusHeading = null;

        if (project.status.toUpperCase() === 'CANCELLED') {
          selectedOperatorDiv = null;
          preferredOperatorSection = null;
          operatorSection = null;
          rideStatusHeading = null;
        } else if (project.status.toUpperCase() === 'COMPLETED') {
          // selectedOperatorDiv = selectedOperatorDiv;
          preferredOperatorSection = null;
          operatorSection = null;
          rideCancellation = null;
          rideStatusHeading = "Details of the selected operator";
        } else if (project.status.toUpperCase() === 'CONFIRMED') {
          // selectedOperatorDiv = selectedOperatorDiv;
          preferredOperatorSection = null;
          operatorSection = null;
          rideCancellation = null;
          rideStatusHeading = "Details of the selected operator";
        } else if (project.status.toUpperCase() === 'PENDING' && project.selectedOperatorId) {
          // selectedOperatorDiv = selectedOperatorDiv;
          operatorSection = acceptedRidesDiv;
          preferredOperatorSection = acceptedPreferredOperatorDiv;
          rideStatusHeading = "Please select an operator";
        } else if (project.status.toUpperCase() === 'PENDING') {
          selectedOperatorDiv = null;
          operatorSection = acceptedRidesDiv;
          preferredOperatorSection = acceptedPreferredOperatorDiv;
          rideStatusHeading = "Please select an operator";
        } else {
          operatorSection = null;
        }
        rideStatus = (project.status && project.status === 'CANCELLED') ?
          (
            <h5 className="red-text">Status: {project.status.toUpperCase()}</h5>
          )
          : (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <p>Status: {project.status.toUpperCase()}</p>
                </div>
              </div>

              <div className="row mt--20">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <h5>{rideStatusHeading}</h5>
                  {selectedOperatorDiv}
                  {preferredOperatorSection}
                  {operatorSection}
                </div>
              </div>

              <br />
              {extraStopsSection &&
                <Card variant="outlined">
                  <CardContent>
                    <h5>EXTRA STOPS</h5>
                    <Table aria-label="extra stops table">
                      <TableBody>
                        {extraStopsSection}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              }
            </>
          )
      } else {
        //ride isn't accepted yet
        rideStatus = (project.status && project.status === 'CANCELLED') ?
          (<div>
            <h5 className="red-text">Status: {project.status.toUpperCase()}</h5>
          </div>)
          : (<div>
            <h4><b>Status: {project.status.toUpperCase()}. </b></h4>
            <p>Please wait while operators accept this ride request.</p>
          </div>
          )
      }
      const fromAddressLink = `geo:${project.fromlat},${project.fromlng}`
      const toAddressLink = `geo:${project.tolat},${project.tolng}`
      const linkToSourceAddressGoogle = `https://maps.google.com/?q=${project.fromlat},${project.fromlng}`
      const linkToSourceAddressApple = `https://maps.apple.com/maps?q=${project.fromlat},${project.fromlng}`
      const linkToDestinationAddressGoogle = `https://maps.google.com/?q=${project.tolat},${project.tolng}`
      const linkToDestinationAddressApple = `https://maps.apple.com/maps?q=${project.tolat},${project.tolng}`

      return (
        <>
          <Breadcrumb title1={'Ride Details'} title={projectId} parent='Rides' parentLink='/rides' />
          <main className="contact-form--1 main_container mt--40 mb--30 bg_color--1">
            <div className="section-title text-left">
              {/* <p><b>Ride ID:</b> {projectId}</p> */}
              <p><b>Ride Code:</b> {project.rideConfirmationCode ? project.rideConfirmationCode : 'N/A'}</p>
            </div>

            <Tabs className="mb--40">
              <div className="tablist-inner">
                <TabList className="pv-tab-button text-center">
                  <Tab><span>RIDE DETAILS</span></Tab>
                  <Tab><span>CHAT</span></Tab>
                  <Tab><span>MAP</span></Tab>
                </TabList>
              </div>

              <TabPanel>
                <div className="single-tab-content mt--20">
                  <div className="row mb--40 plr-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt--20">
                      <div className="rn-address1 bg_color--5">
                        <u><h3> RIDE DETAILS</h3></u>
                        <Table aria-label="ride details table">
                          <TableBody>
                            <TableRow>
                              <TableCell><b>Date</b></TableCell>
                              <TableCell>{moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY")}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Time</b></TableCell>
                              <TableCell>{moment(project.serviceDate.toDate()).format("LT")} </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>From</b></TableCell>
                              {/* <TableCell><a className="underline" href={linkToSourceAddressGoogle}>
                                <a href={linkToSourceAddressApple}>
                                  {project.serviceFrom}</a>
                              </a></TableCell> */}
                              <TableCell><a className="underline" href={fromAddressLink}>{project.serviceFrom}</a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>To</b></TableCell>
                              {/* <TableCell><a className="underline" href={linkToDestinationAddressGoogle}>
                                <a href={linkToDestinationAddressApple}>
                                  {project.serviceTo}</a>
                              </a></TableCell> */}
                              <TableCell><a className="underline" href={toAddressLink}>{project.serviceTo}</a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Ride Distance</b></TableCell>
                              <TableCell>{project.calculatedDistance.toFixed(2) + ' miles'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Duration</b></TableCell>
                              <TableCell> {project.calculatedRideDuration}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Ride Rate</b> </TableCell>
                              <TableCell>{project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Service Type</b></TableCell>
                              <TableCell>{project.serviceType}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Vehicle Type</b></TableCell>
                              <TableCell>{getVehicleTypeName(project.serviceVehicle)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Special Notes</b></TableCell>
                              <TableCell> {project.specialNotes || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Passenger Name</b></TableCell>
                              <TableCell>{project.passengerFirstName ? project.passengerFirstName : 'Not specified'} {project.passengerLastName ? project.passengerLastName : 'Not specified'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><b>Passenger Phone</b></TableCell>
                              <TableCell> {project.passengerPhone ?
                                <a className="underline" href={`tel:${project.passengerPhone}`}>{project.passengerPhone}</a>
                                : 'Not specified'}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt--20">
                      <div className="rn-address1">
                        <u><h3>RIDE STATUS</h3></u>
                        {rideStatus}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>


              <TabPanel>
                <div className="single-tab-content mt--20">
                  {project.confirmedByOperatorId ? chatWithOperator
                    : (<div className="row">
                      <div className="col-12">
                        <p>Chat feature is available when the ride is confirmed by an operator.</p>
                      </div>
                    </div>)}
                </div>
              </TabPanel>

              <TabPanel>
                <div className="single-tab-content mt--20">
                  <MapWithADirectionsRenderer
                    fromlat={project.fromlat}
                    fromlng={project.fromlng}
                    tolat={project.tolat}
                    tolng={project.tolng}
                  />
                </div>
              </TabPanel>

            </Tabs>

            <div className="row pv-tab-button">
              <div className="col-12 ptb--10 text-right">
                <p>Request submitted on {moment(project.createdAt.toDate()).format("LLLL")}</p>
                {rideCancellation}
              </div>
            </div>

            <div id="selectOperatorModal" className="modal">
              <div className="modal-content1 modalForm">
                <i className="material-icons modal-close float-right">close</i>
                <h4 className="red-text center">Please confirm your operator selection</h4>
                <Table aria-label="select operator ride quote table">
                  <TableBody>
                    <TableRow>
                      <TableCell><b>Operator Name</b></TableCell>
                      <TableCell><b>{this.state.selectedOperatorName.toUpperCase()}</b></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ride Rate</TableCell>
                      <TableCell>{project.calculatedPrice}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Tolls</TableCell>
                      <TableCell>{project.operatorData && this.state.selectedOperatorId ? "$" + project.operatorData[this.state.selectedOperatorId].additionalChargesTolls : '$0.00'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Parking</TableCell>
                      <TableCell>{project.operatorData && this.state.selectedOperatorId ? "$" + project.operatorData[this.state.selectedOperatorId].additionalChargesParking : '$0.00'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Airport Fee</TableCell>
                      <TableCell>{project.operatorData && this.state.selectedOperatorId ? "$" + project.operatorData[this.state.selectedOperatorId].additionalChargesAirportFee : '$0.00'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Other charges</TableCell>
                      <TableCell>{project.operatorData && this.state.selectedOperatorId ? "$" + project.operatorData[this.state.selectedOperatorId].additionalChargesMisc : '$0.00'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Special Notes</TableCell>
                      <TableCell>{project.operatorData && this.state.selectedOperatorId ? project.operatorData[this.state.selectedOperatorId].specialNotes : '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Rate</TableCell>
                      <TableCell>{project.operatorData && this.state.selectedOperatorId ? project.operatorData[this.state.selectedOperatorId].totalRate : null}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <br />
                <div className="center">
                  <button className="rn-button-style--2 btn-solid modal-close"
                    onClick={this.agreeBtnClicked}>Confirm</button>
                  <button className="rn-button-style--2 btn-solid modal-close ml--30">Cancel</button>
                </div>
              </div>
            </div>

            <div id="cancelOperatorModal" className="modal">
              <div className="modal-content1 center modalForm">
                <i className="material-icons modal-close float-right">close</i>
                <div className="center">
                  <h5>Are you sure you want to cancel this operator?</h5>
                  <hr />
                  <p>{this.state.selectedOperatorName.toUpperCase()}</p>
                  <hr />
                  <button className="rn-button-style--2 btn-solid modal-close"
                    onClick={this.cancelOperatorBtnClicked}>Cancel</button>
                </div>
              </div>
            </div>

            <div id="acceptExtraChargeModal" className="modal">
              <div className="modal-content1 center modalForm">
                <i className="material-icons modal-close float-right">close</i>
                <div className="center">
                  <h5>Are you sure you want to accept the extra charge?</h5>
                  <br />
                  Extra Stop Charges: ${this.state.currentExtraStop.extraStopsCharges}
                  <br />
                  Extra Stop Notes: {this.state.currentExtraStop.extraStopsNotes}
                  <hr />
                  <br />
                  <div>
                    <button className="rn-button-style--2 btn-solid modal-close" style={{ marginRight: '10px' }}>No</button>
                    <button className="rn-button-style--2 btn-solid modal-close"
                      onClick={this.agreeToAcceptExtraStop}>Yes</button>
                  </div>
                </div>
              </div>
            </div>

            <div id="denyExtraChargeModal" className="modal">
              <div className="modal-content1 center modalForm">
                <i className="material-icons modal-close float-right">close</i>
                <div className="center">
                  <h5>Are you sure you want to deny the extra stop?</h5>
                  <br />
                  Extra Stop Charges: ${this.state.currentExtraStop.extraStopsCharges}
                  <br />
                  Extra Stop Notes: {this.state.currentExtraStop.extraStopsNotes}
                  <hr />
                  <br />
                  <div>
                    <button className="rn-button-style--2 btn-solid modal-close" style={{ marginRight: '10px' }}>No</button>
                    <button className="rn-button-style--2 btn-solid modal-close"
                      onClick={this.agreeToDenyExtraStop}>Yes</button>
                  </div>
                </div>
              </div>
            </div>

            <div id="cancelRideModal" className="modal">
              <div className="modal-content1 center modalForm">
                <i className="material-icons modal-close float-right">close</i>
                <div className="center">
                  <h5>Are you sure you want to cancel the ride?</h5>
                  {
                    (moment(project.serviceDate.toDate()).diff(moment(new Date()), 'minutes') <= 5)
                      ? <p>You will be charged $5.00 cancellation fee.</p>
                      : project.acceptedBy && project.acceptedBy.length > 0 && project.confirmedByOperatorId && (moment(project.serviceDate.toDate()).diff(moment(new Date()), 'hours') <= project.operatorProfile[project.confirmedByOperatorId].cancellationPolicy)
                        ? <p>You will be charged full amount for this ride if you cancel.</p>
                        : project.acceptedBy && project.acceptedBy.length > 0 && project.confirmedByOperatorId && (moment(project.serviceDate.toDate()).diff(moment(new Date()), 'hours') > project.operatorProfile[project.confirmedByOperatorId].cancellationPolicy)
                          ? <p>You will be charged $5.00 cancellation fee.</p>
                          : project.firstOperatorRespondedWithin5Mins === true
                            ? <p>You will be charged $5.00 cancellation fee.</p>
                            : null
                  }
                  <br />
                  <hr />
                  <br />
                  <div>
                    <button className="rn-button-style--2 btn-solid modal-close" style={{ marginRight: '10px' }}>No</button>
                    <button className="rn-button-style--2 btn-solid modal-close"
                      onClick={this.agreeToCancelRide}>Yes</button>
                  </div>
                </div>
              </div>
            </div>

          </main>
        </>
      )
    } else {
      return (
        <>
          <Breadcrumb title1={'Ride Details'} title={projectId} parent='Rides' parentLink='/rides' />
          <main className="contact-form--1 main_container mt--40 mb--30 bg_color--1">
            <div className="section-title text-left">
              <p className="description">Loading</p>
              <Loader1 />
            </div>
          </main>
        </>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const project = state.project.rideDetails;
  return {
    project: project,
    auth: state.firebase.auth,
    projectId: id,
    profile: state.firebase.profile,
    callError: state.auth.callError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRideDetails: (projectId) => dispatch(getRideDetails(projectId)),
    operatorSelectedAction: (operatorSelected) => dispatch(operatorSelectedAction(operatorSelected)),
    cancelSelectedOperator: (operatorCancelled) => dispatch(cancelSelectedOperator(operatorCancelled)),
    cancelRideByTravelerAction: (requestorCancelledRide) => dispatch(cancelRideByTraveler(requestorCancelledRide)),
    // callOperatorAction: (operatorPhone) => dispatch(callOperatorAction(operatorPhone)),
    clearRideDetails: (project) => dispatch(clearRideDetails(project)),
    handleExtraStopAction: (obj) => dispatch(handleExtraStopByTraveler(obj)),
    choosePreferredOperator: (obj) => dispatch(choosePreferredOperator(obj)),
    saveRatingReview: (obj) => dispatch(saveRatingReview(obj)),
    sendChatMessage: (obj) => dispatch(sendChatMessage(obj)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelerRideDetails)
