import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import { getStripeCustomerPortalLink } from '../../store/actions/authActions';
import moment from 'moment';
import Breadcrumb from "../common/Breadcrumb";
import OperatorPricingTable from './OperatorPricingTable';
import { FiCheck } from "react-icons/fi";
import { baseURL, stripePublishableKey } from "../constants/CommonConstants";
var stripe = window.Stripe(stripePublishableKey);

class Subscription extends Component {

    constructor(props) {
        super(props)
        this.state = this.initialState();
        this.handleClick = this.handleClick.bind(this);
    }

    initialState() {
        return {
            sessionId: '',
            redirect: false
        }
    }

    componentDidMount() {
    }

    viewStripeSubscriptionPortal = (e) => {
        this.props.getStripeCustomerPortalLink({
            uid: this.props.auth.uid
        });
        this.setState({
            redirect: true
        });
    }

    redirect = (url) => {
        window.location = url;
    }

    handleClick = (e) => {
        const { auth, profile } = this.props;
        let type = null;
        switch (e.target.id) {
            case 'opPrem':
                type = 'opPrem';
                break;
            default:
                type = null;
                break;
        }

        if (type === null) {
            return;
        }

        let url = baseURL + '/getStripeSession';
        let data = {
            email: auth.email,
            type: type,
            customer: profile.stripeCustomerId || null
        };

        axios.post(url, data)
            .then(response => {
                //redirect to checkout page
                stripe.redirectToCheckout({
                    sessionId: response.data
                }).then(function (result) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                });
            })
            .catch(err => {
                return;
            });
    }

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (!profile.isEmpty && profile.accountType === 'Traveler') {
            return <Redirect to='/404' />
        }

        if (profile.isEmpty) {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />

                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Subscription</h2>
                                    <p className="description">Loading</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else if (!profile.approved) {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h3 className="description error-text center">Please wait for your account to be reviewed. </h3>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }

        if (this.state.redirect && this.props.stripeCustomerPortalUrl) {
            this.redirect(this.props.stripeCustomerPortalUrl);
        }

        // console.log('1', profile);

        if (profile && profile.accountType === 'Operator') {
            if (profile.stripeSubscribedUser) {
                //user is currently subscribed
                return (
                    <>

                        <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />

                        <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h2 className="title">Your subscription is active</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-3 col-12 mt--30">
                                    <div className="rn-pricing">
                                        <div className="pricing-table-inner">
                                            <div className="pricing-header">
                                                {/* <h4 className="title">Premium</h4> */}
                                                <div className="pricing">
                                                    <span className="price">$49</span>
                                                    <span className="subtitle">Per Month</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1 center">
                                                            {/* <li>Subscribed on {moment(profile.stripeSubscriptionCreatedAt * 1000).format("MMMM Do YYYY")}</li> */}
                                                            <li>Current subscription expires on {moment(profile.stripeSubscriptionExpiresAt * 1000).format("MMMM Do YYYY")}</li>
                                                            <li>The subscription will auto renew. You can manage your subscription from the stripe subscription dashboard.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1">
                                                            <li><FiCheck />Accept and fulfill unlimited rides</li>
                                                            <li><FiCheck />Be visible to travelers</li>
                                                            <li><FiCheck />Provide your own rate for rides and extra/additional stops</li>
                                                            <li><FiCheck />Become a preferred operator</li>
                                                            <li><FiCheck />View your rides history</li>
                                                            <li><FiCheck />Modify or cancel subscription anytime</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1">
                                                            <li><FiCheck />Instant quotes</li>
                                                            <li><FiCheck />On-demand and future bookings</li>
                                                            {/* <li><FiCheck />Submit unlimited ride requests without paying ride booking fee</li> */}
                                                            <li><FiCheck />Provide your preferred ride rate</li>
                                                            {/* <li><FiCheck />Select an operator of choice for your ride</li> */}
                                                            <li><FiCheck />Easily mangage your passengers for frequent booking</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-footer">
                                                <button className="rn-btn" onClick={this.viewStripeSubscriptionPortal}>View Stripe Subscription</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )
            } else {
                //not subscribed, show operator plans
                return (
                    <>
                        <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />
                        <OperatorPricingTable />
                    </>
                )
            }
        } else {
            //error
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--50">
                                    <h2 className="title">Subscription</h2>
                                    <p className="description">An unexpected error has occurred. Please try again.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        stripeCustomerPortalUrl: state.auth.stripeCustomerPortalUrl,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStripeCustomerPortalLink: (obj) => dispatch(getStripeCustomerPortalLink(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
