import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import OperatorTableData from './OperatorTableData';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';

function createData(id, firstName, lastName, email, phone, accountType, viewDetailsBtn) {
    return { id, firstName, lastName, email, phone, accountType, action: viewDetailsBtn };
}

class SearchResultList extends Component {

    render() {
        const { searchResultList } = this.props;
        // console.log('searchResultList = ', searchResultList);
        if (!searchResultList) {
            return (<></>)
        } else if (searchResultList.length > 0) {
            let finalData = [];

            searchResultList.forEach(user => {
                let viewDetailsBtn = (
                    <Link to={'/admin/searchOperatorProfile/' + user.uid}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
                );

                finalData.push(
                    createData(
                        user.uid,
                        user.firstName,
                        user.lastName,
                        user.email,
                        user.phone,
                        user.accountType,
                        viewDetailsBtn
                    )
                )
            })
            return (
                <>
                    <React.Fragment>
                        <div className="col-md-12 col-sm-12">
                            <h3 className="description mb--20">Search Result List</h3>
                            {
                                finalData.length >= 0
                                    ? <OperatorTableData rows={finalData} />
                                    : <p className="center red-text">No pending approvals</p>
                            }
                        </div>
                    </React.Fragment>
                </>)
        } else {
            return (
                <>
                    <div className="section-title text-center mb--50">
                        <p className="description red-text">No results found</p>
                    </div>
                </>
            )
        }

    }
}

export default SearchResultList
