import React, { Component } from 'react'
import ReactStars from "react-rating-stars-component";
import M from "materialize-css";
import { Link } from 'react-router-dom';
import { StorageBaseURL, altMedia } from "../../constants/CommonConstants";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Loader1 from '../../loader/Loader1';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

class SelectedOperator extends Component {

    constructor(props) {
        super(props);
        this.starRatingScale = this.starRatingScale.bind(this);
    }

    componentDidMount() {
        let charCounter = document.querySelectorAll('.has-character-counter');
        M.CharacterCounter.init(charCounter);
    }

    starRatingScale(rating) {
        let ratingName = ''
        switch (rating) {
            case 1:
                ratingName = 'Very Poor'
                break;

            case 2:
                ratingName = 'Poor'
                break;

            case 3:
                ratingName = 'Fair'
                break;

            case 4:
                ratingName = 'Good'
                break;

            case 5:
                ratingName = 'Excellent'
                break;

            default:
                break;
        }
        return ratingName;
    }

    render() {
        const { project, operatorProfile, selectedOperator,
            confirmedByOperatorId, operatorData, preferredOperator, rating } = this.props;

        if (operatorProfile) {
            if (operatorProfile === 'BadData' || operatorProfile.accountType === 'Traveler') {
                return (
                    <div>Something went wrong.</div>
                )
            } else {

                var updatedProfileImageUrl = operatorProfile.profileImageUrl ? StorageBaseURL + encodeURIComponent(selectedOperator + '/profile/thumb_' + operatorProfile.profileImageUrl) + altMedia : null;
                let selectedOperatorStatus = null;
                let buttonElement = null;
                let makePreferredOperatorSection = null;
                let rateReviewOperator = null;
                let confirmedRides = (operatorProfile.confirmedRides && operatorProfile.confirmedRides.length) || 0;
                let acceptedRides = (operatorProfile.acceptedRides && operatorProfile.acceptedRides.length) || 0;
                let cancelledConfirmedRides = (operatorProfile.cancelledConfirmedRides && operatorProfile.cancelledConfirmedRides.length) || 0;
                let cancellationPolicy = operatorProfile.cancellationPolicy || 'Not Specified';

                if (project && project.cancelledConfirmedRideBy && project.cancelledConfirmedRideBy.includes(selectedOperator)) {
                    selectedOperatorStatus = <span className="red-text">No longer available. Please select another operator.</span>
                } else if (confirmedByOperatorId) {
                    selectedOperatorStatus = <span className="green-text">Confirmed ride</span>;
                    makePreferredOperatorSection = (
                        <span style={{ textDecoration: 'underline' }} onClick={() => this.props.choosePreferredOperatorFn(selectedOperator)}>Make preferred operator</span>
                    );

                    if (project && project.status.toUpperCase() === 'COMPLETED') {
                        if (project.reviewId && project.reviewDetails) {
                            if (this.props.editReviewRating === true) {
                                //edit existing review
                                rateReviewOperator = (
                                    <div>
                                        <ul className="collapsible">
                                            <li className="active">
                                                <div className="collapsible-header ul-header">
                                                    <u><h3>Ride rating and review</h3></u>
                                                </div>
                                                <div className="collapsible-body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 col-lg-12">
                                                            <div>
                                                                <p>How was your ride? Rate your operator service level on a scale of 5 stars (Excellent) to  1 star (Poor)</p>
                                                                <ReactStars
                                                                    id="editRating"
                                                                    a11y={false}
                                                                    count={5}
                                                                    value={rating}
                                                                    onChange={this.props.ratingChanged}
                                                                    size={16}
                                                                    edit={true}
                                                                    activeColor="#D8B46C"
                                                                />
                                                            </div>
                                                            <br />
                                                            <div>
                                                                <label>Edit review</label>
                                                                <textarea id="review"
                                                                    className="materialize-textarea has-character-counter"
                                                                    value={this.props.review}
                                                                    onChange={this.props.handleChange}
                                                                    data-length="200"
                                                                    maxLength="200"></textarea>
                                                            </div>
                                                            <br />
                                                            <div>
                                                                <span className="left"><Link to="#" onClick={() => this.props.editReviewRatingFn(false)}>Cancel</Link></span>
                                                                <button className="rn-button-style--2 btn-solid right"
                                                                    disabled={this.props.review === '' && this.props.rating <= 0}
                                                                    onClick={() => this.props.submitRatingReview(selectedOperator)}>Submit edited rating and review</button>
                                                            </div>
                                                            <div id="confirmRatingReviewModal" className="modal">
                                                                <div className="modal-content1 center modalForm">
                                                                    <h5>Please confirm your rating and review</h5>
                                                                    <br />
                                                                    Star Rating: {this.props.rating > 0 ? <span>{this.props.rating} ({this.starRatingScale(this.props.rating)})</span> : 'Not rated'}
                                                                    <br />
                                                                    Review: {this.props.review}
                                                                    <hr />
                                                                    <br />
                                                                    <div>
                                                                        <button className="rn-button-style--2 btn-solid modal-close" style={{ marginRight: '10px' }}>Cancel</button>
                                                                        <button className="rn-button-style--2 btn-solid modal-close"
                                                                            onClick={() => this.props.confirmRatingReview(selectedOperator, project.reviewId)}>Confirm</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            } else {
                                //already reviewed this ride
                                rateReviewOperator = (
                                    <div>
                                        <ul className="collapsible">
                                            <li className="active">
                                                <div className="collapsible-header ul-header">
                                                    <u><h3>Ride rating and review</h3></u>
                                                </div>
                                                <div className="collapsible-body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 col-lg-12">
                                                            <div>
                                                                <div>Your submitted ride rating and review</div>
                                                                {/* <Link to="#"
                                                                    onClick={() => this.props.editReviewRatingFn(true, project.reviewDetails.rating, project.reviewDetails.review)}
                                                                >Edit</Link> */}
                                                                <span><ReactStars
                                                                    id="viewRating"
                                                                    a11y={false}
                                                                    count={5}
                                                                    value={+project.reviewDetails.rating}
                                                                    size={16}
                                                                    edit={false}
                                                                    activeColor="#D8B46C"
                                                                /> ({this.starRatingScale(project.reviewDetails.rating)})</span>
                                                            </div>
                                                            <div>
                                                                Review: {project.reviewDetails.review}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            }
                        } else {
                            //show the options to rate and write a review
                            rateReviewOperator = (
                                <div>
                                    <ul className="collapsible">
                                        <li className="active">
                                            <div className="collapsible-header ul-header">
                                                <u><h3>Ride rating and review</h3></u>
                                            </div>
                                            <div className="collapsible-body">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div>
                                                            <p>How was your ride? Rate your operator service level on a scale of 5 stars (Excellent) to  1 star (Poor) </p>
                                                            <ReactStars
                                                                id="setRating"
                                                                a11y={false}
                                                                count={5}
                                                                value={rating}
                                                                onChange={this.props.ratingChanged}
                                                                size={16}
                                                                edit={true}
                                                                activeColor="#D8B46C"
                                                            />
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <label htmlFor="review">Write a review</label>
                                                            <textarea id="review"
                                                                className="materialize-textarea has-character-counter"
                                                                onChange={this.props.handleChange}
                                                                data-length="200"
                                                                maxLength="200"></textarea>
                                                        </div>
                                                        <div className=" center">
                                                            <button className="rn-button-style--2 btn-solid"
                                                                disabled={this.props.review === '' && this.props.rating <= 0}
                                                                onClick={() => this.props.submitRatingReview(selectedOperator)}>Submit rating and review</button>
                                                        </div>
                                                        <div id="confirmRatingReviewModal" className="modal">
                                                            <div className="modal-content1 center modalForm">
                                                                <h5>Please confirm your rating and review</h5>
                                                                <br />
                                                                Star Rating: {this.props.rating > 0 ? <span>{this.props.rating} ({this.starRatingScale(this.props.rating)})</span> : 'Not rated'}
                                                                <br />
                                                                Review: {this.props.review}
                                                                <hr />
                                                                <br />
                                                                <div>
                                                                    <button className="rn-button-style--2 btn-solid modal-close" style={{ marginRight: '10px' }}>Cancel</button>
                                                                    <button className="rn-button-style--2 btn-solid modal-close"
                                                                        onClick={() => this.props.confirmRatingReview(selectedOperator)}>Confirm</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }
                    } else {
                        // ride hasn't ended yet, so don't show ratings section
                    }
                } else if (selectedOperator) {
                    selectedOperatorStatus = <span className="red-text">Pending Operator confirmation</span>
                    buttonElement = (
                        <div>
                            <button className="rn-button-style--2 btn-solid"
                                onClick={() => this.props.cancelSelectOperatorFn(`${operatorProfile.firstName.toUpperCase()}`,
                                    selectedOperator, operatorProfile.phoneNumber)}>Cancel Operator
                            </button>
                        </div>
                    )
                }

                return (
                    <>
                        <React.Fragment>
                            <Card variant="outlined">
                                <CardContent>
                                    <div className="operator-response-section">
                                        <div className="operator-response-section-photo">
                                            <img src={updatedProfileImageUrl || 'http://via.placeholder.com/200x200'} alt="profile" />
                                        </div>
                                        <div className="operator-response-section-personal-info">
                                            <h4>
                                                <u>{operatorProfile.firstName.toUpperCase()} {operatorProfile.lastName.toUpperCase()} </u>
                                                <span>
                                                    <Tooltip title={
                                                        <p>
                                                            Rides accepted: {acceptedRides} <br />
                                                            Rides completed: {confirmedRides} <br />
                                                            Rides cancelled: {cancelledConfirmedRides} <br />
                                                        </p>
                                                    }>
                                                        <InfoIcon fontSize='small' />
                                                    </Tooltip>
                                                </span>
                                            </h4>
                                            <h5>Ride Quote: ${operatorData ? operatorData.totalRate : project.calculatedPrice}
                                                <Tooltip title={
                                                    <p>
                                                        Recommended Rate: {project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}<br />
                                                        Tolls: ${operatorData ? operatorData.additionalChargesTolls : '0.00'}<br />
                                                        Parking: ${operatorData ? operatorData.additionalChargesParking : '0.00'}<br />
                                                        Airport Fee: ${operatorData ? operatorData.additionalChargesAirportFee : '0.00'}<br />
                                                        Other Charges: ${operatorData ? operatorData.additionalChargesMisc : '0.00'}<br />
                                                    </p>
                                                }>
                                                    <InfoIcon fontSize='small' />
                                                </Tooltip>
                                            </h5>
                                            {operatorProfile.numberOfRatings &&
                                                <>
                                                    <ReactStars
                                                        id="selectedOperatorRating"
                                                        a11y={false}
                                                        count={5}
                                                        value={+operatorProfile.avgRating}
                                                        size={16}
                                                        isHalf={true}
                                                        edit={false}
                                                        activeColor="#D8B46C"
                                                    />
                                                    <p>{operatorProfile.avgRating} ({operatorProfile.numberOfRatings} reviews)</p>
                                                </>
                                            }
                                            <p>
                                                {preferredOperator
                                                    ? <><FavoriteIcon /> <span>Preferred Operator</span></>
                                                    : <><FavoriteBorderIcon /> {makePreferredOperatorSection}</>}
                                            </p>
                                        </div>
                                        <div className="operator-response-section-ride-quote">
                                            <p>{selectedOperatorStatus}</p>
                                            <p>{operatorProfile.carYear} {operatorProfile.carColor.toUpperCase()} {operatorProfile.carMake.toUpperCase()} {operatorProfile.carModel.toUpperCase()}</p>
                                            <p><b>Vehicle Plate</b>: {operatorProfile.carPlate.toUpperCase()}</p>
                                            <p><b>Cancellation Policy</b>: {cancellationPolicy} hours prior to up time</p>
                                            <p><b>Special Notes</b>: {operatorData && operatorData.specialNotes ? operatorData.specialNotes : '-'}</p>
                                            <p>{buttonElement}</p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            {
                                rateReviewOperator && 
                                <Card variant="outlined" className="mt--20">
                                    <CardContent>
                                        {rateReviewOperator}
                                    </CardContent>
                                </Card>
                            }
                        </React.Fragment>
                    </>
                )
            }
        } else {
            return (
                <>
                    <p>Loading</p>
                    <Loader1 />
                </>
            );
        }
    }
}


export default SelectedOperator;
