import React, { Component } from 'react'
import Breadcrumb from "../common/Breadcrumb";
import { AiOutlineSearch } from "react-icons/ai";
import { NavLink } from 'react-router-dom';
import { AiFillCar } from 'react-icons/ai';
import { MdVerifiedUser } from 'react-icons/md';
import { FiMail } from 'react-icons/fi';

class AdminHome extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <Breadcrumb title={'Admin'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="row service-one-wrapper">
                                    <div className="col-lg-4 order-1 order-lg-1 col-md-6 col-6">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/admin/pendingOperators'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <MdVerifiedUser />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Pending Approval Operators</h3>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 order-2 order-lg-2 col-md-6 col-6">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/admin/adminSearch'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <AiOutlineSearch />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Search User</h3>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 order-3 order-lg-3 col-md-6 col-6">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/admin/vehicleTypes'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <AiFillCar />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Vehicle Types</h3>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 order-3 order-lg-3 col-md-6 col-6">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/admin/marketingSubscriptionList'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <FiMail />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Marketing Subscription List</h3>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default AdminHome;
