import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { resetPassword, clearErrorAction } from '../../store/actions/authActions'
import Loader1 from '../loader/Loader1'
import Breadcrumb from "../common/Breadcrumb";
import PasswordMask from 'react-password-mask';
import queryString from 'query-string';
import ErrorMessage from "../errorMessage/ErrorMessage";
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage";

class ResetPassword extends Component {

  constructor(props) {
    super(props);

    // Set the initial input values
    this.state = {
      password: '',
      loading: false
    }

    // Bind the submission to handleChange() 
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    this.props.resetPassword({
      ...this.state,
      token: this.props.token
    })
      .then(() => {
        this.setState({
          password: '',
          loading: false
        });
      });
  }

  componentDidMount() {
    this.props.clearError();
  }

  render() {
    const { auth, authError, authSuccess, profile, token } = this.props;
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }
    if (!token) {
      return (
        <>
          <Breadcrumb title={'Reset Password'} />
          <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-12 center">
                  This link is invalid.
                </div>
              </div>
            </div>
          </main>
        </>
      )
    } else if (auth.uid) {
      if (profile && !profile.isEmpty) {
        if (profile.accountType === 'Traveler') {
          return <Redirect to='/home' />
        } else {
          return <Redirect to='/home' />
        }
      } else {
        return (
          <div className="container center">
            <div>Loading</div>
            <Loader1 />
          </div>
        )
      }
    } else {
      return (
        <>
          {loader}
          <Breadcrumb title={'Reset Password'} />
          <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 offset-lg-3">
                  <div className="section-title text-left mb--15">
                    <p className="description">Set your new password to access your account.</p>
                  </div>
                  <div className="mt--40 mb--40">
                    <form onSubmit={this.handleSubmit}>
                      <div>
                        {authError ? <ErrorMessage><span errorMessageText={authError}></span></ErrorMessage> : null}
                        {authSuccess ? <ConfirmationMessage message={authSuccess} /> : null}
                      </div>
                      <label>New Password<span className="star">*</span></label>
                      <div>
                        <PasswordMask
                          id="password"
                          name="password"
                          placeholder="Enter password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="mb--20 center">
                        <button className="rn-button-style--2 btn-solid">Submit</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const values = queryString.parse(ownProps.location.search);
  let token = values.token;
  return {
    token: token,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data) => dispatch(resetPassword(data)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
