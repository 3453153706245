import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Breadcrumb from "../common/Breadcrumb";
import { CgProfile } from "react-icons/cg";
import { AiOutlineCar } from "react-icons/ai";
import { NavLink } from 'react-router-dom'
import Loader1 from '../loader/Loader1'

const ServiceListOne = [
    {
        icon: <AiOutlineCar />,
        title: 'Rides',
        description: 'Manage rides',
        link: '/my-reservations'
    },
    {
        icon: <CgProfile />,
        title: 'Settings',
        description: 'Manage account',
        link: '/account'
    }
]

class TravelerHome extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState() {
        return {
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (profile && !profile.isEmpty) {

            return (
                <>
                    <Breadcrumb title1={`Welcome ${profile.firstName.toUpperCase()}`} />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
                        <div className="container">
                            <div className="row service-one-wrapper">
                                {ServiceListOne.map((val, i) => (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                        <NavLink className="text-center" onClick={this.scrollToTop} to={val.link}>
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title={'Welcome'} />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <p className="description">Loading</p>
                                    <Loader1 />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelerHome)

