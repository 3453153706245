import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import getRideStatusBackground from './RideStatusBackground';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { IoCarOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function RideTableData(props) {
    const rows = props.rows
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let sortedRows = null;
    if (props.sortAsc) {
        sortedRows = rows.sort((a, b) => {
            return a.serviceDate - b.serviceDate;
        })
    } else {
        sortedRows = rows.sort((a, b) => {
            return b.serviceDate - a.serviceDate;
        })
    }

    return (
        <Paper className={classes.root}>
            {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Card className={`${classes.root}`} variant="outlined" key={row.id}>
                    <Link to={row.actionLink} className="rowLink">
                        <CardHeader
                            avatar={
                                <Avatar className={getRideStatusBackground(row.status)}>
                                    <IoCarOutline />
                                </Avatar>
                            }
                            action={
                                <IconButton aria-label="ride-details">
                                    {/* <MoreVertIcon /> */}
                                    {row.action}
                                </IconButton>
                            }
                            title={`${row.serviceType} - ${row.serviceVehicle} - ${row.date}, ${row.time}`}
                            subheader={`Rate: ${row.price} | Status: ${row.status} | Ride Code : ${row.rideConfirmationCode}`}
                        />
                        <CardContent>
                            <b>From:</b> {row.from}
                            <br /><b>To:</b> {row.to}
                            <br /><b>Distance:</b> {row.distance} | <b>Estimated Trip Duration:</b> {row.duration || ''}
                        </CardContent>
                    </Link>
                </Card>
            ))}
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
