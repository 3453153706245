import React from 'react'
import Breadcrumb from "../common/Breadcrumb";

const TravelerQuickGuide = () => {
    return (
        <>
            <Breadcrumb title={'Traveler'} title1={'Traveler Quick Guide'} parent='Quick Guide' parentLink='/quickGuide' />

            <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                <div className="row row--35 align-items-start ml--30">
                    <div className="col-lg-12 order-2 order-lg-1">
                        {/* <div className="section-title text-left mb--50">
                            <h3 className="title center">Quick Guide for Travelers on how to use the tool.</h3>
                        </div> */}
                        <div className="about-inner inner">
                            <div className="section-title">
                                <div className="mt--30 justify-content-end">
                                    <p> <b>Hello Travelers</b>, <br /><br />
                                        <b>Welcome to FINDANIO (Find An Independent Operator)</b>.<br /><br />
                                        Here are a few tips to help you navigate through our portal.</p>
                                    <p> 1. To recieve an instant quote enter the date, time, pickup, and drop off location. For booking the ride please login.</p>
                                    <p> 2. When booking the ride, choose from the list of available operators that match your request. You will be able to view their profile, the number of rides they have completed and/or canceled, as well as any feedback written by other travelers. FINDANIO does not use a star rating process however there is a feedback option embedded.</p>
                                    <p> 3. Operators can accept the recommended FINDANIO rate or submit their own rate, which may include additional costs such as tolls, airport fees, wait times, redirecting fees, and parking.</p>
                                    <p> 4. View the available operators and make the best choice for you.</p>
                                    <p> 5. After choosing your operator, await their confirmation.</p>
                                    <p> 6. Once confirmed by your operator, you will be able to view each other’s contact information.</p>
                                    <p> 7. In high-demand cities or during big events, FINDANIO provides travelers who are interested the opportunity to increase the rate to attract more operators.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default TravelerQuickGuide;
