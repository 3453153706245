import React from 'react'
// import M from "materialize-css";
import firebase from "../../config/fbConfig";
import '../common/Checkbox.css';
import CheckBox from '../common/Checkbox';
import { getVehicleTypeName } from '../util/rideUtils'

class OperatorSignUpStep2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.carMakeRef = React.createRef();
  }

  initialState() {
    return {
      airportList: [],
      carModelList: [],
      carModelTypeMapping: {},
      carMakeUpdated: false,
      carModelBeingUpdated: false
    }
  }

  handleCarModelChange = (event) => {
    let carModel = event.target.value;
    this.props.updateCarModelChange(carModel, this.state.carModelTypeMapping[carModel])
  }

  componentDidMount() {
    if (this.props.carMakeList && this.props.carMakeList.length > 0 && this.state.carMakeUpdated === false) {
      // let carMakeDropdown = document.getElementById("carMake");
      // populate the car make list
      let carMakeDropdown = this.carMakeRef.current;
      this.props.carMakeList.forEach((value) => {
        if (value === this.props.carMake) {
          carMakeDropdown.add(new Option(value, value, false, true));
        } else {
          carMakeDropdown.add(new Option(value, value));
        }
      })
      this.setState({
        carMakeUpdated: true
      });
    }
    if (this.props.state !== '') {
      const ref = firebase.firestore().collection('airports').doc(this.props.state);
      ref.get().then((doc) => {
        let airports = [];
        if (doc.exists) {
          let data = doc.data();
          for (var p in data.airports) {
            if (data.airports.hasOwnProperty(p)) {
              if (this.props.airports.includes(p)) {
                airports.push({ value: p, label: data.airports[p] + " (" + p + ")", isChecked: true });
              } else {
                airports.push({ value: p, label: data.airports[p] + " (" + p + ")", isChecked: false });
              }
            }
          }
        }
        this.setState({
          airportList: airports
        });
      })
    }
  }

  componentDidUpdate(prevProps) {
    const currentCarMake = this.props.carMake;
    let carModelDropdown = document.getElementById("carModel");
    const currentState = this.props.state;
    let airports = [];
    if (prevProps.state !== currentState) {
      if (currentState !== '') {
        const ref = firebase.firestore().collection('airports').doc(currentState);
        ref.get().then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            for (var p in data.airports) {
              if (data.airports.hasOwnProperty(p)) {
                airports.push({ value: p, label: data.airports[p] + " (" + p + ")", isChecked: false });
              }
            }
          }
          this.setState({
            airportList: airports
          });
        })
      } else {
        this.setState({
          airportList: airports
        });
      }
    } else if (prevProps.currentStep !== this.props.currentStep && this.state.airportList.length > 0 && this.props.airports && this.props.airports.length > 0) {
      for (var p in this.state.airportList) {
        if (this.props.airports.includes(p.value)) {
          airports.push({ value: p, label: this.state.airportList[p] + " (" + p + ")", isChecked: true });
        } else {
          airports.push({ value: p, label: this.state.airportList[p] + " (" + p + ")", isChecked: false });
        }
      }
      this.setState({
        airportList: airports
      });
    }

    // new content
    if (prevProps.carMakeList !== this.props.carMakeList && this.props.carMakeList && this.props.carMakeList.length > 0 && this.state.carMakeUpdated === false) {
      // populate the car make list
      // let carMakeDropdown = document.getElementById("carMake");
      let carMakeDropdown = this.carMakeRef.current;
      this.props.carMakeList.forEach((value) => {
        if (value === this.props.carMake) {
          carMakeDropdown.add(new Option(value, value, false, true));
        } else {
          carMakeDropdown.add(new Option(value, value));
        }
      })
      this.setState({
        carMakeUpdated: true
      });
    }

    if (currentCarMake === "") {
      if (carModelDropdown) {
        carModelDropdown.length = 1;
        carModelDropdown.setAttribute("disabled", "disabled");
      }
    } else if (prevProps.carMake !== currentCarMake) {
      const ref = firebase.firestore().collection('cars').doc(currentCarMake);
      ref.get().then((doc) => {
        if (doc.exists) {
          carModelDropdown.length = 1;
          let data = doc.data();
          let carModelList = Object.keys(data.modelType);
          for (var m in carModelList) {
            carModelDropdown.add(new Option(carModelList[m], carModelList[m]));
          }
          carModelDropdown.removeAttribute("disabled");
          this.setState({
            carModelList: carModelList,
            carModelTypeMapping: data.modelType
          });
        } else {
          carModelDropdown.length = 1;
          carModelDropdown.setAttribute("disabled", "disabled");
          this.setState({
            carModelList: [],
            carModelTypeMapping: {}
          });
        }
      });
    } else if (prevProps.currentStep !== this.props.currentStep && carModelDropdown && this.state.carModelList.length > 0) {
      this.state.carModelList.forEach((value) => {
        if (value === this.props.carModel) {
          carModelDropdown.add(new Option(value, value, false, true));
        } else {
          carModelDropdown.add(new Option(value, value));
        }
      })
      carModelDropdown.removeAttribute("disabled");
    } else if (!this.state.carModelBeingUpdated && currentCarMake && this.state.carModelList.length === 0) {
      this.setState({
        carModelBeingUpdated: true,
      });
      const ref = firebase.firestore().collection('cars').doc(currentCarMake);
      ref.get().then((doc) => {
        if (doc.exists) {
          carModelDropdown.length = 1;
          let data = doc.data();
          let carModelList = Object.keys(data.modelType);
          for (var m in carModelList) {
            if (carModelList[m] === this.props.carModel) {
              carModelDropdown.add(new Option(carModelList[m], carModelList[m], false, true));
            } else {
              carModelDropdown.add(new Option(carModelList[m], carModelList[m]));
            }
          }
          carModelDropdown.removeAttribute("disabled");
          this.setState({
            carModelList: carModelList,
            carModelTypeMapping: data.modelType,
            carModelBeingUpdated: false,
          });
        } else {
          carModelDropdown.length = 1;
          carModelDropdown.setAttribute("disabled", "disabled");
          this.setState({
            carModelList: [],
            carModelTypeMapping: {}
          });
        }
      });
    }

    if (prevProps.currentStep !== this.props.currentStep) {
      this.props.updateLoader(false);
    }
  }

  handleCheckInputChanged = (event) => {
    let airportList = this.state.airportList;
    let selectedAirports = [];
    airportList.forEach(airport => {
      if (airport.value === event.target.value) {
        airport.isChecked = event.target.checked;
      }
      if (airport.isChecked) {
        selectedAirports.push(airport.value);
      }
    })
    this.setState({ airportList });
    this.props.handleChangeAirport(selectedAirports);
  }

  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
      return null
    }

    return (
      <div className="form-group mt--20">
        <div className="row">
          <div className="col-12" style={{ fontSize: '80%' }}>
            <span className="star">*</span> Required
          </div>
        </div>

        <div className="row mt--20">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="carPlate">Vehicle License Plate<span className="star">*</span></label>
            <input type="text" id='carPlate' placeholder="Enter your vehicle license plate" value={this.props.carPlate} onChange={this.props.handleChange} />
          </div>

          <div className="col-md-6 col-sm-12">
            <label htmlFor="vehicleVIN">Vehicle VIN<span className="star">*</span></label>
            <input type="text" id='vehicleVIN' placeholder="Enter your vehicle VIN" value={this.props.vehicleVIN} onChange={this.props.handleChange} />
          </div>
        </div>

        <div className="row mt--20">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="carYear">Year<span className="star">*</span></label>
            <select name="carYear" id="carYear" value={this.props.carYear} onChange={this.props.handleChange}>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
              <option value="2015">2015</option>
            </select>
          </div>

          <div className="col-md-6 col-sm-12">
            <label htmlFor="carMake">Make<span className="star">*</span></label>
            <select name="carMake" id="carMake"
              ref={this.carMakeRef}
              value={this.props.carMake} onChange={this.props.handleChange}>
              <option value="">Select a car make</option>
            </select>
          </div>
        </div>

        <div className="row mt--20">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="carModel">Model<span className="star">*</span></label>
            <select name="carModel" id="carModel" value={this.props.carModel} onChange={this.handleCarModelChange}>
              <option value="">Select a car model</option>
            </select>
          </div>

          <div className="col-md-6 col-sm-12">
            <label>Vehicle Type</label>
            <input type="text" id='carType' placeholder="Select car make and model" value={getVehicleTypeName(this.props.carType)} disabled />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label>Vehicle Color<span className="star">*</span></label>
            <select id="carColor" name="carColor" value={this.props.carColor} onChange={this.props.handleChange} >
              <option value="Black">
                Black
              </option>
              <option value="OtherColor">
                Other
              </option>
            </select>
          </div>

          <div className="col-md-6 col-sm-12">
            <span id="otherColorDiv">
              <label>Other color</label>
              <input type="text" name="otherColor" placeholder="Enter your vehicle color" disabled={true} id='otherColor' value={this.props.otherColor} onChange={this.props.handleChange} />
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="liabilityInsuranceAmt">Liability Insurance Amount<span className="star">*</span></label>
            <select id="liabilityInsuranceAmt" name="liabilityInsuranceAmt" value={this.props.liabilityInsuranceAmt} onChange={this.props.handleChange} >
              <option value="">Select amount</option>
              <option value="250K">250K</option>
              <option value="300K">300K</option>
              <option value="350K">350K</option>
              <option value="500K">500K</option>
              <option value="750K">750K</option>
              <option value="1Million">1 Million</option>
              <option value="1.5Million">1.5 Million</option>
              <option value="2Million">2 Million</option>
              <option value="5Million">5 Million</option>
            </select>
          </div>

          <div className="col-md-6 col-sm-12">
            <label htmlFor="cancellationPolicy">Ride Cancellation Policy<span className="star">*</span></label>
            <select id="cancellationPolicy" name="cancellationPolicy" value={this.props.cancellationPolicy} onChange={this.props.handleChange} >
              <option value="">Select your cancellation policy prior to ride pick up time</option>
              <option value="1">1 hour</option>
              <option value="2">2 hours</option>
              <option value="3">3 hours</option>
              <option value="4">4 hours</option>
              <option value="5">5 hours</option>
              <option value="6">6 hours</option>
              <option value="7">7 hours</option>
              <option value="8">8 hours</option>
              <option value="9">9 hours</option>
              <option value="10">10 hours</option>
              <option value="11">11 hours</option>
              <option value="12">12 hours</option>
              <option value="24">24 hours</option>
              <option value="36">36 hours</option>
              <option value="48">48 hours</option>
              <option value="72">72 hours</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="state">State<span className="star">*</span></label>
            <select name="state" id="state" value={this.props.state} onChange={this.props.handleChange}>
              <option value="">Select a State</option>
              <option value="AL">Alabama (AL)</option>
              <option value="AK">Alaska (AK)</option>
              <option value="AZ">Arizona (AZ)</option>
              <option value="AR">Arkansas (AR)</option>
              <option value="CA">California (CA)</option>
              <option value="CO">Colorado (CO)</option>
              <option value="CT">Connecticut (CT)</option>
              <option value="DE">Delaware (DE)</option>
              <option value="DC">District Of Columbia (DC)</option>
              <option value="FL">Florida (FL)</option>
              <option value="GA">Georgia (GA)</option>
              <option value="HI">Hawaii (HI)</option>
              <option value="ID">Idaho (ID)</option>
              <option value="IL">Illinois (IL)</option>
              <option value="IN">Indiana (IN)</option>
              <option value="IA">Iowa (IA)</option>
              <option value="KS">Kansas (KS)</option>
              <option value="KY">Kentucky (KY)</option>
              <option value="LA">Louisiana (LA)</option>
              <option value="ME">Maine (ME)</option>
              <option value="MD">Maryland (MD)</option>
              <option value="MA">Massachusetts (MA)</option>
              <option value="MI">Michigan (MI)</option>
              <option value="MN">Minnesota (MN)</option>
              <option value="MS">Mississippi (MS)</option>
              <option value="MO">Missouri (MO)</option>
              <option value="MT">Montana (MT)</option>
              <option value="NE">Nebraska (NE)</option>
              <option value="NV">Nevada (NV)</option>
              <option value="NH">New Hampshire (NH)</option>
              <option value="NJ">New Jersey (NJ)</option>
              <option value="NM">New Mexico (NM)</option>
              <option value="NY">New York (NY)</option>
              <option value="NC">North Carolina (NC)</option>
              <option value="ND">North Dakota (ND)</option>
              <option value="OH">Ohio (OH)</option>
              <option value="OK">Oklahoma (OK)</option>
              <option value="OR">Oregon (OR)</option>
              <option value="PA">Pennsylvania (PA)</option>
              <option value="RI">Rhode Island (RI)</option>
              <option value="SC">South Carolina (SC)</option>
              <option value="SD">South Dakota (SD)</option>
              <option value="TN">Tennessee (TN)</option>
              <option value="TX">Texas (TX)</option>
              <option value="UT">Utah (UT)</option>
              <option value="VT">Vermont (VT)</option>
              <option value="VA">Virginia (VA)</option>
              <option value="WA">Washington (WA)</option>
              <option value="WV">West Virginia (WV)</option>
              <option value="WI">Wisconsin (WI)</option>
              <option value="WY">Wyoming (WY)</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 col-12">
            <label>Select airports you service<span className="star">*</span></label>
            <ul>
              {
                this.state.airportList.length > 0 && this.state.airportList.map((airport) => {
                  return (<CheckBox key={airport.value} handleCheckInputChanged={this.handleCheckInputChanged}  {...airport} />)
                })
              }
            </ul>

          </div>
        </div>
      </div>
    )
  }
}

export default OperatorSignUpStep2;