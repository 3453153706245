import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { updatePassword } from '../../store/actions/authActions'
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage";
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage";

class UpdatePassword extends Component {

  constructor(props) {
    super(props);

    // Set the initial input values
    this.state = {
      newPassword: '',
      currentPassword: ''
    }

    // Bind the submission to handleChange() 
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updatePassword(this.state);

    this.setState({
      newPassword: '',
      currentPassword: ''
    })
  }

  render() {
    const { auth, authError, authSuccess } = this.props;
    if (!auth.uid) return <Redirect to='/login' />

    return (
      <>
        <Breadcrumb title='Security' parent='Account' parentLink='/account' />

        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="mt--10">
                  {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
                  {authSuccess ? <ConfirmationMessage message={authSuccess} /> : null}
                </div>
                <div className="section-title text-left mt--30 mb--30">
                  <p className="description center">Update your account password.</p>
                </div>
                <div className="">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <label htmlFor="currentPassword">Current password<span className="star">*</span></label>
                        <input type="password" placeholder="Enter current password"
                          value={this.state.currentPassword} id='currentPassword' onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label htmlFor="newPassword">New password<span className="star">*</span></label>
                        <input type="password" placeholder="Enter new password"
                          id='newPassword' value={this.state.newPassword} onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="row mt--20">
                      <div className="col-lg-12 center">
                        <button className="rn-button-style--2 btn-solid">Submit</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (creds) => dispatch(updatePassword(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword)
