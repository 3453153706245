import React, { Component } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCardSetupForm from './InjectedCardSetupForm';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../../loader/Loader1'
import { createSetupIntent, getUserProfile } from '../../../store/actions/authActions';
import { stripePublishableKey } from "../../constants/CommonConstants";
const stripePromise = loadStripe(stripePublishableKey);

class AddPaymentMethodInModal extends Component {

    componentDidMount() {
        const { auth, profile } = this.props;
        if (auth && auth.uid && !profile) {
            this.props.getUserProfile(auth.uid);
        }
        if (profile && !profile.stripeSetupIntentClientSecret) {
            this.props.createSetupIntent({
                email: profile.email,
                uid: auth.uid
            })
        }
    }

    componentDidUpdate() {
        const { auth, profile } = this.props;
        if (auth && auth.uid && !profile) {
            this.props.getUserProfile(auth.uid);
        }
        if (profile && !profile.stripeSetupIntentClientSecret) {
            this.props.createSetupIntent({
                email: profile.email,
                uid: auth.uid
            })
        }
    }

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (profile && profile.stripeSetupIntentClientSecret) {
            return (
                <React.Fragment>
                    <div className="card-title mainFont center">ADD NEW PAYMENT METHOD</div>
                    <br />
                    <Elements stripe={stripePromise}  >
                        <InjectedCardSetupForm
                            redirectToPath={this.props.redirectToPath}
                            secret={profile.stripeSetupIntentClientSecret} />
                    </Elements>
                </React.Fragment>
            )
        } else {
            return (
                <div className="container">
                    <div className="card z-depth-0">
                        <div className="card-content">
                            <div className="card-title mainFont center">ADD NEW PAYMENT METHOD</div>
                            <br />
                            <div className='center'>
                                <div>Loading. Please wait. </div>
                                <Loader1 />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createSetupIntent: (obj) => dispatch(createSetupIntent(obj)),
        getUserProfile: (uid) => dispatch(getUserProfile(uid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodInModal)
