import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { mailingList, clearErrorAction } from '../../store/actions/authActions'
import Loader1 from '../loader/Loader1';
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage"
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage"

class MarketingSubscriberSignUp extends Component {
  state = {
    email: '',
    numberOfFleet: '',
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    officePhoneNumber: '',
    city: '',
    state: '',
    loading: false
  }

  componentDidMount() {
    this.props.clearError();
  }

  handleChange = (e) => {
    const { id, email, value } = e.target
    if (email === 'email') {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: value
      })
    }
  }
  handlePhoneChange = (value) => {
    this.setState({
      phoneNumber: value.replace(/[^0-9]+/g, '')
    })
  }

  handleOfficePhoneChange = (value) => {
    this.setState({
      officePhoneNumber: value.replace(/[^0-9]+/g, '')
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.clearError();
    this.setState({
      loading: true
    }, () => {
      this.props.mailingListAction(this.state)
        .then(() => {
          window.scrollTo(0, 0);
          this.setState({
            loading: false
          });
        })
        .catch((err) => {
          // console.log('error = ', err);
          window.scrollTo(0, 0);
          this.setState({
            loading: false
          });
        })
    })
  }


  render() {
    const { auth, error, profile, mailListSignUpSuccess } = this.props;
    if (auth.id) {
      if (profile && !profile.isEmpty) {
        return <Redirect to='/home' />
      }
    } else {
      let loader = null;
      if (this.state.loading === true) {
        loader = <Loader1 />
      }
      return (
        <>
          {loader}
          <Breadcrumb title={'Marketing Subscription'} title1={'Marketing Subscription'} parent='home' />
          <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-12">
                  <div className="mb--40">
                    {error ? <ErrorMessage errorMessageText={error}></ErrorMessage>
                      : mailListSignUpSuccess === true
                        ?
                        <ConfirmationMessage message="You have been successfully added to the FINDANIO marketing email list." />
                        : null
                    }
                  </div>
                  <div className="section-title text-left mb--30">
                    <h3 className="title mt--30">Subscribe to FINDANIO promotional emails</h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6  col-md-6 col-sm-6 order-1 order-lg-1">
                        <div>
                          <label htmlFor="firstName">First Name<span className="star">*</span></label>
                          <input type="text" id='firstName' placeholder="Enter your first name" onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <div>
                          <label htmlFor="lastName">Last Name<span className="star">*</span></label>
                          <input type="text" id='lastName' placeholder="Enter your last name" onChange={this.handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <div>
                          <label htmlFor="phoneNumber">Cell Phone</label>
                          <PhoneInput
                            specialLabel=""
                            id='phoneNumber'
                            onlyCountries={['us']}
                            country={'us'}
                            disableCountryCode={true}
                            disableSearchIcon={true}
                            disableDropdown={true}
                            placeholder={'(xxx) xxx-xxxx'}
                            value={this.state.phoneNumber}
                            onChange={this.handlePhoneChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <div>
                          <label htmlFor="officePhoneNumber">Office Phone</label>
                          <PhoneInput
                            specialLabel=""
                            id='officePhoneNumber'
                            onlyCountries={['us']}
                            country={'us'}
                            disableCountryCode={true}
                            disableSearchIcon={true}
                            disableDropdown={true}
                            placeholder={'(xxx) xxx-xxxx'}
                            value={this.state.officePhoneNumber}
                            onChange={this.handleOfficePhoneChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <div>
                          <label htmlFor="email">Email<span className="star">*</span></label>
                          <input type="email" id='email' placeholder="yours@example.com" onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <div>
                          <label htmlFor="companyName">Company Name</label>
                          <input type="text" id='companyName' placeholder="Enter your company name" onChange={this.handleChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <label htmlFor="city">City<span className="star">*</span></label>
                        <input type="text" id='city' placeholder="Enter your city name" onChange={this.handleChange} />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <label htmlFor="state">State<span className="star">*</span></label>
                        <input type="text" id='state' placeholder="Enter your state name" onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                        <label htmlFor="numberOfFleet">Number of Fleet</label>
                        <input type="text" id='numberOfFleet' placeholder="Enter your fleet number" onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="row ptb--10">
                      <div className="col-lg-12">
                        By clicking the button below, you confirm the following:
                          <br /> - you have agreed to receive FINDANIO marketing emails.
                          <br /> - you've read and accepted our <a href="/tnc" className="anchor">Terms and Conditions of Service</a> and <a href="/privacy" className="anchor">Privacy Policy</a>
                      </div>
                    </div>
                    <div className="row ptb--10">
                      <div className="col-lg-12">
                        <button className="rn-button-style--2 btn-solid align-center">JOIN NOW</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </>
      )
    }
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    error: state.auth.mailListSignUpError,
    mailListSignUpSuccess: state.auth.mailListSignUpSuccess,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    mailingListAction: (creds) => dispatch(mailingList(creds)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingSubscriberSignUp)
