import React from 'react'

class OperatorSignUpStep5 extends React.Component {
  render() {

    const { details } = this.props;

    if (details.currentStep !== 5) { // Prop: The current step
      return null
    }
    return (

      <div className="form-group">
        <div className="row">
          <div className="col-12">

            <div className="row mt--20">
              <div className="col-sm-12">
                <label>Please review the details you submitted and confirm sign up</label>
              </div>
            </div>

            <div className="row mt--20">
              <div className="col-sm-12">
                <h4>Personal Details</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Email
              </div>
              <div className="col-sm-6">
                {details.email}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                First Name
              </div>
              <div className="col-sm-6">
                {details.firstName}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Last Name
              </div>
              <div className="col-sm-6">
                {details.lastName}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Phone Number
              </div>
              <div className="col-sm-6">
                {details.phoneNumber}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Company Name
              </div>
              <div className="col-sm-6">
                {details.companyName}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Office Phone Number
              </div>
              <div className="col-sm-6">
                {details.officePhoneNumber}
              </div>
            </div>


            <div className="row mt--20">
              <div className="col-sm-12">
                <h4>Vehicle Details</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Vehicle License Plate
              </div>
              <div className="col-sm-6">
                {details.carPlate}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Vehicle Year
              </div>
              <div className="col-sm-6">
                {details.carYear}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Vehicle Make
              </div>
              <div className="col-sm-6">
                {details.carMake}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Vehicle Model
              </div>
              <div className="col-sm-6">
                {details.carModel}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Vehicle Color
              </div>
              <div className="col-sm-6">
                {details.carColor}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Other color
              </div>
              <div className="col-sm-6">
                {details.otherColor}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Vehicle Type
              </div>
              <div className="col-sm-6">
                {details.carType}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                State
              </div>
              <div className="col-sm-6">
                {details.state}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Airports
              </div>
              <div className="col-sm-6">
                {details.airports}
              </div>
            </div>


            <div className="row mt--20">
              <div className="col-sm-12">
                <h4>Preferences</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Do you want to receive text messages? 
              </div>
              <div className="col-sm-6">
                {details.receiveTexts}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Do you want to receive email communications?
              </div>
              <div className="col-sm-6">
                {details.receiveEmails}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Is your vehicle pet friendly?
              </div>
              <div className="col-sm-6">
                {details.petFriendly}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                Do you sanitize the vehicle before each ride pick up?
              </div>
              <div className="col-sm-6">
                {details.q1}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <span>Do you provide complimentary drinking water?</span>
              </div>
              <div className="col-sm-6">
                {details.q2}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <span>Do you provide hand sanitizer?</span>
              </div>
              <div className="col-sm-6">
                {details.q3}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <span>Do you have a sneeze guards divider shield in your car?</span>
              </div>
              <div className="col-sm-6">
                {details.q4}
              </div>
            </div>

            <div className="row mt--60">
              <div className="col-md-12 col-sm-12">
                By signing up, you confirm that you've read and accepted our <a href="/tnc" className="anchor">Terms and Conditions of Service</a> and <a href="/privacy" className="anchor">Privacy Policy</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OperatorSignUpStep5