import React, { Component } from 'react'
import Breadcrumb from "../common/Breadcrumb";
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader1 from '../loader/Loader1'
import OperatorTableData from './OperatorTableData';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { getPendingOperators } from "../../store/actions/authActions";

function createData(id, firstName, lastName, email, phone, accountType, viewDetailsBtn) {
    return { id, firstName, lastName, email, phone, accountType, action: viewDetailsBtn };
}

class PendingApprovalOperators extends Component {

    componentDidMount() {
        this.props.getPendingOperators();
    }

    render() {
        const { auth, errorMsg, userList } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (userList && !errorMsg) {
            if (userList.length > 0) {
                let finalData = [];

                userList.forEach(user => {
                    let viewDetailsBtn = (
                        <Link to={'/admin/operatorProfile/' + user.uid}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
                    );

                    finalData.push(
                        createData(
                            user.id,
                            user.firstName,
                            user.lastName,
                            user.email,
                            user.phone,
                            user.accountType,
                            viewDetailsBtn
                        )
                    )
                })
                return (
                    <>
                        <Breadcrumb title={'Pending Approval Operators'} title1={'Pending Approval Operators'} parent='Admin' parentLink='/admin' />
                        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                            <div className="main_container">
                                <React.Fragment>
                                    <div className="row mt">
                                        <div className="col-md-12 col-sm-12">
                                            <p className="description">Pending  Account Approvals</p>
                                            {(finalData.length >= 0 && <OperatorTableData rows={finalData} />) || <p className="center error-text">No pending approvals</p>}
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        </main>
                    </>)
            } else {
                return (
                    <>
                        <Breadcrumb title={'Pending Approval Operators'} title1={'Pending Approval Operators'} parent='Admin' parentLink='/admin' />
                        <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--50">
                                        <p className="description">No pending approvals</p>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )
            }
        } else if (errorMsg) {
            return (
                <>
                    <Breadcrumb title={'Pending Approval Operators'} title1={'Pending Approval Operators'} parent='Admin' parentLink='/admin' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <p className="description">{errorMsg}</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title={'Pending Approval Operators'} title1={'Pending Approval Operators'} parent='Admin' parentLink='/admin' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <p className="description">Loading</p>
                                    <Loader1 />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        userList: state.auth.pendingOperators,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        errorMsg: state.auth.err
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPendingOperators: (uid) => dispatch(getPendingOperators(uid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingApprovalOperators)
