import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { travelerSignUp, clearErrorAction, verifyTravelerSignUp, sendVerificationCode, verifyCode } from '../../store/actions/authActions'
import PasswordMask from 'react-password-mask';
import M from "materialize-css";
import Loader1 from '../loader/Loader1';
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
import Delete from '@material-ui/icons/DeleteRounded';
import ErrorMessage from "../errorMessage/ErrorMessage"

class TravelerSignUp extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    accountType: 'Traveler',
    companyName: '',
    phoneNumber: '',
    officePhoneNumber: '',
    profileImageFile: null,
    profileImageFileURL: '',
    verifiedPhone: false,
    verificationCode: '',
    verificationCodeSent: false,
    requestorCategory: '',
    loading: false
  }

  componentDidMount() {
    this.props.clearError();
  }

  handleChange = (e) => {
    const { id, name, value } = e.target
    if (name === 'requestorCategory') {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: value
      })
    }
  }

  handlePhoneChange = (value, data, event) => {
    this.setState({
      phoneNumber: value.replace(/[^0-9]+/g, '')
    })
  }

  handleOfficePhoneChange = (value, data, event) => {
    this.setState({
      officePhoneNumber: value.replace(/[^0-9]+/g, '')
    })
  }


  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    if (file.type && file.type.toUpperCase().includes('image')) {
      switch (event.target.id) {
        case 'profileImageFile':
          this.setState({
            profileImageFile: file,
            profileImageFileURL: URL.createObjectURL(event.target.files[0])
          });
          break;
        default:
          break;
      }
      document.getElementById(`${event.target.id}Err`).innerHTML = '';
    } else {
      // invalid image file uploaded, so clear it
      document.getElementById(event.target.id).value = '';
      document.getElementById(`${event.target.id}Err`).innerHTML = 'Please upload an image file only.';
    }
  }

  removeFileHandler = (id) => {
    document.getElementById(id).value = '';
    this.setState({
      profileImageFile: null,
      profileImageFileURL: ''
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.clearError();
    this.setState({
      verificationCode: '',
      loading: true
    }, () => {
      // console.log('start verify signup', this.state.loading)
      this.props.verifyTravelerSignUpAction(this.state)
        .then((valid) => {
          if (valid && valid === true) {
            // console.log('valid');
            if (this.state.verificationCodeSent === false) {
              //send verification code to user
              this.props.sendVerificationCodeAction(this.state)
                .then((data) => {
                  this.setState({
                    verificationCodeSent: true,
                    loading: false
                  });
                })
                .catch((err) => {
                  this.setState({
                    loading: false
                  });
                })
            }
            //open the modal, so user can enter verification code
            var elems = document.getElementById('verifyPhoneModal');
            M.Modal.init(elems, {});
            M.Modal.getInstance(elems).open();
          } else {
            // console.log('invalid');
            window.scrollTo(0, 0);
            this.setState({
              loading: false
            });
          }
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.setState({
            loading: false
          });
        })
    })
  }

  verifyPhoneForm = (e) => {
    e.preventDefault();
    let inputData = {
      phoneNumber: this.state.phoneNumber,
      code: this.state.verificationCode
    }
    var elems = document.getElementById('verifyPhoneModal');
    M.Modal.getInstance(elems).close();

    //verify the user entered code
    this.props.verifyCode(inputData)
      .then((data) => {
        if (!data.err) {
          //save profile now
          // console.log('save profile now')
          this.props.travelerSignUpAction(this.state);
          this.setState({
            loading: false
          });
        } else {
          this.setState({
            loading: false
          });
        }
      })
      .catch((err) => {
        // console.log('error = ', err);
        this.setState({
          loading: false
        });
      })
  }

  render() {
    const { auth, authError, profile } = this.props;
    if (auth.uid) {
      if (profile && !profile.isEmpty) {
        if (profile.accountType === 'Traveler') {
          return <Redirect to='/home' />
        } else {
          return <Redirect to='/home' />
        }
      } else {
        return (
          <div className="container center">
            <div>Loading</div>
            <Loader1 />
          </div>
        )
      }
    }

    // console.log('loading - ', this.state.loading)
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }

    return (

      <>
        {loader}
        <Breadcrumb title={'Traveler'} title1={'Traveler Sign Up'} parent='Signup' parentLink='/signup' />
        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-12">
                <div className="mb--40">
                  {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
                </div>
                <div className="section-title text-left mb--50">
                  <h2 className="title">Create your account</h2>
                  <p className="description">Join now and book new rides!</p>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 order-1 order-lg-1">
                      <div>
                        <label htmlFor="firstName">First Name<span className="star">*</span></label>
                        <input type="text" id='firstName' placeholder="Enter your first name" onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-6 order-2 order-lg-1">
                      <div>
                        <label htmlFor="lastName">Last Name<span className="star">*</span></label>
                        <input type="text" id='lastName' placeholder="Enter your last name" onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 order-1 order-lg-1">
                      <div>
                        <label htmlFor="phoneNumber">Cell Phone<span className="star">*</span></label>
                        <PhoneInput
                          specialLabel=""
                          id='phoneNumber'
                          onlyCountries={['us']}
                          country={'us'}
                          disableCountryCode={true}
                          disableSearchIcon={true}
                          disableDropdown={true}
                          placeholder={'(xxx) xxx-xxxx'}
                          value={this.state.phoneNumber}
                          onChange={this.handlePhoneChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 order-2 order-lg-1">
                      <div>
                        <label htmlFor="officePhoneNumber">Office Phone</label>
                        <PhoneInput
                          specialLabel=""
                          id='officePhoneNumber'
                          onlyCountries={['us']}
                          country={'us'}
                          disableCountryCode={true}
                          disableSearchIcon={true}
                          disableDropdown={true}
                          placeholder={'(xxx) xxx-xxxx'}
                          value={this.state.officePhoneNumber}
                          onChange={this.handleOfficePhoneChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 order-1 order-lg-1">
                      <div>
                        <label htmlFor="email">Email<span className="star">*</span></label>
                        <input type="email" id='email' placeholder="yours@example.com" onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-6 order-2 order-lg-1">
                      <div>
                        <label htmlFor="email">Password<span className="star">*</span></label>
                        <PasswordMask
                          id="password"
                          name="password"
                          placeholder="Set your Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 order-1 order-lg-1">
                      <div>
                        <label htmlFor="companyName">Company Name</label>
                        <input type="text" id='companyName' placeholder="Enter your company name" onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-6 order-2 order-lg-1">
                      <label htmlFor="requestorCategory">Category<span className="star">*</span></label>
                      <select id='requestorCategory' name="requestorCategory"
                        value={this.props.requestorCategory}
                        onChange={this.handleChange} >
                        <option value="">Select a category</option>
                        <option value="Individual">Individual</option>
                        <option value="Transportation Company">Transportation Company</option>
                        <option value="Booking Agent">Booking Agent</option>
                        <option value="Travel Agent">Travel Agent</option>
                        <option value="Concierge">Concierge</option>
                        <option value="Assistant">Administrative Assistant / Executive Assistant</option>
                        <option value="Event Planner">Event Planner</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb--20">
                    <div className="col-md-12 col-sm-12 ">
                      <div style={{ marginBottom: '5px' }}>
                        <label style={{ fontSize: '16px' }}>Profile Image</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <input
                            id="profileImageFile"
                            type="file"
                            onChange={this.fileChangedHandler}
                          />
                          <label className="error-text" id="profileImageFileErr"></label>
                          <img src={this.state.profileImageFileURL || 'http://via.placeholder.com/500x500'} width={500} height={500} alt='profile' />
                          <div
                            style={{ visibility: this.state.profileImageFileURL ? 'visible' : 'hidden' }}
                            onClick={() => this.removeFileHandler('profileImageFile')}>
                            <label>
                              <span>Remove File</span>
                              <Delete color="secondary" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ptb--10">
                    <div className="col-lg-12">
                      By signing up, you confirm that you've read and accepted our <a href="/tnc" className="anchor">Terms and Conditions of Service</a> and <a href="/privacy" className="anchor">Privacy Policy</a>.
                    </div>
                  </div>
                  <div className="row ptb--10">
                    <div className="col-lg-12">
                      <button className="rn-button-style--2 btn-solid">Sign Up</button>
                    </div>
                  </div>
                </form>
                <div id="verifyPhoneModal" className="modal">
                  <div className="modal-content1 center">
                    <i className="material-icons modal-close" style={{ float: 'right' }}>close</i>
                    <form className="modalForm " onSubmit={this.verifyPhoneForm}>
                      <h5>Please enter the verification code sent to {this.state.phoneNumber}</h5>
                      <br />
                      <div>
                        <label htmlFor="verificationCode">Enter Verification Code<span className="star">*</span></label>
                        <input type="text" id='verificationCode' onChange={this.handleChange} />
                      </div>
                      <br />
                      <button className="rn-button-style--2 btn-solid">Verify</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    travelerSignUpAction: (creds) => dispatch(travelerSignUp(creds)),
    verifyTravelerSignUpAction: (creds) => dispatch(verifyTravelerSignUp(creds)),
    sendVerificationCodeAction: (creds) => dispatch(sendVerificationCode(creds)),
    verifyCode: (code) => dispatch(verifyCode(code)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelerSignUp)
