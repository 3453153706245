const SAASAgreementMD = `

**SOFTWARE AS A SERVICE AGREEMENT**

Last updated: June 21, 2021

THIS AGREEMENT CONSTITUTES A BINDING CONTRACT AND GOVERNS YOUR USE OF AND ACCESS TO THE SERVICES BY YOU, YOUR AGENTS AND YOUR END-USERS, WHETHER IN CONNECTION WITH A PAID OR FREE TRIAL SUBSCRIPTION TO THE SERVICES.


By accepting this Agreement, either by accessing or using a Service, or authorizing or permitting any Agent or End-User to access or use a Service, You agree to be bound by the terms of this Agreement as of the date of such access or use of the Service (the “Effective Date”). If You are entering into this Agreement on behalf of a company, organization or another legal entity (an “Entity”), You are agreeing to this Agreement for that Entity and representing to FINDANIO LLC that You have the authority to bind such Entity and its Affiliates to this Agreement, in which case the terms “Subscriber,” “You,” “Your” shall refer to such Entity and its Affiliates. If You do not have such authority, or if You do not agree with this Agreement, You must not accept this Agreement and may not use or authorize any use of the Services.


This Software as a Service Agreement (this &quot;Agreement&quot;) is entered into as of Effective Date, by and between you, as an independent operator of a transportation services business and a the user of the FINDANIO platform (and referred to in this Agreement as the &quot;IO&quot;) as defined herein, and Findanio LLC., a Delaware limited liability company (the &quot;Company&quot;) with offices at 1915 N Chestnut Ave, Arlington Heights, Illinois 60004. Each of the IO and the Company are a &quot;Party&quot; under this Agreement and they are, collectively, the &quot;Parties&quot;.

By registering with the Company for the Services (as defined herein) or using the Company programs in any way, the IO agrees to be bound by the terms of this Agreement.

This Agreement is made with reference to the following facts:

A. The Company has created, designed, implemented, manages, markets and licenses to independent transportation operators (whether sole proprietors, corporations, limited liability companies, or any other form or form of entity or business structure) and other businesses, software as a service program(s) named FINDANIO (the &quot;Company Program&quot;), which consists of software, including source codes, object codes and algorithms for the management of reservations and payment for transportation services to be provided by the IO to its customers and clients ;

B. To have access to and utilize the Company Program, the IO desires to subscribe to the services available through, and by use of, the Company Program (the &quot;Services&quot;) , and Company desires to provide the Services to the IO for the subscription fees set forth herein and other good and valuable consideration, including the performance by the IO of all of the IO obligations under this Agreement; and

C. In consideration of the mutual promises and covenants herein, the parties hereby agree as follows:

**1. Services Covered by this Agreement**.

**1.1. Software as a Service**.

**1.1.1. Description of the Company Programs**. The Company Program is comprised of a web-based application that Company hosts as a Service. The Company Program provided as a service consists of an accessible online system whereby the IO can connect with IO existing and potential clients and customers (collectively the &quot;IO Clients&quot;) for providing the IO transportation services to the IO Clients. The Company Program provides the opportunity for the IO Clients to schedule, arrange for and provide payment for, IO transportation services. The Company Program provides system administration, system management, and system monitoring activities and includes the right of the IO and the IO Clients to use the programs, and support services for the Company Programs (collectively, the &quot;Services&quot;). The application currently contains modules for scheduling, payments, evaluations and matching requests to IOs. The term &quot;Company Program&quot; refers to the software products owned and operated by Company to which Company grants the IO and the IO Clients access as part of the Services, including program documentation, and any program updates provided as part of the Services.

**1.1.2. Rights Granted**. For the duration of the term defined in this Agreement, IO will have the nonexclusive, non-assignable, royalty free, limited right to use the Services solely for IO&#39;s internal business operations and subject to the terms of the Agreement. IO may allow IO Clients to use the Services for this purpose. The IO acknowledges that Company has no direct or indirect obligations to any of the IO Clients other than the provision of the Services and the Company has no obligation, responsibility or liability for provision of, or failure to provide, the IO services to the IO Clients. The IO agrees that it does not acquire under the Agreement any license to use the Company Programs in excess of the scope and/or duration of the Services.

**1.2. Discretion of Company**. Company shall have the right, in its sole and absolute discretion, at any time or from time to time to implement new versions of the Services or additions, features, modifications, or enhancements to the Services or any part or component contained therein, or to change the methods by which the Services are delivered, all of which may be implemented by Company from time to time in its sole discretion. Company shall also have the right, in its sole and absolute discretion, at any time or from time to time to stop delivering the Services, provided that Company will not do so during the period for which fees for the services have been paid. Any such modification or alteration of any of the Services shall not create a presumption, of any kind or nature that the Services were in any way defective prior to such modification or alteration. Company shall determine, in its sole and absolute discretion, whether, and to what extent, it shall promote, advertise, market, license, and deliver the Services, and nothing contained herein shall obligate Company to do so. IO agrees that IO has not relied on the future availability of any Services, programs or updates in entering into the payment obligations in the Agreement, provided that the preceding does not relieve Company of its obligation to deliver Services that IO has ordered and paid for per the terms of the Agreement.

**1.3. Ownership and Restrictions**. IO retains all ownership and intellectual property rights in and to IO&#39;s Data. The term &quot;IO&#39;s Data&quot; refers to the data provided by IO that resides in the Company system for the sole purpose of the Company providing the Services to the IO for the IO business of providing transportation services to the IO Clients.. Company retains all ownership and intellectual property rights to the Services and Company Program.

**1.3.1. Additional Restrictions**. IO may not:

1.3.1.1. remove or modify any program markings or any notice of Company&#39;s proprietary rights;

1.3.1.2. make the programs or materials resulting from the Services available in any manner to any third Party for use in the third Party&#39;s business operations;

1.3.1.3. modify, make derivative works of, disassemble, reverse compile, or reverse engineer any part of the Services (the foregoing prohibition includes but is not limited to review of data structures or similar materials produced by programs), or access or use the Services in order to build or support, and/or assist a third Party in building or supporting, products or Services competitive to Company;

1.3.1.4. disclose results of any Services or program benchmark tests without Company&#39;s prior written consent; and

1.3.1.5. license, sell, rent, lease, transfer, assign, distribute, display, host, outsource, disclose, permit timesharing or service bureau use, or otherwise commercially exploit or make the Services, Company Program or materials available, to any third Party other than, as expressly permitted under the terms of the Agreement.

**1.3.2. Additional Conditions**. The rights granted to IO under the Agreement are also conditioned on the following:

1.3.2.1. The IO shall identify each of IO&#39;s users of the Services and the rights of the IO to use the Services shall be limited to the identified users and cannot be shared or used by anyone other than the identified users.

1.3.2.2. Except as expressly provided herein, no part of the Company Program or the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including but not limited to electronic, mechanical, photocopying, recording, or other means.

1.3.2.3. IO agrees to make every reasonable effort to prevent unauthorized third parties from accessing the Services.

1.3.2.4. The IO agrees that, except as specifically set forth in the specifications and documentation of the Company Program any processing, receipt, or storage of any IO Client personal or confidential data is outside the scope of this Agreement. IO will not upload to Company&#39;s servers any confidential personally identifiable information of IO, IO personnel or IO Clients. IO understands, acknowledges and accepts that the Company uses a third Party credit card processing service provider and that the Company does not and will not maintain any IO or IO Client credit card data under this Agreement. Furthermore, Company strictly prohibits the storage or inclusion of personal confidential information in its systems.

1.3.2.5. The IO will obtain and maintain throughout the Term of this Agreement all licenses (including but not limited to a commercial transportation provider license), permits and authorizations necessary and appropriate for the IO to operate a transportation service business and to offer transportation services to IO clients.

**2. Pricing/Compensation/Insurance**.

**2.1. Software as a Service Subscription Fee**. IO shall pay Company the subscription fee set forth on Exhibit A on a monthly or annual basis as set forth on Exhibit A. The IO authorizes the Company to charge the subscription fee of the IO to the credit card registered by the IO with the Company&#39;s credit card services provider and IO agrees that the Company&#39;s credit card services provider shall be the only provider of such services for the relationship between the IO and the Company. The Company may modify the subscription fee at any time upon no less than thirty days prior notice, which notice may be posted on the Company&#39;s website at any time and from time to time. Use of the Company Program after the effective date of a subscription fee changes shall be acceptance of such subscription fee change.

**2.2. Fees and Taxes**. IO agrees to pay for all Services as set forth herein without offset. All fees due under the Agreement are non-cancelable and the sums paid nonrefundable. IO agrees to pay any sales, value-added or other similar taxes that may be imposed by applicable law that Company must pay based on the Services, except for taxes based on Company&#39;s income. The IO understands and agrees that an IO Client who cancels a requested and scheduled service will be charged by the Company a fee for such cancellation (initially such fee will be $5 for each such cancellation). The Company will send an email to such IO Client of the placement of such charge and shall notify IO of such charge as well.

**2.3 Insurance.** During the entire Term of this Agreement, the IO shall obtain and maintain the following insurances coverages: (i) comprehensive general liability in the amount of $1,000,000 per occurrence and in the aggregate; (ii) automobile liability insurances in amounts not less than $100,000 or as required by applicable law. Such insurance shall be issued by companies rated no less than A by AM Best and Company and the IO shall provide the Company with certificates of insurance naming the Company as an additional insured and providing the Company with no less than 30 days&#39; advance notice of termination or cancellation.

**3. Term and Termination**.

**3.1. Term and Termination**. This Agreement shall be effective upon the date the IO is fully registered as a user of the Services and shall continue unless and until terminated by either Party upon no less than thirty days prior notice; provided however, the Company shall have the right to the terminate this Agreement effective immediately upon a breach by IO of its obligations under this Agreement, including but not limited to the failure of the IO to pay the subscription fee when due. Services provided under this Agreement shall be provided for the term of this Agreement. At the end of the term all rights to access or use the Services, including the Company Program, shall end.

**3.2. Termination for Failure to Correct a Default**. If Company ends the Agreement as specified in the preceding section due to a breach by the IO, the Company shall have the right to charge the for and and all amounts which have accrued prior to such end, as well as all sums remaining unpaid for the Services plus related liabilities, taxes and expenses. The Company may agree in its sole discretion to extend the termination of this Agreement due to a breach by the IO if the IO promptly commences and diligently prosecutes to cure the breach. IO agrees that if IO is in default under the Agreement, unless the Company agrees in writing to extend the right to use, IO may not use the Services and the Company may immediately suspend IO&#39;s password, account, and access to or use of the Services.

**3.3. Access to IO Data**. At IO&#39;s request, and for a period of up to 30 days after the termination of this Agreement, Company may, in Company&#39;s sole discretion, permit IO to access the Services solely to the extent necessary for IO to retrieve a file of IO&#39;s Data then in the Services environment. IO agrees and acknowledges that Company has no obligation to retain IO&#39;s Data and that IO&#39;s Data may be irretrievably deleted after 30 days following the termination of the Agreement.

**3.4 Survival**. Provisions that survive termination or expiration of the Agreement are those relating to limitation of liability, infringement indemnity, payment, and others which by their nature are intended to survive.

**4. Warranty, Disclaimers and Exclusive Remedies**.

**4.1. Warranty**. Company warrants that the Services will perform in all material respects in accordance with the following Services policies:

The Company offers free online and telephone [add email and phone] support with this Agreement during business hours of 8:00 am to 5:00 pm CST five days per week. Server or operating system failures are handled through Company&#39;s technology department where detection and support for catastrophic failures are available 24 hours per day, 7 days per week, 365 days per year. It is intended that IO&#39;s access to the Services will be 24 hours per day, 7 days per week, 365 days per year. Nevertheless, it is understood that technical issues could arise. In such cases, Company is committed to restoring IO&#39;s access to the Services as soon as reasonably practicable. Company is required from time to time to conduct maintenance on its servers. Maintenance will take place after hours between 12:00 a.m. 8:00 a.m. CST. If additional downtime is required, IO will be notified via email outlining the maintenance downtime. If the Services provided to IO for any given month during the Services term are not performed as warranted, IO must provide written notice to Company no later than five business days after the last day of that particular month.

**4.2. Disclaimer**. TO THE EXTENT NOT PROHIBITED BY LAW, THE ABOVE WARRANTY IS EXCLUSIVE AND THERE ARE NO OTHER EXPRESS OR IMPLIED WARRANTIES OR COVENANTS BY COMPANY INCLUDING FOR HARDWARE, SYSTEMS, NETWORKS OR ENVIRONMENTS OR FOR MERCHANTABILITY, SATISFACTORY QUALITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT GUARANTEE THAT THE SERVICES WILL BE PERFORMED ERROR-FREE OR UNINTERRUPTED, OR THAT COMPANY WILL CORRECT ALL SERVICES ERRORS. IO ACKNOWLEDGES THAT COMPANY DOES NOT CONTROL THE TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, AND THAT THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES. COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.

**4.3. Exclusive Remedy**. FOR ANY BREACH OF THE ABOVE WARRANTY, COMPANY WILL REMIT A SERVICES FEE CREDIT TO CUSTOMER CALCULATED AT [TEN PERCENT (10%) OF NET MONTHLY SUBSCRIPTION FEES] FOR THE SERVICES FOR THE MONTH IN WHICH THE BREACH OCCURRED. THE CREDIT WILL BE PROVIDED ONLY TOWARDS ANY OUTSTANDING BALANCE FOR SERVICES OWED TO COMPANY, AND THE REMITTANCE OF SUCH CREDIT WILL REPRESENT CUSTOMER&#39;S EXCLUSIVE REMEDY, AND COMPANY&#39;S SOLE LIABILITY, FOR ALL BREACHES OF ANY WARRANTY SPECIFIED IN THE AGREEMENT.

**4.4. Trial Use of the Services**. Services acquired for trial purposes are subject to the terms and conditions of the Agreement, except that they are provided &quot;as is&quot; and Company does not offer any warranty for such Services.

**5. Indemnification**. If a third Party makes a claim against either IO or Company (&quot;Recipient&quot; which may refer to IO or Company depending upon which Party received the Material), that any information, design, specification, instruction, software, service, data, or material (&quot;Material&quot;) furnished by either IO or Company (&quot;Provider&quot; which may refer to IO or Company depending on which Party provided the Material), and used by the Recipient infringes its intellectual property rights, the Provider, at its sole cost and expense, will defend the Recipient against the claim and indemnify the Recipient from the damages, liabilities, costs and expenses awarded by the court to the third Party claiming infringement or the settlement agreed to by the Provider, if the Recipient does the following: (a) notifies the Provider promptly in writing, not later than 30 days after the Recipient receives notice of the claim (or sooner if required by applicable law); (b) gives the Provider sole control of the defense and any settlement negotiations; and (c) gives the Provider the information, authority, and assistance the Provider needs to defend against or settle the claim.

If the Provider believes or it is determined that any of the Material may have violated a third Party&#39;s intellectual property rights, the Provider may choose to either modify the Material to be non-infringing (while substantially preserving its utility or functionality) or obtain a license to allow for continued use, or if these alternatives are not commercially reasonable, the Provider may require return of the applicable Material and refund any unused, prepaid fees the Recipient may have paid to the other Party for such Material.

The Provider will not indemnify the Recipient if the Recipient alters the Material or uses it outside the scope of use identified in the Provider&#39;s user documentation or services policies. The Provider will not indemnify the Recipient to the extent that an infringement claim is based upon any information, design, specification, instruction, software, data, or material not furnished by the Provider.

Company will not indemnify IO to the extent that an infringement claim is based upon the combination of any Material with any products or services not provided by Company. Company will not indemnify IO for infringement caused by IO&#39;s actions against any third Party if the Services as delivered to IO and used in accordance with the terms of the Agreement would not otherwise infringe any third Party intellectual property rights. Company will not indemnify IO for any infringement claim that is based on: (1) a patent that IO was made aware of prior to the date of the Agreement (pursuant to a claim, demand, or notice); or (2) IO&#39;s actions prior to the date of the Agreement. This section provides the Parties&#39; exclusive remedy for any infringement claims or damages.

The IO shall indemnify, defend, save and hold harmless the Company, its Member, manager(s), officers, employees, advisers, representatives, agents and advisors from and against any claims, costs, liabilities, losses, expenses, actions or causes of action that arise out of or result from (i) any negligent act or intentional act or omission of IO; (ii) any breach by IO of its obligations under this Agreement; (ii) the introduction of any virus, malware, ransomware or the like, by the IO into the Company software or systems.

**6. Nondisclosure**. By virtue of the Agreement, the parties may have access to information that is confidential to one another (&quot;Confidential Information&quot;). Each of the parties agrees to disclose only information that is required for the performance of its obligations under the Agreement. Confidential information includes the terms and pricing under the Agreement, the Company&#39;s software, documentation and specifications, IO&#39;s Data residing in the Services environment, and all information clearly identified as confidential at the time of disclosure.

A Party&#39;s confidential information shall not include information that: (a) is or becomes a part of the public domain through no act or omission of the other Party; (b) was in the other Party&#39;s lawful possession prior to the disclosure and had not been obtained by the other Party either directly or indirectly from the disclosing Party; (c) is lawfully disclosed to the other Party by a third Party without restriction on the disclosure; or (d) is independently developed by the other Party.

Each of the parties agrees to hold each other&#39;s confidential information in confidence during the Term and thereafter unless and until one of the exceptions set forth in the preceding paragraph is or becomes applicable. Also, each of the parties agrees to disclose confidential information only to those employees or agents who are required to protect it against unauthorized disclosure in a manner no less protective than under the Agreement. Nothing shall prevent either Party from disclosing the confidential information to a governmental entity as required by law, provided that the Party being required to disclose such information gives the other Party reasonable notice to allow such Party to seek an appropriate protective order.

**7. Limitation of Liability**. NEITHER PARTY SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF REVENUE OR PROFITS (EXCLUDING FEES UNDER THE AGREEMENT), DATA, OR DATA USE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. COMPANY&#39;S MAXIMUM LIABILITY FOR ANY DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT OR TORT, OR OTHERWISE, SHALL IN NO EVENT EXCEED, IN THE AGGREGATE, THE TOTAL AMOUNTS ACTUALLY PAID TO COMPANY FOR THE SERVICES HEREUNDER IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. ANY DAMAGE AWARD IO&#39;S FAVOR AGAINST COMPANY SHALL BE REDUCED BY ANY REFUND OR CREDIT RECEIVED BY IO UNDER THE AGREEMENT AND ANY SUCH REFUND AND CREDIT SHALL APPLY TOWARDS THE LIMITATION OF LIABILITY.

**8. Force Majeure**. Neither Party hereto shall be responsible for failure or delay of performance if caused by: an act of war, hostility, or sabotage; act of God; pandemic; electrical, internet, or telecommunication outage that is not caused by the obligated Party; government restrictions (including the denial or cancellation of any export or other license); other event outside the reasonable control of the obligated Party. Each of the parties will use reasonable efforts to mitigate the effect of a force majeure event. If such event continues for more than 30 days, either of the parties may cancel unperformed Services upon written notice. This section does not excuse either Party&#39;s obligation to take reasonable steps to follow its normal disaster recovery procedures or IO&#39;s obligation to pay for the Services.

**9. Data and Security**

**9.1 IO&#39;s Data**. Company will protect the confidentiality of IO&#39;s Data residing in the Services environment in accordance with the following Company Data Security Practices:

**9.1.1 Data Center Security Policies**. Company&#39;s data center implements Information Security practices to help protect physical access to customer data and systems and to limit access to authorized personnel. This approach reflects an enterprise commitment to the ISO 27000 security framework family. A Security Awareness Policy and Program communicate security expectations to the Data Center workforce during the initial Security training at new employee orientation and are reinforced annually. Quarterly Security Awareness bulletins focus on current security issues and concerns. All visitors are required to sign in via a visitor log. Building Operations, Security, or Data Center Management review and approve visitor access and issue visitor badges, as needed, for identification purposes before access is granted to any non-Google employee or unauthorized Data Center personnel.

Company&#39;s data center servers are protected by a dedicated firewall, which is used to restrict network access to servers to communication protocols authorized by Company. The firewall has enhanced firewall rules, which are in place to restrict access to customer data and to limit the possibility of disruptions to customer operations from unauthorized users.

Data integrity in Company&#39;s data center servers is reinforced by arrays with hot swappable drives to ensure continuous service without loss of data in the event of a hard drive failure.

**9.1.2**  **Data Center Facilities**. The Data Center features redundant HVAC (Heating Ventilation Air Conditioning) units, which provide consistent temperature within the raised floor area. HVAC systems are inspected regularly and air filters are changed periodically.

Data centers are equipped with sensors, including smoke detectors, and floor water detectors, to detect environmental hazards. The data centers are also equipped with raised flooring to protect hardware and communications equipment from water damage. Data centers are equipped with fire detection and suppression systems and fire extinguishers. Fire detection systems, sprinkler systems, and chemical fire extinguishers are inspected at least annually.

Data centers are equipped with uninterruptible power supplies (UPS) to mitigate the risk of short-term utility power failures and fluctuations. The UPS power subsystem is N+1 redundant with instantaneous failover in the event of a primary UPS failure. The UPS systems are inspected daily and inspected / serviced at least annually by a third Party contractor. Data centers are equipped with diesel generators to mitigate the risk of long-term utility power failures and fluctuations. Generators are regularly tested, at least every 120 days, and maintained and serviced as needed, at least annually, by a third Party contractor to provide assurance of appropriate operability in the event of an emergency.

**9.3. Data Center Physical Access**. Google employees are required to display their identity badges at all times when onsite at the Data Center. Two-factor authentication is required to gain access to the data center facilities. Biometric authentication and proximity cards control electromechanical locks. Only authorized Google personnel have access to data center facilities. Closed circuit video surveillance has been installed at all entrance points on the interior and exterior of the buildings housing data centers and is monitored by authorized Google personnel. Google personnel are on duty 24 hours a day, 7 days a week at all of Google&#39;s data center facilities.

**9.4.** Backup of Programs and Data Files – Managed Hosting. Company&#39;s Data Center Service Provider backs up data daily incremental and weekly full using backup utility software stored in a hardened environment. The servers are logically segmented, through the use of a dedicated VLAN on the network for communication to the backup servers. Backups are run after 12 Midnight and do not interrupt service.

The back-up utility software performs backups according to the predetermined schedule and tracks all tapes within the automated tape library using bar codes. Automatic testing of the tape media is performed by the backup software at various times to verify that tape media are suitable for use. All backup failures are logged by the backup utility software. The software utility used by Smart Facility Software offers reports with the most recent backup success/failure status, including the volume of backed up information.

An automated tape library is utilized to track backup tapes. All tapes are bar coded and scanned by the library using the backup utility software. The most current full backup of each customer&#39;s data is stored on site in the tape library. Backup tapes are securely destroyed when their useful life expires. The destruction process physically destroys the media to prevent retrieval of data.

**9.5. Changes to Company Data Security Policies**. The Company Data Security Practices are subject to change at Company&#39;s discretion; however, changes to the Company Data Security Practices will not result in a material reduction in the level of protection provided for IO&#39;s Data during the period for which fees for the Services have been paid. Company reserves the right to provide the Services from locations, and/or through use of subcontractors, worldwide.

**9.6. IO Responsibilities**. IO agrees to provide any notices and obtain any consents related to IO&#39;s use of the Services and Company&#39;s provision of the Services, including those related to the collection, use, processing, transfer and disclosure of personal information. IO shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness and ownership of all of IO&#39;s Data.

**10. Restrictions on Use of the Services**. IO agrees not to use or permit use of the Services, including by uploading, emailing, posting, publishing or otherwise transmitting any material, for any purpose that may (a) menace or harass any person or cause damage or injury to any person or property, (b) involve the publication of any material that is false, defamatory, harassing or obscene, (c) violate privacy rights or promote bigotry, racism, hatred or harm, (d) constitute unsolicited bulk e-mail, &quot;junk mail&quot;, &quot;spam&quot; or chain letters; (e) constitute an infringement of intellectual property or other proprietary rights, or (f) otherwise violate applicable laws, ordinances or regulations. In addition to any other rights afforded to Company under the Agreement, Company reserves the right to remove or disable access to any material that violates the foregoing restrictions. Company shall have no liability to IO in the event that Company takes such action. IO agrees to defend and indemnify Company against any claim arising out of a violation of IO&#39;s obligations under this section.

**11. Statistical Information**. Company may compile statistical information related to the performance of the Services. Company may make such information publicly available, provided that such information does not incorporate identify IO&#39;s confidential information or include IO&#39;s company&#39;s name. Company retains all intellectual property rights in such information.

**12. Other**.

**12.1. No Assignment**. IO may not assign this Agreement, give or transfer the Services or an interest in them to another individual or entity, or delegate the duties provided for under the terms of this Agreement without the prior written consent of Company, which consent Company may withhold in the exercise of its absolute discretion. All terms and provisions of this Agreement shall be binding upon and inure to the benefit of the parties hereto and their respective permitted transferees, successors and assigns.

**12.2. Status of the Parties**. Company is an independent contractor and, the parties agree that no partnership, joint venture, or agency relationship exists between them. Nothing in this Agreement shall be construed in any manner as to make either Party the agent or legal representative of the other Party for any purpose whatsoever.

**12.3. Consents**. IO shall obtain at IO&#39;s sole expense any rights and consents from third parties necessary for Company to perform the Services under the Agreement.

**12.4. Notices**. If IO has a dispute with Company or if IO wishes to provide a notice under the Indemnification section of this Agreement, or if IO becomes subject to insolvency or other similar legal proceedings, IO will promptly send written notice to: Company at the address set forth in the preamble to this Agreement. Company may give notice applicable to Company&#39;s software as a service customer base by means of a general notice on the Company portal for the Services, and notices specific to IO by electronic mail to IO&#39;s e-mail address on record in Company&#39;s account information or by written communication sent by first class mail or pre-paid post to IO&#39;s address on record in Company&#39;s account information.

**12.5. Limitation of Action**. Except for actions for nonpayment or breach of Company&#39;s proprietary rights, no action, regardless of form, arising out of or relating to the Agreement may be brought by either Party more than two years after the cause of action has accrued.

**12.6 Audit**. Company may audit IO&#39;s use of the Services. IO agrees to cooperate with Company&#39;s audit and provide reasonable assistance and access to information. Any such audit shall not unreasonably interfere with IO&#39;s normal business operations. IO agrees to pay within 30 days of written notification any fees applicable to IO&#39;s use of the Services in excess of IO&#39;s rights. If IO does not pay, Company can end IO&#39;s Services and/or the Agreement. IO agrees that Company shall not be responsible for any of IO&#39;s costs incurred in cooperating with the audit.

**12.7 Entire Agreement**. IO agrees that this Agreement, including the Exhibits attached hereto, is the complete Agreement for the Services, and that the Agreement supersedes all prior or contemporaneous agreements or representations, written or oral, regarding such Services. If any term of the Agreement is found to be invalid or unenforceable, the remaining provisions will remain effective and such term shall be replaced with a term consistent with the purpose and intent of the Agreement. It is expressly agreed that the terms of the Agreement shall supersede the terms in any purchase order or other non-Company document and no terms included in any such purchase order or other non-Company document shall apply to the Services. The Agreement may not be modified and the rights and restrictions may not be altered or waived except in a writing signed by authorized representatives of IO and of Company.

**12.8 Governing Law, Jurisdiction and Waiver of Venue**. This Agreement shall be governed by and construed in accordance with the substantive and procedural laws of the State of Illinois regardless of the fact that any of the parties hereto may be or may become a resident of a different country, state, or jurisdiction. Any suit or action arising out of this Agreement shall be filed in a court of competent jurisdiction within the County of Cook, State of Illinois. The parties hereby consent to the personal jurisdiction of such courts within Cook County, State of Ilinois. The parties hereby waive any objections to venue in such courts within Cook County, State of Illinois.

**12.9 Attorneys&#39; Fees**. If any action, suit or other proceeding is instituted concerning or arising out of this Agreement, the prevailing Party shall recover all of such Party&#39;s reasonable costs and attorneys&#39; fees incurred in each and every such action, suit or other proceeding, including any and all appeals or petitions there from.

**12.10 No Waiver**. The waiver by any Party hereto of any breach or default shall not constitute a waiver of any different or subsequent breach or default. No waiver to this Agreement shall be effective unless stated in writing and signed by an authorized representative of each Party.


~~~
EXHIBIT A

SUBSCRIPTION AND OTHER FEES

https://beta.findanio.com/pricing

~~~
`;

export default SAASAgreementMD;