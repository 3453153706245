import axios from 'axios';
import { baseURL } from "../../components/constants/CommonConstants";
import { validate } from '../../components/util/validateVIN'

export const clearErrorAction = () => {
  // console.log('clearErrorAction')
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: 'CLEAR_ERROR' });
  }
};

export const clearSearchResults = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: 'CLEAR_SEARCH_RESULTS' });
  }
};

function isValid(element) {
  return (element === null || element !== '' || element.trim() !== '')
};

export const updateProfile = (updatedProfile) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // console.log('Inside authActions - updatedProfile', updatedProfile);
    const uid = updatedProfile.uid;
    const firestore = getFirestore();
    //validations
    if (updatedProfile.accountType === 'Traveler' && (!isValid(updatedProfile.firstName) || !isValid(updatedProfile.lastName))) {
      // console.log('invalid data')  
      dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Please enter valid data and try again.' });
    } else if (updatedProfile.accountType === 'Operator' && (!isValid(updatedProfile.firstName) || !isValid(updatedProfile.lastName))) {
      // console.log('invalid data')  
      dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Please enter valid data and try again.' });
    } else {
      // console.log('valid data')
      //get firebase user profile by uid
      await firestore.collection('users').doc(uid).get()
        .then(async (doc) => {
          if (doc.exists) {
            let existingUserDetails = doc.data();
            if (existingUserDetails.email !== updatedProfile.email) {
              // console.log('profile not found');
              dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Failed to update profile' });
              return;
            } else {
              if (updatedProfile.accountType === 'Traveler') {
                // console.log('update Traveler profile');
                return firestore.collection('users').doc(uid).update({
                  firstName: updatedProfile.firstName,
                  lastName: updatedProfile.lastName,
                  officePhoneNumber: updatedProfile.officePhoneNumber,
                  initials: updatedProfile.firstName[0] + updatedProfile.lastName[0],
                  updatedAt: new Date()
                })
                  .then((data) => {
                    dispatch({ type: 'UPDATED_PROFILE_SUCCESSFULLY' });
                    return;
                  }).catch(err => {
                    // console.log(err);
                    dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Failed to update profile' });
                    return;
                  });
              } else {

                //check if state and/or airports have changed
                if (existingUserDetails.state !== updatedProfile.state || existingUserDetails.airports !== updatedProfile.airports) {
                  // state has changed
                  if (existingUserDetails.state !== updatedProfile.state) {
                    //remove operator from previous state
                    await firestore.collection('operatorLocations').doc(existingUserDetails.state).update({
                      ['operators.' + existingUserDetails.carType]: firestore.FieldValue.arrayRemove(uid)
                    });

                    //add operator from previous state
                    const operatorLocation = await firestore.collection('operatorLocations').doc(updatedProfile.state);
                    await operatorLocation.get()
                      .then((docSnapshot) => {
                        if (docSnapshot.exists) {
                          //update firestore document
                          firestore.collection('operatorLocations').doc(updatedProfile.state).update({
                            ['operators.' + updatedProfile.carType]: firestore.FieldValue.arrayUnion(uid)
                          });
                        } else {
                          //create firestore document
                          firestore.collection('operatorLocations').doc(updatedProfile.state).set({
                            'operators': { [updatedProfile.carType]: firestore.FieldValue.arrayUnion(uid) }
                          });
                        }
                      });
                  }

                  // remove operator from old airports in operatorLocations
                  await existingUserDetails.airports.forEach(ap => {
                    firestore.collection('operatorLocations').doc(existingUserDetails.state).update({
                      ['airports.' + ap + '.' + existingUserDetails.carType]: firestore.FieldValue.arrayRemove(uid)
                    });
                  });
                  // update airports in operatorLocations
                  await updatedProfile.airports.forEach(ap => {
                    firestore.collection('operatorLocations').doc(updatedProfile.state).update({
                      ['airports.' + ap + '.' + updatedProfile.carType]: firestore.FieldValue.arrayUnion(uid)
                    });
                  });
                }

                // console.log('update operator profile', uid, updatedProfile);
                return firestore.collection('users').doc(uid).update({
                  firstName: updatedProfile.firstName,
                  lastName: updatedProfile.lastName,
                  initials: updatedProfile.firstName[0] + updatedProfile.lastName[0],
                  officePhoneNumber: updatedProfile.officePhoneNumber,
                  petFriendly: updatedProfile.petFriendly,
                  receiveEmails: updatedProfile.receiveEmails,
                  receiveTexts: updatedProfile.receiveTexts,
                  companyName: updatedProfile.companyName,
                  q1: updatedProfile.q1,
                  q2: updatedProfile.q2,
                  q3: updatedProfile.q3,
                  q4: updatedProfile.q4,
                  vehicleVIN: updatedProfile.vehicleVIN,
                  carPlate: updatedProfile.carPlate,
                  carMake: updatedProfile.carMake,
                  carModel: updatedProfile.carModel,
                  carYear: updatedProfile.carYear,
                  carColor: updatedProfile.carColor,
                  otherColor: updatedProfile.otherColor || '',
                  carType: updatedProfile.carType,
                  liabilityInsuranceAmt: updatedProfile.liabilityInsuranceAmt,
                  cancellationPolicy: updatedProfile.cancellationPolicy,
                  state: updatedProfile.state,
                  airports: updatedProfile.airports,
                  profileDescription: updatedProfile.profileDescription,
                  updatedAt: new Date()
                })
                  .then((data) => {
                    // console.log('done updating')
                    dispatch({ type: 'UPDATED_PROFILE_SUCCESSFULLY' });
                    return;
                  }).catch(err => {
                    // console.log('error', err);
                    dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Failed to update profile' });
                    return;
                  });
              }
            }
          }
        }).catch(err => {
          // console.log('error caught - ', err)
          dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Failed to update profile' });
          return;
        });
    }
  }
}

export const validateOperatorSignupStepAction = (step, credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    let validStep = true;
    // console.log(step, credentials);
    if (credentials) {
      if (step === 1) {
        //validate step 1
        if (!isValid(credentials.email) || !isValid(credentials.password)
          || !isValid(credentials.firstName) || !isValid(credentials.lastName)
          || !isValid(credentials.phoneNumber)) {
          validStep = false;
          dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR' });
        } else if (credentials.password.length < 8) {
          //invalid phone
          validStep = false;
          dispatch({ type: 'INVALID_PASSWORD' });
        } else if (credentials.phoneNumber.length !== 10) {
          //invalid phone
          validStep = false;
          dispatch({ type: 'INVALID_PHONE' });
        } else {
          // check if user exists with given email and phone
          const inputData = {
            email: credentials.email,
            phoneNumber: credentials.phoneNumber
          }
          const url = baseURL + '/checkUserExists';
          await axios.post(url, inputData)
            .then(async (respObj) => {
              if (respObj.data.type === 'EMAIL_EXISTS') {
                validStep = false;
                dispatch({ type: 'EMAIL_EXISTS' })
              } else if (respObj.data.type === 'PHONE_EXISTS') {
                validStep = false;
                dispatch({ type: 'PHONE_EXISTS' })
              }
            })
            .catch((err) => {
              validStep = false;
              dispatch({ type: 'SIGNUP_INVALID' })
            });
        }
      } else if (step === 2) {
        // console.log('airports =',credentials.airports.length)
        //validate step 2
        if (!isValid(credentials.carPlate) || !isValid(credentials.vehicleVIN) || !isValid(credentials.carYear)
          || !isValid(credentials.state) || !isValid(credentials.carType) || !isValid(credentials.carColor)
          || !isValid(credentials.carMake) || !isValid(credentials.carModel)
          || credentials.airports.length === 0) {
          validStep = false;
          // console.log('invalid step 2')
          dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR' });
        } else if (validate(credentials.vehicleVIN) === false) {
          // console.log('invalid VIND')
          validStep = false;
          dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR_VIN' });
        }
      } else if (step === 3) {
        //validate step 3, validations for uploaded file
        if (credentials.profileImageFile === null || !credentials.profileImageFile
          || credentials.vehicleImageFile === null || !credentials.vehicleImageFile
          || credentials.licenseFile === null || !credentials.licenseFile
          || credentials.insuranceFile === null || !credentials.insuranceFile
          || credentials.vehicleRegistrationImageFile === null || !credentials.vehicleRegistrationImageFile
        ) {
          validStep = false;
          dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR' });
        }
      } else if (step === 4) {
        //validate step 1
        if (!isValid(credentials.receiveEmails) || !isValid(credentials.receiveTexts)) {
          validStep = false;
          dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR' });
        }
      } else if (step === 5) {
        // no validation checks for step 5
      } else {
        //invalid step
        dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR' });
        validStep = false;
      }
    } else {
      //credentials do not exist
      dispatch({ type: 'OPERATOR_SIGNUP_STEP_ERROR' });
      validStep = false;
    }
    return validStep;
  }
};

export const login = (credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    if (isValid(credentials.email) && isValid(credentials.password)) {
      await firebase.auth().signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      ).then((res) => {
        // console.log('res = ', res, res.user.uid)
        firestore.collection('users').doc(res.user.uid).get()
          .then((userProfile) => {
            // console.log('userProfile = ', userProfile.data());
            if (userProfile.data().accountType === 'Operator') {
              dispatch({ type: 'LOGIN_SUCCESS', email: credentials.email, loggedInOperatorMode: 'OperatorMode' });
            } else {
              dispatch({ type: 'LOGIN_SUCCESS', email: credentials.email, loggedInOperatorMode: null });
            }
          })
      }).catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', email: credentials.email });
      });
    } else {
      dispatch({ type: 'LOGIN_ERROR', email: credentials.email });
    }
  }
};

export const updatePassword = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let user = firebase.auth().currentUser;
    if (user) {
      if (credentials.newPassword && credentials.currentPassword && user.email) {
        //re-authenticate user before updating password
        var cred = firebase.auth.EmailAuthProvider.credential(user.email, credentials.currentPassword);
        user.reauthenticateWithCredential(cred).then(function () {
          // User re-authenticated, now update the new password
          user.updatePassword(credentials.newPassword)
            .then(() => {
              //password successfully updated
              dispatch({ type: 'PASSWORD_UPDATE_SUCCESSFUL' });
            })
            .catch((err) => {
              //error while updating password
              dispatch({ type: 'PASSWORD_UPDATE_FAILED', err: "Please try again later." });
            });
        }).catch(function (err) {
          //error while re-authenticating user
          dispatch({ type: 'PASSWORD_UPDATE_FAILED', err: "Please try again later." });
        });
      } else {
        dispatch({ type: 'PASSWORD_UPDATE_FAILED', err: "Please enter required fields." });
      }
    } else {
      // No user is signed in
      dispatch({ type: 'NO_USER_LOGGED_IN' });
    }
  }
}

export const forgotPassword = (credentials) => {
  return async (dispatch, getState, { getFirebase }) => {
    if (!isValid(credentials.email)) {
      dispatch({ type: 'FORGOT_PASSWORD_FAILED' });
    } else {
      const inputData = {
        toEmail: credentials.email
      }
      const url = baseURL + '/sendForgotPasswordEmail';
      await axios.post(url, inputData)
        .then(async (respObj) => {
          // console.log('respObj', respObj);
          // Forgot password email sent successfully
          dispatch({ type: 'FORGOT_PASSWORD_SUCCESSFUL' });
        })
        .catch((err) => {
          // console.log('err', err)
          dispatch({ type: 'FORGOT_PASSWORD_FAILED', err });
        });
    }
  }
}

export const logout = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    // firebase.auth().signOut().then(() => {
    firebase.logout().then(() => {
      dispatch({ type: 'LOGOUT_SUCCESS' })
    }).catch(function (error) {
      // An error happened
      // console.log('An error occurred while logging out', error)
    });
  }
}

export const operatorsignUpAction = (newUser) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // console.log('operatorsignUpAction - ', newUser)
    const firebase = getFirebase();
    const firestore = getFirestore();

    let users = firestore.collection('users');
    //check if user exists by phone number
    return users.where('phoneNumber', '==', newUser.phoneNumber).get()
      .then(snapshot => {
        if (snapshot.empty) {
          //create user in firebase
          return firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
          ).then(async (resp) => {
            const uid = resp.user.uid;
            if (newUser.profileImageFile) {
              const formData = new FormData();
              formData.append(
                'image',
                newUser.profileImageFile,
                newUser.profileImageFile.name
              )
              const url = baseURL + '/uploadFile?category=profile&id=' + uid;
              axios.post(url, formData);
            }
            if (newUser.licenseFile) {
              const formData = new FormData();
              formData.append(
                'image',
                newUser.licenseFile,
                newUser.licenseFile.name
              )
              const url = baseURL + '/uploadFile?category=license&id=' + uid
              axios.post(url, formData);
            }
            if (newUser.insuranceFile) {
              const formData = new FormData();
              formData.append(
                'image',
                newUser.insuranceFile,
                newUser.insuranceFile.name
              )
              const url = baseURL + '/uploadFile?category=insurance&id=' + uid
              axios.post(url, formData);
            }
            if (newUser.vehicleImageFile) {
              const formData = new FormData();
              formData.append(
                'image',
                newUser.vehicleImageFile,
                newUser.vehicleImageFile.name
              )
              const url = baseURL + '/uploadFile?category=vehicle&id=' + uid
              axios.post(url, formData);
            }
            if (newUser.vehicleRegistrationImageFile) {
              const formData = new FormData();
              formData.append(
                'image',
                newUser.vehicleRegistrationImageFile,
                newUser.vehicleRegistrationImageFile.name
              )
              const url = baseURL + '/uploadFile?category=vehicleRegistration&id=' + uid
              axios.post(url, formData);
            }
            const profileImageUrl = newUser.profileImageFile ? newUser.profileImageFile.name : null
            const licenseImageUrl = newUser.licenseFile ? newUser.licenseFile.name : null
            const insuranceImageUrl = newUser.insuranceFile ? newUser.insuranceFile.name : null
            const vehicleImageUrl = newUser.vehicleImageFile ? newUser.vehicleImageFile.name : null
            const vehicleRegistrationImageUrl = newUser.vehicleRegistrationImageFile ? newUser.vehicleRegistrationImageFile.name : null

            // update operatorLocation
            const operatorLocation = await firestore.collection('operatorLocations').doc(newUser.state)
            await operatorLocation.get()
              .then((docSnapshot) => {
                if (docSnapshot.exists) {
                  //update firestore document
                  firestore.collection('operatorLocations').doc(newUser.state).update({
                    ['operators.' + newUser.carType]: firestore.FieldValue.arrayUnion(uid)
                  });
                } else {
                  //create firestore document
                  firestore.collection('operatorLocations').doc(newUser.state).set({
                    'operators': { [newUser.carType]: firestore.FieldValue.arrayUnion(uid) }
                  });
                }
              });

            // console.log('setting the operators in airports');
            await newUser.airports.forEach(ap => {
              firestore.collection('operatorLocations').doc(newUser.state).update({
                ['airports.' + ap + '.' + newUser.carType]: firestore.FieldValue.arrayUnion(uid)
              });
            })

            // create firestore operator profile
            await firestore.collection('users').doc(uid).set({
              createdAt: new Date(),
              email: newUser.email,
              firstName: newUser.firstName.toUpperCase(),
              lastName: newUser.lastName.toUpperCase(),
              initials: newUser.firstName[0].toUpperCase() + newUser.lastName[0].toUpperCase(),
              phoneNumber: newUser.phoneNumber,
              officePhoneNumber: newUser.officePhoneNumber,
              vehicleVIN: newUser.vehicleVIN,
              carMake: newUser.carMake,
              carModel: newUser.carModel,
              carYear: newUser.carYear,
              carPlate: newUser.carPlate,
              carColor: newUser.carColor,
              carType: newUser.carType,
              state: newUser.state,
              airports: newUser.airports,
              accountType: newUser.accountType,
              petFriendly: newUser.petFriendly,
              receiveEmails: newUser.receiveEmails,
              receiveTexts: newUser.receiveTexts,
              companyName: newUser.companyName,
              profileImageUrl: profileImageUrl,
              licenseImageUrl: licenseImageUrl,
              insuranceImageUrl: insuranceImageUrl,
              vehicleImageUrl: vehicleImageUrl,
              vehicleRegistrationImageUrl: vehicleRegistrationImageUrl,
              q1: newUser.q1,
              q2: newUser.q2,
              q3: newUser.q3,
              q4: newUser.q4,
              liabilityInsuranceAmt: newUser.liabilityInsuranceAmt,
              cancellationPolicy: newUser.cancellationPolicy,
              address: newUser.address,
              approved: false,
              profileDescription: newUser.profileDescription
            });

            //send welcome email
            const payload = {
              "toEmail": newUser.email,
              "firstName": newUser.firstName,
              "token": "sedrtvygbhjsdnk2f36ybun"
            }
            const url = baseURL + '/sendWelcomeEmailToOperator'
            await axios.post(url, payload);

          })
            .then(() => {
              dispatch({ type: 'SIGNUP_SUCCESS' });
              return true
            }).catch((err) => {
              dispatch({ type: 'SIGNUP_ERROR', err });
              return false
            });

        } else {
          dispatch({ type: 'PHONE_EXISTS' });
          return false;
        }
      })
      .catch(err => {
        // console.log('Error getting documents', err);
        dispatch({ type: 'SIGNUP_ERROR' }, err);
        return false;
      });
  }
}


export const sendVerificationCode = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // console.log('inside sendVerificationCode authAction - data: ', data);
    const url = baseURL + '/sendTwilioVerificationToken?phone=%2B1' + data.phoneNumber
    let response = await axios.get(url)
      .then(responseData => {
        // console.log('verifyPhone - responseData - ', responseData);
        // console.log('verifyPhone - responseData - ', responseData.data);
        return responseData.data;
      })
      .catch(err => {
        // console.log('Error in verifyPhone', err);
        dispatch({ type: 'INVALID_PHONE' });
        return { err: err };
      });
    // console.log('response = ', response);
    return response;
  }
}

export const verifyCode = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // console.log('inside verifyCode authAction - data: ', data);
    const url = baseURL + '/checkTwilioVerificationToken?phone=%2B1' + data.phoneNumber + '&code=' + data.code
    let response = await axios.get(url)
      .then(responseData => {
        // console.log('verifyCode - responseData - ', responseData);
        // console.log('verifyCode - responseData - ', responseData.data);
        return responseData.data;
      })
      .catch(err => {
        // console.log('Error in verifyPhone - ', err);
        dispatch({ type: 'PHONE_VERIFICATION_ERROR' }, err);
        return { err: err };
      });
    // console.log('response = ', response);
    return response;
  }
}

export const verifyTravelerSignUp = (newUser) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    let valid = true;
    if (isValid(newUser.email) && isValid(newUser.password)
      && isValid(newUser.firstName) && isValid(newUser.lastName)
      && isValid(newUser.phoneNumber) && isValid(newUser.requestorCategory)) {
      if (newUser.phoneNumber.length !== 10) {
        //invalid phone
        valid = false;
        dispatch({ type: 'INVALID_PHONE', errMsg: 'Please enter a valid cell phone number' });
      } else if (newUser.password.length < 8) {
        //invalid phone
        valid = false;
        dispatch({ type: 'INVALID_PASSWORD' });
      } else if (newUser.officePhoneNumber && newUser.officePhoneNumber.length !== 10) {
        //invalid phone
        valid = false;
        dispatch({ type: 'INVALID_PHONE', errMsg: 'Please enter a valid office phone number' });
      } else {
        const inputData = {
          email: newUser.email,
          phoneNumber: newUser.phoneNumber
        }
        const url = baseURL + '/checkUserExists';
        await axios.post(url, inputData)
          .then(async (respObj) => {
            if (respObj.data.type === 'EMAIL_EXISTS') {
              valid = false;
              dispatch({ type: 'EMAIL_EXISTS' })
            } else if (respObj.data.type === 'PHONE_EXISTS') {
              valid = false;
              dispatch({ type: 'PHONE_EXISTS' })
            }
          })
          .catch((err) => {
            // console.log('error - ', err)
            valid = false;
            dispatch({ type: 'SIGNUP_INVALID' })
          });
      }
    } else {
      valid = false;
      dispatch({ type: 'SIGNUP_INVALID' });
    }
    return valid;
  }
}

export const travelerSignUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    if (isValid(newUser.email) && isValid(newUser.password)
      && isValid(newUser.firstName) && isValid(newUser.lastName)
      && isValid(newUser.phoneNumber)) {
      const firebase = getFirebase();
      const firestore = getFirestore();
      let users = firestore.collection('users');
      users.where('phoneNumber', '==', newUser.phoneNumber).get()
        .then(snapshot => {
          if (snapshot.empty) {
            // console.log('No matching documents.');
            //create user
            firebase.auth().createUserWithEmailAndPassword(
              newUser.email,
              newUser.password
            ).then(async resp => {
              // console.log('created user - resp = ', resp)
              const uid = resp.user.uid;
              if (newUser.profileImageFile) {
                const formData = new FormData();
                formData.append(
                  'image',

                  newUser.profileImageFile,
                  newUser.profileImageFile.name
                )
                const url = baseURL + '/uploadFile?category=profile&id=' + uid;
                axios.post(url, formData);
              }
              const profileImageUrl = newUser.profileImageFile ? newUser.profileImageFile.name : null
              firestore.collection('users').doc(uid).set({
                createdAt: new Date(),
                uid: uid,
                email: newUser.email,
                firstName: newUser.firstName.toUpperCase(),
                lastName: newUser.lastName.toUpperCase(),
                initials: newUser.firstName[0].toUpperCase() + newUser.lastName[0].toUpperCase(),
                accountType: newUser.accountType,
                companyName: newUser.companyName,
                phoneNumber: newUser.phoneNumber,
                officePhoneNumber: newUser.officePhoneNumber,
                profileImageUrl: profileImageUrl,
                requestorCategory: newUser.requestorCategory
              });

              //send verification email
              const payload = {
                "toEmail": newUser.email,
                "firstName": newUser.firstName,
                "token": "sedrtvygbhjsdnk2f36ybun"
              }
              const url = baseURL + '/sendWelcomeEmailToTraveler'
              await axios.post(url, payload);

            }).then(() => {
              // console.log('sign up successful');
              dispatch({ type: 'SIGNUP_SUCCESS' });
            }).catch((err) => {
              // console.log('sign up failed ', err);
              dispatch({ type: 'SIGNUP_ERROR', err });
            });
          } else {
            dispatch({ type: 'PHONE_EXISTS' });
          }
        })
        .catch(err => {
          // console.log('Error getting documents', err);
          dispatch({ type: 'SIGNUP_ERROR' }, err);
          return;
        });
    }
  }
}

export const updateImage = (obj) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    if (isValid(obj.imageFile) && isValid(obj.category) && isValid(obj.uid)) {
      const firestore = getFirestore();
      // console.log('auth actions - updateImage - ', obj);
      const formData = new FormData();
      formData.append(
        'image',
        obj.imageFile,
        obj.imageFile.name
      )
      const url = baseURL + '/updateFile?category=' + obj.category + '&id=' + obj.uid;
      axios.post(url, formData);

      const imageName = obj.imageFile ? obj.imageFile.name : null;

      let categoryName = '';
      switch (obj.category) {
        case 'profile': categoryName = 'profileImageUrl'; break;
        case 'license': categoryName = 'licenseImageUrl'; break;
        case 'insurance': categoryName = 'insuranceImageUrl'; break;
        case 'vehicle': categoryName = 'vehicleImageUrl'; break;
        case 'vehicleRegistration': categoryName = 'vehicleRegistrationImageUrl'; break;
        default: break;
      }
      // console.log('categoryName = ', categoryName);
      setTimeout(() => {
        firestore.collection('users').doc(obj.uid).update({
          [categoryName]: imageName,
        });
        dispatch({ type: 'UPDATED_PROFILE_SUCCESSFULLY' })
      }, 7000);
    }
  }
}


export const processSubscriptionConfirmationAction = (id, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - processSubscriptionConfirmation', id, uid)
    if (!id || !uid) {
      // console.log('Bad data in processSubscriptionConfirmationAction');
      dispatch({ type: 'ERROR_PROCESSING_SUB' });
      return;
    }
    const inputData = {
      sessionId: id,
      user: uid
    }
    const url = baseURL + '/processStripeSession';
    await axios.post(url, inputData)
      .then(async (res) => {
        dispatch({ type: 'PROCESSED_SUB', data: 'success' });
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch({ type: 'ERROR_PROCESSING_SUB' }, err);
      });
  }
};


export const processRideConfirmationAction = (id, uid, projectId) => {
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - processRideConfirmationAction')
    if (!id || !uid || !projectId) {
      // console.log('Bad data in processSubscriptionConfirmationAction');
      dispatch({ type: 'ERROR_PROCESSING_RIDE_PAYMENT' });
      return;
    }
    const inputData = {
      sessionId: id
    }
    const url = baseURL + '/processStripeSessionRidePaid';
    axios.post(url, inputData)
      .then(async (res) => {
        let data = res.data;
        const firestore = getFirestore();
        await firestore.collection('users').doc(uid).get()
          .then((doc) => {
            if (doc.exists) {
              if (doc.data().email !== data.customer_email) {
                dispatch({ type: 'ERROR_PROCESSING_RIDE_PAYMENT' });
                return;
              } else {
                //update user profile
                firestore.collection('users').doc(uid).update({
                  paidRides: firestore.FieldValue.arrayUnion(projectId)
                })
                  .then(() => {
                    //update project
                    firestore.collection('projects').doc(projectId).update({
                      requestorPaid: true
                    }).then(async () => {

                      var projectDetails = await firestore.collection('projects').doc(projectId).get();

                      dispatch({ type: 'PROCESSED_RIDE_PAYMENT', project: projectDetails.data() });
                      return;
                    }).catch(err => {
                      dispatch({ type: 'ERROR_PROCESSING_RIDE_PAYMENT' }, err);
                      return;
                    });
                  }).catch(err => {
                    dispatch({ type: 'ERROR_PROCESSING_RIDE_PAYMENT' }, err);
                    return;
                  });
              }
            }
          }).catch(err => {
            dispatch({ type: 'ERROR_PROCESSING_RIDE_PAYMENT' }, err);
            return;
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_PROCESSING_RIDE_PAYMENT' }, err);
      });
  }
};

// export const callOperatorAction = (uid) => {
//   return async (dispatch, getState, { getFirebase, getFirestore }) => {
//     // console.log('inside callOperatorAction authAction - uid: ', uid);
//     const url = baseURL + '/generateTwilioCapabilityToken';
//     return await axios.get(url)
//       .then(async (twilioResp) => {
//         // console.log('callOperatorAction - twilioResp - ', twilioResp.data);
//         let token = twilioResp.data;
//         if (token) {
//           const firestore = getFirestore();
//           await firestore.collection('users').doc(uid).get()
//             .then((doc) => {
//               if (doc.exists) {
//                 let phoneNumber = '1' + doc.data().phoneNumber;
//                 // console.log('phoneNumber -  ', phoneNumber);
//                 // console.log('token -  ', token);

//                 window.Twilio.Device.setup(token);

//                 window.Twilio.Device.ready(function (device) {
//                   // console.log('Ready');
//                 });

//                 window.Twilio.Device.error(function (error) {
//                   // console.log('Error: ' + error.message);
//                   dispatch({ type: 'ERROR_CALLING', msg: error.message });
//                 });

//                 const params = { To: phoneNumber };

//                 // console.log('Calling ' + params.To);
//                 window.Twilio.Device.connect(params);

//               }
//             }).catch(err => {
//               // console.log('error - no user found - ', err);
//               dispatch({ type: 'ERROR_CALLING', msg: err.message });
//             });
//         } else {
//           // console.log('no token found')
//           dispatch({ type: 'ERROR_CALLING', msg: 'Phone number not found.' });

//         }
//       })
//       .catch(err => {
//         // console.log('error - token -  ', err);
//         dispatch({ type: 'ERROR_CALLING', msg: 'Cannot call currently. Please try again.' });
//       });
//   }
// }

export const getUserProfile = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const userProfile = await firestore.collection('users').doc(uid).get();

    if (userProfile !== undefined && userProfile.exists) {
      dispatch({ type: 'RETRIEVED_USER_DETAILS', userProfile: userProfile.data() });
    } else {
      dispatch({ type: 'RETRIEVED_USER_DETAILS', userProfile: 'BadData' });
    }
  }
};

export const checkAdminRole = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const adminRole = await firestore.collection('admin').doc(uid).get();
    if (adminRole !== undefined && adminRole.exists) {
      dispatch({ type: 'RETRIEVED_ADMIN_DETAILS', adminRole: adminRole.data().role });
    } else {
      dispatch({ type: 'RETRIEVED_ADMIN_DETAILS', adminRole: 'none' });
    }
  }
};

export const getLoggedInOperatorMode = (uid) => {
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside getLoggedInOperatorMode');
    const firestore = getFirestore();
    firestore.collection('users').doc(uid).get()
      .then(userProfile => {
        if (userProfile !== undefined && userProfile.exists) {
          if (userProfile.data().accountType === 'Operator') {
            dispatch({ type: 'GET_LOGGED_IN_OPERATOR_MODE', loggedInOperatorMode: 'OperatorMode' });
          } else {
            dispatch({ type: 'GET_LOGGED_IN_OPERATOR_MODE', loggedInOperatorMode: null });
          }
        } else {
          dispatch({ type: 'GET_LOGGED_IN_OPERATOR_MODE', loggedInOperatorMode: null });
        }
      })
  }
};

export const toggleLoggedInOperatorMode = () => {
  return (dispatch, getState) => {
    // console.log('inside toggleLoggedInOperatorMode');
    dispatch({ type: 'TOGGLE_LOGGED_IN_OPERATOR_MODE' });
  }
};

export const updateUserConnectState = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside updateUserConnectState - ', obj);
    const firestore = getFirestore();
    await firestore.collection('users').doc(obj.uid).update({
      stripeConnectState: obj.stripeConnectState
    })
      .then((data) => {
        // console.log('updated')
        dispatch({ type: 'UPDATED_PROFILE_SUCCESSFULLY' });
      }).catch(err => {
        // console.log('error')
        dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Error occurred' });
      });
  }
};

export const handleStripeConnectAccount = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside handleStripeConnectAccount - ', obj);
    const firestore = getFirestore();

    //verify code
    const userProfile = await firestore.collection('users').doc(obj.uid).get();
    if (userProfile.data().stripeConnectState !== obj.stripeConnectState) {
      // console.log('no match');
      dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Error occurred' });
    } else {
      // console.log('stripeConnectState match');
      const url = baseURL + '/processStripeAccountCode';
      axios.post(url, obj)
        .then(async (res) => {
          // var userProfile = await firestore.collection('users').doc(obj.uid).get();
          dispatch({ type: 'UPDATED_PROFILE_SUCCESSFULLY' });
        })
        .catch((err) => {
          // console.log('Error occurred - ', err)
          dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Error occurred' });
        });
    }
  }
};

export const createSetupIntent = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside createSetupIntent - ', obj);
    const inputData = {
      email: obj.email
    }
    const firestore = getFirestore();
    const url = baseURL + '/createSetupIntent';
    axios.post(url, inputData)
      .then(async (res) => {
        var userProfile = await firestore.collection('users').doc(obj.uid).get();
        dispatch({ type: 'SETUP_INTENT_CREATED', userProfile: userProfile.data() });
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Error occurred' });
      });
  }
};

export const updateUserStripePaymentMethod = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside updateUserStripePaymentMethod - ', obj);
    const firestore = getFirestore();
    await firestore.collection('users').doc(obj.uid).update({
      paymentMethod: firestore.FieldValue.arrayUnion(obj.paymentMethod),
      stripeSetupIntentClientSecret: null
    })
      .then(async (data) => {
        // console.log('updated');
        var userProfile = await firestore.collection('users').doc(obj.uid).get();
        dispatch({
          type: 'UPDATED_PAYMENT_METHOD',
          paymentUpdated: obj.paymentMethod,
          userProfile: userProfile.data()
        });
      }).catch(err => {
        // console.log('error', err)
        dispatch({ type: 'ERROR_UPDATING_PROFILE', errorMsg: 'Error occurred' });
      });
  }
};

export const getPaymentMethods = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside getPaymentMethods - ', obj);
    const inputData = {
      uid: obj.uid
    }
    const url = baseURL + '/getUserPaymentMethods';
    axios.post(url, inputData)
      .then(async (res) => {
        // console.log('res -', res);
        dispatch({ type: 'PAYMENT_METHODS_RETRIEVED', rows: res.data });
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'PAYMENT_METHODS_RETRIEVED', rows: [] });
      });
  }
};


export const clearPaymentMethodError = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'CLEAR_PAYMENT_ERROR' });
  }
};


export const deauthorizeStripeConnectedAccount = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside deauthorizeStripeConnectedAccount - ', uid);
    const inputData = {
      uid: uid
    }
    const url = baseURL + '/deauthorizeStripeConnectedAccount';
    axios.post(url, inputData)
      .then(async (res) => {
        // console.log('res -', res);
        dispatch({ type: 'DEAUTHORIZED_STRIPE_ACCOUNT_SUCCESS', errorMsg: null });
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'DEAUTHORIZED_STRIPE_ACCOUNT_SUCCESS_FAILURE', errorMsg: 'Something went wrong. Please try again later.' });
      });
  }
};

// export const getStripeDashboardLink = (obj) => {
//   return async (dispatch, getState, { getFirestore }) => {
//     // console.log('inside getStripeDashboardLink - ', obj);
//     const inputData = {
//       uid: obj.uid
//     }
//     const url = baseURL + '/getStripeDashboardLink';
//     axios.post(url, inputData)
//       .then(async (res) => {
//         // console.log('res -', res);
//         dispatch({ type: 'STRIPE_DASHBOARD_LINK', url: res.data.url, errorMsg: null });
//       })
//       .catch((err) => {
//         // console.log('Error occurred - ', err)
//         dispatch({ type: 'STRIPE_DASHBOARD_LINK', url: null, errorMsg: 'Something went wrong' });
//       });
//   }
// };

export const deletePaymentMethod = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside deletePaymentMethod - ', obj);
    const inputData = {
      uid: obj.uid,
      id: obj.id
    }
    const url = baseURL + '/detachPayment';
    return axios.post(url, inputData)
      .then(async (res) => {
        // console.log('res -', res);
        dispatch({ type: 'PAYMENT_METHODS_RETRIEVED', rows: res.data });
        return true;
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'ERROR', rows: [], errorMsg: 'Something went wrong' });
        return false;
      });
  }
};

export const updateDefaultPaymentMethod = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside updateDefaultPaymentMethod - ', obj);
    const inputData = {
      uid: obj.uid,
      id: obj.id
    }
    const url = baseURL + '/updateDefaultPaymentMethod';
    return axios.post(url, inputData)
      .then(async (res) => {
        // console.log('res -', res);
        dispatch({ type: 'PAYMENT_METHODS_RETRIEVED', rows: res.data });
        return true;
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'ERROR', rows: [], errorMsg: 'Something went wrong' });
        return false;
      });
  }
};


export const getStripeCustomerPortalLink = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside getStripeCustomerPortalLink - ', obj);
    const inputData = {
      uid: obj.uid,
    }
    const url = baseURL + '/stripeCustomerPortalLink';
    await axios.post(url, inputData)
      .then(async (res) => {
        dispatch({ type: 'STRIPE_CUSTOMER_PORTAL_RETRIEVED', stripeCustomerPortalUrl: res.data.url });
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        // dispatch({ type: 'ERROR', rows: [], errorMsg: 'Something went wrong' });
      });
  }
};


export const clearContactErrorAction = () => {
  // console.log('clearContactErrorAction')
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: 'CLEAR_CONTACT_US_ERROR' });
  }
};

export const validateCard = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside validateCard - ', obj);
    if (isValid(obj.name) && isValid(obj.email) && isValid(obj.addressLine1) && isValid(obj.addressLine2) && isValid(obj.city) && isValid(obj.state) && isValid(obj.country)) {
      return true;
    } else {
      return false;
    }
  }
}

export const sendContactUsEmail = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside sendContactUsEmail - ', obj);
    if (isValid(obj.firstName) && isValid(obj.lastName) && isValid(obj.email) && isValid(obj.phone) && isValid(obj.message)) {
      const inputData = {
        firstName: obj.firstName,
        lastName: obj.lastName,
        email: obj.email,
        phone: obj.phone,
        message: obj.message,
      }
      const url = baseURL + '/sendContactUsEmail';
      await axios.post(url, inputData)
        .then(async (res) => {
          dispatch({ type: 'CONTACT_US_SUCCESSFUL' });
        })
        .catch((err) => {
          // console.log('Error occurred - ', err)
          dispatch({ type: 'CONTACT_US_ERROR', contactError: 'Something went wrong. Please try again in some time.' });
        });
    } else {
      dispatch({ type: 'CONTACT_US_ERROR', contactError: 'Please enter all fields marked with *' });
    }
  }
};

export const resetPassword = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    if (isValid(data.token) && isValid(data.password)) {
      // firebase function to reset the password
      const inputData = {
        token: data.token,
        password: data.password,
      }
      const url = baseURL + '/resetPassword';
      await axios.post(url, inputData)
        .then(async (res) => {
          dispatch({ type: 'PASSWORD_UPDATE_SUCCESSFUL' });
        })
        .catch((err) => {
          // console.log('Error occurred - ', err)
          dispatch({ type: 'RESET_PASSWORD_ERROR', contactError: 'Invalid link. Please try again.' });
        });
    } else {
      dispatch({ type: 'PASSWORD_UPDATE_FAILED', err: "Please enter a password" });
    }
  }
}

export const getOperatorProfile = (id) => {
  return async (dispatch, getState, { getFirebase }) => {
    if (isValid(id)) {
      // firebase function to get the operator profile

      const url = baseURL + '/getOperatorProfile?uid=' + id;
      await axios.get(url)
        .then(async (res) => {
          // console.log('res=', res);
          dispatch({ type: 'PROFILE_RETRIEVE_SUCCESSFUL', userProfile: res.data.data });
        })
        .catch((err) => {
          // console.log('Error occurred - ', err)
          dispatch({ type: 'FAILED_TO_RETRIEVE_PROFILE', err: 'User profile not found.' });
        });
    } else {
      dispatch({ type: 'FAILED_TO_RETRIEVE_PROFILE', err: "User profile not found." });
    }
  }
}

export const getPendingOperators = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const url = baseURL + '/getPendingOperators';
    await axios.get(url)
      .then(async (res) => {
        // console.log('data=', res.data.operatorPending)
        dispatch({ type: 'PROFILES_RETRIEVE_SUCCESSFUL', pendingOperators: res.data.operatorPending });
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'FAILED_TO_RETRIEVE_PROFILES', err: 'No pending operators' });
      });
  }
}

export const confirmApproveOperator = (id) => {
  return async (dispatch, getState, { getFirebase }) => {

    const inputData = {
      uid: id
    }
    const url = baseURL + '/approveOperator';
    return axios.post(url, inputData)
      .then(async (res) => {
        // console.log('data=', res.data.status)
        if (res.data.status) {
          dispatch({ type: 'OPERATOR_APPROVED_SUCCESSFUL', userProfile: res.data.profile });
        } else {
          dispatch({ type: 'FAILED_TO_APPROVE_OPERATOR', err1: 'Failed to approve operator' });
        }
      })
      .catch((err) => {
        // console.log('Error occurred - ', err)
        dispatch({ type: 'FAILED_TO_APPROVE_OPERATOR', err1: 'Error occurred' });
      });
  }
}

export const holdApprovalOfOperator = (obj) => {
  return async (dispatch, getState, { getFirebase }) => {
    if (isValid(obj.reasonForDeny)) {
      const inputData = {
        uid: obj.uid,
        reasonForDeny: obj.reasonForDeny
      }
      const url = baseURL + '/holdApprovalOfOperator';
      return axios.post(url, inputData)
        .then(async (res) => {
          // console.log('data=', res.data.status)
          if (res.data.status) {
            dispatch({ type: 'OPERATOR_APPROVAL_ON_HOLD', userProfile: res.data.profile });
          } else {
            dispatch({ type: 'FAILED_TO_ HOLD_APPROVAL_OF_OPERATOR', err3: 'Failed to hold approval of operator' });
          }
        })
        .catch((err) => {
          // console.log('Error occurred - ', err)
          dispatch({ type: 'Failed to hold approval of operator', err3: 'Error occurred' });
        });

    } else {
      dispatch({ type: 'FAILED_TO_ HOLD_APPROVAL_OF_OPERATOR', err3: 'Enter a valid reason for deny.' });

    }
  }
}

export const searchProfileList = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    if (!data.searchText) {
      dispatch({ type: 'FAILED_TO_RETRIEVE_SEARCH_RESULT', err1: 'Please enter search text' });
    } else {
      const url = baseURL + '/getSearchList';
      return axios.post(url, data)
        .then(async (res) => {
          if (res.data.searchResultList && res.data.searchResultList.length > 0) {
            dispatch({ type: 'SEARCH_RETRIEVE_SUCCESSFUL', searchResultList: res.data.searchResultList });
          } else {
            dispatch({ type: 'FAILED_TO_RETRIEVE_SEARCH_RESULT', err1: 'No search results found' });
          }
        })
        .catch((err) => {
          // console.log('Error occurred - ', err)
          dispatch({ type: 'FAILED_TO_RETRIEVE_SEARCH_RESULT', err1: 'Failed to retrieve the search results' });
        });
    }
  }
}

export const mailingList = (newUser) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    if (isValid(newUser.email) && isValid(newUser.city)
      && isValid(newUser.firstName) && isValid(newUser.lastName)
      && isValid(newUser.state)) {
      const firestore = getFirestore();
      // check if email is already added to marketing list
      await firestore.collection('mailingList').where('email', '==', newUser.email).get()
        .then(async snapshot => {
          if (snapshot.empty) {
            // user's email is not added yet, so add them to the mailing list
            await firestore.collection('mailingList').add({
              email: newUser.email,
              firstName: newUser.firstName.toUpperCase(),
              lastName: newUser.lastName.toUpperCase(),
              companyName: newUser.companyName,
              phoneNumber: newUser.phoneNumber,
              officePhoneNumber: newUser.officePhoneNumber,
              city: newUser.city,
              state: newUser.state,
              numberOfFleet: newUser.numberOfFleet,
              createdAt: new Date(),
            });
            // send user a welcome email
            const inputData = {
              toEmail: newUser.email,
              firstName: newUser.firstName.toUpperCase(),
            }
            const url = baseURL + '/sendWelcomeEmailToMailListSignUp'
            await axios.post(url, inputData);
            // send success response
            dispatch({ type: 'MAILLIST_SIGNUP_SUCCESS' });
          } else {
            // user is already added to mailing list
            dispatch({ type: 'MAILLIST_EMAIL_EXISTS' });
          }
        })
        .catch(err => {
          // console.log('Error occurred ', err);
          dispatch({ type: 'MAILLIST_SIGNUP_ERROR', err: 'An error occurred. Please try again later.' });
        });
    } else {
      // console.log('missing values')
      dispatch({ type: 'MAILLIST_SIGNUP_ERROR', err: 'Please enter all required fields.' });
    }
  }
}

export const getCarMakeListAction = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    await firestore.collection('cars').get()
      .then(async (snapshot) => {
        let carMakeList = [];
        snapshot.forEach((doc) => {
          carMakeList.push(doc.id)
        });
        dispatch({ type: 'RETRIEVED_CAR_MAKE_LIST', carMakeList });
      }).catch(err => {
        // console.log('error caught - ', err)
        return;
      });
  }
}