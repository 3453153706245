import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPreferredOperators } from '../../store/actions/userActions'
import { Redirect } from 'react-router-dom'
import Loader1 from '../loader/Loader1'
import Breadcrumb from "../common/Breadcrumb";
import ReactStars from "react-rating-stars-component";
import { StorageBaseURL, altMedia } from "../constants/CommonConstants"

class PreferredOperators extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    if (this.props.auth) {
      this.props.getPreferredOperators({
        uid: this.props.auth.uid
      })
        .then(() => {
          this.setState({
            loading: false
          });
        })
    }
  }

  render() {
    const { auth, preferredOperatorsData } = this.props;
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }

    if (!auth.uid) return <Redirect to='/login' />

    return (
      <>
        {loader}
        <Breadcrumb title='Preferred Operators' />
        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
          <div className="container">
            <div className="row service-one-wrapper">

              {
                preferredOperatorsData && preferredOperatorsData.data && preferredOperatorsData.data.length > 0
                ? preferredOperatorsData.data.map((value, i) => {
                  return (
                    <div className="row ptb--40" key={value.id}>
                      <div className="col-md-4">
                        <div style={{ width: '200px' }}>
                          <img src={value.profileImageUrl
                            ? StorageBaseURL + encodeURIComponent(value.id + '/profile/thumb_' + value.profileImageUrl) + altMedia
                            : 'http://via.placeholder.com/200x200'} alt="profile" />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div><span style={{ fontWeight: 'bold' }}>{value.firstName.toUpperCase()} {value.lastName.toUpperCase()}</span></div>
                        <div>Vehicle Details: {value.carColor.toUpperCase()} {value.carMake.toUpperCase()} {value.carModel.toUpperCase()}</div>
                        <div>Vehicle Plate: {value.carPlate.toUpperCase()}</div>
                        <div>{value.acceptedRides} rides accepted</div>
                        <div>{value.confirmedRides} rides completed</div>
                        <div>{value.cancelledConfirmedRides} rides cancelled</div>
                        <div>Cancellation Policy: {value.cancellationPolicy} hours prior to scheduled ride pick up time</div>
                        {
                          value.numberOfRatings !== "N/A" ?
                            (
                              <div>
                                <ReactStars
                                  id="selectedOperatorRating"
                                  a11y={false}
                                  count={5}
                                  value={+value.avgRating}
                                  size={24}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#D8B46C"
                                />
                                {value.numberOfRatings
                                  ?
                                  <div>
                                    {value.avgRating} out of 5 ({value.numberOfRatings} ratings)
                                  </div>
                                  : 'Not available'}
                              </div>
                            )
                            : null
                        }
                      </div>
                    </div>
                  )
                })
                : <p className="red-text">You have not selected any preferred operator(s)</p>
              }
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    preferredOperatorsData: state.user.preferredOperatorsData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPreferredOperators: (data) => dispatch(getPreferredOperators(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferredOperators)
