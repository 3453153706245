import React, { Component } from 'react'
import Breadcrumb from "../common/Breadcrumb";

class VehicleTypes extends Component {

    render() {
        return (
            <>
                <Breadcrumb title={'Vehicle Types'} title1={'Vehicle Types'} parent='Admin' parentLink='/admin' />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default VehicleTypes;
