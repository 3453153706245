import React, { Component } from 'react'
import Breadcrumb from "../common/Breadcrumb";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class FAQs extends Component {

    render() {
        return (
            <>
                <Breadcrumb title={'FAQs'} />

                <div className="pv-feaq-area bg_color--3 ptb--40">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--50">
                                    <h2 className="title">FAQs</h2>
                                    <p className="description">We have the answers to your most frequently asked questions. </p>
                                </div>
                                <div className="faq-area">
                                    <Accordion className="accodion-style--1" preExpanded={'0'}>
                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I book a vehicle with FINDANIO?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Create an account for free to book a ride by entering your ride details and payment method and choose from
                                                    your available operators.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What information do I need to provide to book a reservation?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>The information that is required to make the reservation is:
                                                    <br />- Passenger name and Phone Number
                                                    <br />- Date and Time
                                                    <br />- Pick up and Drop Off locations
                                                    <br />- Preferred vehicle type
                                                    <br />- Any special notes or instructions like flight details, where to meet the chauffeur etc.
                                                    <br />- Payment method
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How much notice is required to make a reservation?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>We would recommend to make a reservation at least 24 hours prior to the pick up time.
                                                    We understand that plans change and that travelers need to make a reservation on a very short notice,
                                                    operators will do their best to accommodate your request.
                                                    Most major cities will have a large supply of vehicles so even under 4 hours
                                                    or as soon as possible have been fulfilled for many travelers.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How much notice is needed to cancel a reservation?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The cancellation notice is based on the operator of your choice, vehicle and city of travel.
                                                    FINDANIO does not set the vehicle cancellation policy.

                                                    A $5.00 cancellation fee will apply if a traveler submits a request and cancels after 1 or more operator(s) have replied available.
                                                    Otherwise it is based on the cancellation policy set by the operator of choice.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What payment methods do you accept?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>We accept major credit cards such as Master Card, Visa, American Express.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    When will you charge my credit card?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>We preauthorize the credit card for the entire amount once the confirmation has been set by both parties (traveler and operator)
                                                    and in some cases 20% additional for unexpected charges.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How and Where do I meet my operator?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    You can communicate directly with the selected operator and set up your preferred meeting point
                                                    using the chat feature on the FINDANIO ride details.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Is my personal information safe and private?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>Yes, the information provided to us is safe and secure.
                                                    Read more about our <a href="/tnc" className="anchor">Terms and Conditions of Service</a> and <a href="/privacy" className="anchor">Privacy Policy</a>.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How do I communicate with the operator?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    FINDANIO recommends all travel communication happens through the message feature on the ride details.
                                                    This helps with providing documentation for resolving any service or billing issues.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default FAQs
