import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

class ProtectedRoute extends Component {

  render() {
    const { auth } = this.props;
    if (auth.uid) {
      return (
        <Route exact path={this.props.path} component={this.props.component} {...this.props}/>
      )
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { redirectAfterLoginTo: this.props.location.pathname }
          }}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(ProtectedRoute)

