import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getNewUpcomingRides } from '../../../store/actions/projectActions'
import Loader1 from '../../loader/Loader1'
import moment from 'moment';
import RideTableData from '../RideTableData3'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createData } from '../../util/rideUtils';

class NewRidesData extends Component {

  state = {
    projectsFound: false
  }

  componentDidMount() {
    const { profile } = this.props;
    if (!this.state.projectsFound && !profile.isEmpty) {
      this.props.getNewUpcomingRides({
        operatorUsState: profile.state,
        carType: profile.carType
      });
      this.setState({
        projectsFound: true
      })
    }
  }

  componentDidUpdate() {
    const { projects, profile } = this.props;
    if (!this.state.projectsFound && !profile.isEmpty && !projects) {
      this.props.getNewUpcomingRides(profile.state);
      this.setState({
        projectsFound: true
      })
    }
  }

  render() {
    const { projects, auth, profile, errorMsg } = this.props;
    if (!auth.uid) return <Redirect to='/login' />

    if (!profile.isEmpty && profile.accountType !== 'Operator') {
      return <Redirect to='/404' />
    }

    if (this.state.projectsFound && !errorMsg) {
      if (projects && projects.length > 0) {
        let finalData = [];
        const nonOperatorProjects = projects.filter(eachProject => {
          return (eachProject.authorId !== auth.uid
            && !(eachProject.deniedBy && eachProject.deniedBy.includes(auth.uid))
            && !(eachProject.deniedAfterAcceptingBy && eachProject.deniedAfterAcceptingBy.includes(auth.uid))
          )
        })
        if (nonOperatorProjects.length > 0) {
          nonOperatorProjects && nonOperatorProjects.forEach(project => {
            let viewDetailsBtn = (
              <Link to={'/ride/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
            );
            let actionLink = '/ride/' + project.id;
            if (project.acceptedBy) {
              if (!(project.acceptedBy.includes(auth.uid))) {
                finalData.push(
                  createData(
                    project.id,
                    moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY"),
                    moment(project.serviceDate.toDate()).format("LT"),
                    project.serviceFromShort || project.serviceFrom,
                    project.serviceToShort || project.serviceTo,
                    project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
                    project.status ? project.status.toUpperCase() : null,
                    viewDetailsBtn,
                    project.serviceVehicle,
                    project.serviceType,
                    project.calculatedDistance.toFixed(2) + ' miles',
                    project.calculatedRideDuration,
                    project.serviceDate.toDate(),
                    actionLink,
                    project.rideConfirmationCode
                  )
                )
              }
            } else {
              finalData.push(
                createData(
                  project.id,
                  moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY"),
                  moment(project.serviceDate.toDate()).format("LT"),
                  project.serviceFromShort || project.serviceFrom,
                  project.serviceToShort || project.serviceTo,
                  project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
                  project.status ? project.status.toUpperCase() : null,
                  viewDetailsBtn,
                  project.serviceVehicle,
                  project.serviceType,
                  project.calculatedDistance.toFixed(2) + ' miles',
                  project.calculatedRideDuration,
                  project.serviceDate.toDate(),
                  actionLink,
                  project.rideConfirmationCode
                )
              )
            }
          })

          if (finalData.length > 0) {
            return (
              <RideTableData sortAsc={true} rows={finalData} />
            )
          } else {
            return (
              <Card variant="outlined">
                <CardContent>
                  <p className="center error-text">No rides found</p>
                </CardContent>
              </Card>
            )
          }
        } else {
          return (
            <Card variant="outlined">
              <CardContent>
                <p className="center error-text">No rides found</p>
              </CardContent>
            </Card>
          )
        }
      } else {
        return (
          <Card variant="outlined">
            <CardContent>
              <p className="center error-text">No rides found</p>
            </CardContent>
          </Card>
        )
      }
    } else if (errorMsg) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">{errorMsg}</p>
          </CardContent>
        </Card>
      )
    } else {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">Loading</p>
            <Loader1 />
          </CardContent>
        </Card>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.project.upcomingProjects,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    errorMsg: state.project.errorMsg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNewUpcomingRides: (obj) => dispatch(getNewUpcomingRides(obj))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRidesData)
