import axios from 'axios'

import { baseURL } from "../../components/constants/CommonConstants";

const initState = {
  rideCreationErr: null,
  rideCreationStatus: false,
  errorMsg: null
}

const projectReducer = (state = initState, action) => {
  switch (action.type) {

    case 'RETRIEVED_OPERATOR_LOCATIONS':
      return {
        ...state,
        locations: action.locations,
      }

    case 'TRAVELER_REQUESTED_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        travelerRequestedProjects: action.travelerRequestedProjects,
        errorMsg: null
      }

    case 'TRAVELER_PENDING_APPROVAL_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        travelerPendingApprovalRides: action.travelerPendingApprovalRides,
        errorMsg: null
      }

    case 'UPCOMING_REQUESTED_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        travelerUpcomingProjects: action.travelerUpcomingProjects,
        errorMsg: null
      }

    case 'ACCEPTED_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        acceptedRides: action.acceptedRides,
        errorMsg: null
      }

    case 'PENDING_ACCEPTED_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        pendingAcceptedRides: action.pendingAcceptedRides,
        errorMsg: null
      }


    case 'OPERATOR_UPCOMING_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        operatorUpcomingRides: action.operatorUpcomingRides,
        errorMsg: null
      }

    case 'OPERATOR_PAST_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        operatorPastRides: action.operatorPastRides,
        errorMsg: null
      }

    case 'ERROR_FETCHING_RIDES':
      return {
        ...state,
        errorMsg: action.errorMsg
      }

    case 'UPCOMING_RIDES_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        upcomingProjects: action.upcomingProjects,
        errorMsg: null
      }

    case 'ERROR_FETCHING_UPCOMING_RIDES':
      return {
        ...state,
        errorMsg: action.errorMsg
      }

    case 'CREATE_PROJECT_SUCCESS':
      return {
        ...state,
        rideCreationErr: null,
        rideCreationStatus: true,
        createdRideId: action.id
      }
    case 'CREATE_PROJECT_ERROR':
      return {
        ...state,
        rideCreationErr: action.message,
        rideCreationStatus: false
      }

    case 'SUCCESSFULLY_ACCEPTED_RIDE':
      if (action.sendTravelerRideAcceptedMsg === true) {
        sendTravelerRideAcceptedMessage(action.projectId, action.requestorPhone);
      }
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'SUCCESSFULLY_UPDATED_RIDE_STATUS':
      sendTravelerRideUpdatedMessage(action.projectId, action.requestorPhone, action.msg);
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'UPDATED_EXTRA_STOP_BY_REQUESTOR_SUCCESSFUL':
      sendOperatorRideUpdatedMessage(action.projectId, action.operatorPhone, action.msg);
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'SUCCESSFULLY_DENIED_RIDE':
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'OPERATOR_DECLINED_CONFIRMATION':
      sendTravelerRideCannotBeConfirmedMsg(action.projectId, action.requestorPhone, action.operatorName);
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'OPERATOR_CANCELLED_RIDE':
      sendTravelerRideCancelledByOperatorMsg(action.projectId, action.requestorPhone, action.operatorName);
      sendOperatorRideCancelledByOperatorMsg(action.projectId, action.operatorPhone);
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'SUCCESSFULLY_CONFIRMED_RIDE':
      sendTravelerRideConfirmedMessage(action.projectId, action.requestorPhone, action.operatorName);
      sendOperatorRideConfirmedMessage(action.projectId, action.operatorPhone);
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'ERROR_DENYING_RIDE':
      return state;

    case 'ERROR_ACCEPTING_RIDE':
      return state;

    case 'ERROR_CANCELLING_RIDE':
      return state;

    case 'ERROR_UPDATING_RIDE':
      return state;

    case 'SUCCESSFULLY_CANCELLED_RIDE':
      sendTravelerCancellationMsg(action.projectId, action.requestorPhone);
      if (action.operatorPhone !== null) {
        sendOperatorCancellationMsg(action.projectId, action.operatorPhone);
      }
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'CLEAR_RIDE_DETAILS':
      return {
        ...state,
        rideDetails: null
      }

    case 'OPERATOR_DECLINED_CONFIRMATION_ERR':
      return state;
    case 'OPERATOR_CANCELLED_RIDE_ERR':
      return state;
    case 'ERROR_CONFIRMING_RIDE':
      return state;

    case 'SEND_SELECTED_OPERATOR_MSG':
      sendSelectedOperatorMessage(action.id, action.phone, 'selected');
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'CANCEL_SELECTED_OPERATOR_MSG':
      sendSelectedOperatorMessage(action.id, action.phone, 'unselected');
      return {
        ...state,
        rideDetails: action.updatedProject
      }

    case 'CLEAR_PROJECT_STATUS':
      return {
        ...state,
        rideCreationErr: null,
        rideCreationStatus: false,
        createdRideId: null
      }

    case 'RETRIEVED_RIDE_DETAILS':
      return {
        ...state,
        rideDetails: action.rideDetails,
      }

    default:
      return state;
  }
};

function sendSelectedOperatorMessage(id, phone, status) {
  let url = baseURL + '/sendMessageToSelectedOperator';
  let data = {
    id: id,
    phone: phone,
    status: status
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}


function sendTravelerRideUpdatedMessage(projectId, requestorPhone, msg) {
  let url = baseURL + '/sendTravelerRideUpdatedMessage';
  let data = {
    id: projectId,
    phone: requestorPhone,
    msg: msg
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendOperatorRideUpdatedMessage(projectId, operatorPhone, msg) {
  let url = baseURL + '/sendOperatorRideUpdatedMessage';
  let data = {
    id: projectId,
    phone: operatorPhone,
    msg: msg,
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendTravelerRideAcceptedMessage(projectId, requestorPhone) {
  let url = baseURL + '/sendRideAcceptedMsgToTraveler';
  let data = {
    id: projectId,
    phone: requestorPhone,
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendTravelerRideConfirmedMessage(projectId, requestorPhone, operatorName) {
  let url = baseURL + '/sendRideConfirmedMsgToTraveler';
  let data = {
    id: projectId,
    phone: requestorPhone,
    operatorName: operatorName
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendOperatorRideConfirmedMessage(projectId, operatorPhone) {
  let url = baseURL + '/sendRideConfirmedMsgToOperator';
  let data = {
    id: projectId,
    phone: operatorPhone
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendTravelerRideCannotBeConfirmedMsg(projectId, requestorPhone, operatorName) {
  let url = baseURL + '/sendRideNotConfirmedMsgToTraveler';
  let data = {
    id: projectId,
    phone: requestorPhone,
    operatorName: operatorName
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}


function sendTravelerRideCancelledByOperatorMsg(projectId, requestorPhone, operatorName) {
  let url = baseURL + '/sendRideCancelledByOperatorMsgToTraveler';
  let data = {
    id: projectId,
    phone: requestorPhone,
    operatorName: operatorName
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendOperatorRideCancelledByOperatorMsg(projectId, operatorPhone) {
  let url = baseURL + '/sendRideCancelledByOperatorMsgToOperator';
  let data = {
    id: projectId,
    phone: operatorPhone
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendOperatorCancellationMsg(projectId, operatorPhone) {
  let url = baseURL + '/sendRideCancelledByTravelerMsgToOperator';
  let data = {
    id: projectId,
    phone: operatorPhone
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

function sendTravelerCancellationMsg(projectId, requestorPhone) {
  let url = baseURL + '/sendRideCancelledByTravelerMsgToTraveler';
  let data = {
    id: projectId,
    phone: requestorPhone
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent message successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending message - ', err);
      return;
    })
  return;
}

export default projectReducer;