import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Breadcrumb from "../common/Breadcrumb";
import { FiSettings } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { NavLink } from 'react-router-dom';
import { MdPayment } from "react-icons/md";

const ServiceListOne = [
    {
        icon: <CgProfile />,
        title: 'Profile',
        description: 'Manage your profile',
        link: '/profile'
    },
    {
        icon: <FiSettings />,
        title: 'Security',
        description: 'Update your account password',
        link: '/security'
    },
    {
        icon: <CgProfile />,
        title: 'Preferred Operators',
        description: 'Manage your preferred operators',
        link: '/preferredOperators'
    },
    {
        icon: <MdPayment />,
        title: 'Payment Methods',
        description: 'Manage your payment methods',
        link: '/payment'
    }

]

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState() {
        return {
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        return (
            <>
                <Breadcrumb title='Account' />

                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map((val, i) => (
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                                    <NavLink className="text-center" onClick={this.scrollToTop} to={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)

