const initState = {
  data: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {

    case 'RETRIEVED_PREFERRED_OPERATORS_SUCCESSFULLY':
      return {
        ...state,
        preferredOperatorsData: action.preferredOperatorsData
      }

    default:
      return state
  }
};

export default authReducer;