import React from 'react'
import Delete from '@material-ui/icons/DeleteRounded';

class OperatorSignUpStep3 extends React.Component {
  render() {
    if (this.props.currentStep !== 3) { // Prop: The current step
      return null
    }
    return (
      <div className="form-group mt--20">
        <div className="row">
          <div className="col-12" style={{ fontSize: '80%' }}>
            <span className="star">*</span> Required
          </div>
        </div>

        <div className="row mt--20 mb--40">
          <div className="col-md-6 col-sm-12 ">
            <div style={{ marginBottom: '5px' }}>
              <label style={{ fontSize: '16px' }}>Profile Image<span className="star">*</span></label>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 mb--10">
                <input
                  id="profileImageFile"
                  type="file"
                  onChange={this.props.fileChangedHandler}
                />
                <label className="error-text" id="profileImageFileErr"></label>
                <img width="200" height="200" src={this.props.profileImageFileURL || 'http://via.placeholder.com/200x200'} alt='profile' />
                <div
                  onClick={() => this.props.removeFileHandler('profileImageFile')}
                  style={{ visibility: this.props.profileImageFileURL ? 'visible' : 'hidden' }}>
                  <label>
                    <span>Remove File</span>
                    <Delete color="secondary" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 ">
            <div style={{ marginBottom: 5 + 'px' }}>
              <label style={{ fontSize: 16 + 'px' }}>Vehicle (with Plate) Image<span className="star">*</span></label>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <input
                  id="vehicleImageFile"
                  type="file"
                  onChange={this.props.fileChangedHandler}
                />
                <label className="error-text" id="vehicleImageFileErr"></label>
                <img width="200" height="200" src={this.props.vehicleImageFileURL || 'http://via.placeholder.com/200x200'} alt='vehicle' />
                <div
                  onClick={() => this.props.removeFileHandler('vehicleImageFile')}
                  style={{ visibility: this.props.vehicleImageFileURL ? 'visible' : 'hidden' }}>
                  <label>
                    <span>Remove File</span>
                    <Delete color="secondary" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb--40">
          <div className="col-md-6 col-sm-12 ">
            <div style={{ marginBottom: 5 + 'px' }}>
              <label style={{ fontSize: 16 + 'px' }}>Driver's License<span className="star">*</span></label>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <input
                  id="licenseFile"
                  type="file"
                  onChange={this.props.fileChangedHandler}
                />
                <label className="error-text" id="licenseFileErr"></label>
                <img width="200" height="200" src={this.props.licenseFileURL || 'http://via.placeholder.com/200x200'} alt='license' />
                <div
                  onClick={() => this.props.removeFileHandler('licenseFile')}
                  style={{ visibility: this.props.licenseFileURL ? 'visible' : 'hidden' }}>
                  <label>
                    <span>Remove File</span>
                    <Delete color="secondary" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 ">
            <div style={{ marginBottom: 5 + 'px' }}>
              <label style={{ fontSize: 16 + 'px' }}>Proof of Insurance<span className="star">*</span></label>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <input
                  id="insuranceFile"
                  type="file"
                  onChange={this.props.fileChangedHandler}
                />
                <label className="error-text" id="insuranceFileErr"></label>
                <img width="200" height="200" src={this.props.insuranceFileURL || 'http://via.placeholder.com/200x200'} alt='insurance' />
                <div
                  onClick={() => this.props.removeFileHandler('insuranceFile')}
                  style={{ visibility: this.props.insuranceFileURL ? 'visible' : 'hidden' }}>
                  <label>
                    <span>Remove File</span>
                    <Delete color="secondary" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <div style={{ marginBottom: 5 + 'px' }}>
              <label style={{ fontSize: 16 + 'px' }}>Vehicle Registration<span className="star">*</span></label>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <input
                  id="vehicleRegistrationImageFile"
                  type="file"
                  onChange={this.props.fileChangedHandler}
                />
                <label className="error-text" id="vehicleRegistrationImageFileErr"></label>
                <img width="200" height="200" src={this.props.vehicleRegistrationImageURL || 'http://via.placeholder.com/200x200'} alt='registration' />
                <div
                  onClick={() => this.props.removeFileHandler('vehicleRegistrationImageFile')}
                  style={{ visibility: this.props.vehicleRegistrationImageURL ? 'visible' : 'hidden' }}>
                  <label>
                    <span>Remove File</span>
                    <Delete color="secondary" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OperatorSignUpStep3




