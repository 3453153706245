import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getTravelerUpcomingRides } from '../../../store/actions/projectActions'
import Loader1 from '../../loader/Loader1'
import moment from 'moment';
import RideTableData from '../RideTableData3'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { createData } from '../../util/rideUtils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class TravelerUpcomingRides extends Component {

  state = {
    projectsFound: false
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!this.state.projectsFound && auth.uid) {
      this.props.getTravelerUpcomingRides(auth.uid);
      this.setState({
        projectsFound: true
      })
    }
  }

  componentDidUpdate() {
    const { projects, auth } = this.props;
    if (!this.state.projectsFound && auth.uid && !projects) {
      this.props.getTravelerUpcomingRides(auth.uid);
      this.setState({
        projectsFound: true
      })
    }
  }

  render() {
    const { projects, auth, errorMsg } = this.props;
    if (!auth.uid) return <Redirect to='/login' />

    // if (!profile.isEmpty && profile.accountType !== 'Traveler') {
    //   return <Redirect to='/404' />
    // }

    if (this.state.projectsFound && projects && !errorMsg) {
      if (projects.length > 0) {
        let todayRides = [];
        let upcomingRides = [];

        projects.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/requested/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          );
          let actionLink = '/ride/requested/' + project.id;
          if (moment(new Date()).format("dddd, MMMM Do, YYYY") === moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY")) {
            todayRides.push(
              createData(
                project.id,
                moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY"),
                moment(project.serviceDate.toDate()).format("LT"),
                project.serviceFrom,
                project.serviceTo,
                project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
                project.status ? project.status.toUpperCase() : null,
                viewDetailsBtn,
                project.serviceVehicle,
                project.serviceType,
                project.calculatedDistance.toFixed(2) + ' miles',
                project.calculatedRideDuration,
                project.serviceDate.toDate(),
                actionLink,
                project.rideConfirmationCode
              )
            )
          } else {
            upcomingRides.push(
              createData(
                project.id,
                moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY"),
                moment(project.serviceDate.toDate()).format("LT"),
                project.serviceFrom,
                project.serviceTo,
                project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
                project.status ? project.status.toUpperCase() : null,
                viewDetailsBtn,
                project.serviceVehicle,
                project.serviceType,
                project.calculatedDistance.toFixed(2) + ' miles',
                project.calculatedRideDuration,
                project.serviceDate.toDate(),
                actionLink,
                project.rideConfirmationCode
              )
            )
          }
        })
        return (
          <React.Fragment>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <h3>Today's Rides</h3>
                  <p className="description">Your ride schedule for {moment(new Date()).format("dddd, MMMM Do, YYYY")}</p>
                  {(todayRides.length > 0 && <RideTableData sortAsc={true} rows={todayRides} />) || <p className="center error-text">No rides scheduled for today</p>}
                </div>
              </div>
              <div className="row mt--30">
                <div className="col-md-12 col-sm-12">
                  <h3>Upcoming Rides</h3>
                  {(upcomingRides.length > 0 && <RideTableData sortAsc={true} rows={upcomingRides} />) || <p className="center error-text">No upcoming confirmed rides</p>}
                </div>
              </div>
            </div>
          </React.Fragment>
        )

      } else {
        return (
          <Card variant="outlined">
            <CardContent>
              <p className="center error-text">No rides found</p>
            </CardContent>
          </Card>
        )
      }
    } else if (errorMsg) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">{errorMsg}</p>
          </CardContent>
        </Card>
      )
    } else {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">Loading</p>
            <Loader1 />
          </CardContent>
        </Card>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.project.travelerUpcomingProjects,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    errorMsg: state.project.errorMsg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTravelerUpcomingRides: (uid) => dispatch(getTravelerUpcomingRides(uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelerUpcomingRides)
