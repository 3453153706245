import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
// import { IoCarOutline } from 'react-icons/io5';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import { IconButton, Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ViewPaymentMethodsDataCard(props) {
    const rows = props.rows
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Card className={`${classes.root}`} variant="outlined" key={row.id}>
                    <CardHeader
                        avatar={
                            <Avatar className="red-background">
                                <CreditCardIcon />
                            </Avatar>
                        }
                        title={`${row.card}`}
                        subheader={`Expires: ${row.expires}`}
                    />
                    <CardContent>
                        <div className="row">
                            <div className="col-6">
                                <b>Name: </b>{row.name}
                                <br />
                                <b>Expires: </b>{row.expires}
                                <br />
                                <b>Postal Code: </b>{row.postalCode}
                                <br />
                            </div>
                            <div className="col-6 textRight">
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => props.deletePaymentMethod(row.id)}
                                        className="tooltip-btn"
                                        aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>

                                {row.defaultCard ?
                                    (<Chip label="Default" className="red-background" variant="outlined" />)
                                    : (<Tooltip title="Set as default">
                                        <IconButton
                                            onClick={() => props.updateDefaultPaymentMethod(row.id)}
                                            className="tooltip-btn "
                                            aria-label="star">
                                            <StarIcon />
                                        </IconButton>
                                    </Tooltip>)}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
