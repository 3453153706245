import React from "react";
import Breadcrumb from "../common/Breadcrumb";

const Privacy = (props) => {
  return (
    <>
      <Breadcrumb title={"Privacy Policy"} />

      <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
        <div className="row row--35 align-items-start">
          <div className="col-lg-12 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">Privacy Policy</h2>
              <p className="description"></p>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                Effective date: 05/15/2021
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p>
                  <h3>1. INTRODUCTION</h3> <br />
                  <p>
                    Welcome to <b>FINDANIO LLC</b>
                  </p>
                  <p>
                    FINDANIO LLC (“us”, “we”, or “our”) operates <a className="anchor" href="https://findanio.com">
                      https://findanio.com </a> and FINDANIO web application (hereinafter referred to as"<b> Service</b>"). </p>
                  <p>Our Privacy Policy governs your visit to <a className="anchor" href="https://findanio.com">https://findanio.com</a> and
                  FINDANIO webapplication, and explains how we collect,
                  safeguard and disclose information that results from your use
                  of our Service.</p>
                  <br />
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. Unless otherwise defined in this
                  Privacy Policy, the terms used in this Privacy Policy have the
                  same meanings as in our Terms and Conditions.
                  <br />
                  Our Terms and Conditions (“<b>Terms”</b>) govern all use of
                  our Service and together with the Privacy Policy constitutes
                  your agreement with us (“<b>Agreement</b>"").
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <p>
                  <h3>2. Definitions</h3>
                  <p>
                    <b>SERVICE</b> means the
                    <a className="anchor" href="https://findanio.com"> https://findanio.com </a>
                    website and FINDANIO web application operated by FINDANIO
                    LLC.
                  </p>
                  <p>
                    <b>PERSONAL DATA</b> means data about a living individual
                    who can be identified from those data (or from those and
                    other information either in our possession or likely to come
                    into our possession).
                  </p>
                  <p>
                    <b>USAGE DATA</b> is data collected automatically either
                    generated by the use of Service or from Service
                    infrastructure itself (for example, the duration of a page
                    visit).
                  </p>
                  <p>
                    <b>COOKIES</b> are small files stored on your device
                    (computer or mobile device).
                  </p>
                  <p>
                    <b>DATA CONTROLLER </b> means a natural or legal person who
                    (either alone or jointly or in common with other persons)
                    determines the purposes for which and the manner in which
                    any personal data are, or are to be, processed. For the
                    purpose of this Privacy Policy, we are a Data Controller of
                    your data.
                  </p>
                  <p>
                    <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any
                    natural or legal person who processes the data on behalf of
                    the Data Controller. We may use the services of various
                    Service Providers in order to process your data more
                    effectively.
                  </p>
                  <p>
                    <b>DATA SUBJECT</b> is any living individual who is the
                    subject of Personal Data.
                  </p>
                  <p>
                    <b>THE USER</b> is the individual using our Service. The
                    User corresponds to the Data Subject, who is the subject of
                    Personal Data.
                  </p>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>3. Information Collection and Use</h3>
                <p>
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>4. Types of Data Collected</h3>
                <p>
                  <b>Personal Data</b> While using our Service, we may ask you
                  to provide us with certain personally identifiable information
                  that can be used to contact or identify you (“Personal Data”).
                  Personally identifiable information may include, but is not
                  limited to:
                </p>
                <p>
                  (a) Email address <br />
                  (b) First name and last name <br />
                  (c) Phone number <br />
                  (d) Address, State, Province, ZIP/Postal code, City <br />
                  (e) Cookies and Usage Data <br />
                </p>
                <p>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from us by following the
                  unsubscribe link.
                </p>
                <p>
                  <b>Usage Data </b>
                  <br />
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access Service by
                  or through a mobile device (“<b>Usage Data</b>”).
                </p>
                <p>
                  This Usage Data may include information such as your
                  computer's Internet Protocol address (e.g. IP address),
                  browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers and other diagnostic
                  data.
                </p>
                <p>
                  When you access Service with a mobile device, this Usage Data
                  may include information such as the type of mobile device you
                  use, your mobile device unique ID, the IP address of your
                  mobile device, your mobile operating system, the type of
                  mobile Internet browser you use, unique device identifiers and
                  other diagnostic data.
                </p>

                <p>
                  <b>Location Data</b>
                  <br />
                  We may use and store information about your location if you
                  give us permission to do so (“<b>Location Data</b>”). We use
                  this data to provide features of our Service, to improve and
                  customize our Service.
                </p>
                <p>
                  You can enable or disable location services when you use our
                  Service at any time by way of your device settings.
                </p>
                <p>
                  <b>Tracking Cookies Data</b> <br />
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and we hold certain information.
                </p>
                <p>
                  Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your device. Other
                  tracking technologies are also used such as beacons, tags and
                  scripts to collect and track information and to improve and
                  analyze our Service.
                </p>
                <p>
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </p>
                <p>Examples of Cookies we use:</p>
                <p>
                  (a) <b>Session Cookies</b>: We use Session Cookies to operate
                  our Service.
                </p>
                <p>
                  (b) <b>Preference Cookies</b>: We use Preference Cookies to
                  remember your preferences and various settings.
                </p>
                <p>
                  (c) <b>Security Cookies</b>: We use Security Cookies for
                  security purposes.
                </p>
                <p>
                  (d) <b>Advertising Cookies</b>: Advertising Cookies are used
                  to serve you with advertisements that may be relevant to you
                  and your interests.
                </p>
                <p>
                  <b>Other Data </b>: While using our Service, we may also
                  collect the following information: sex, age, date of birth,
                  place of birth, passport details, citizenship, registration at
                  place of residence and actual address, telephone number (work,
                  mobile), details of documents on education, qualification,
                  professional training, employment agreements, non-disclosure
                  agreements, information on bonuses and compensation,
                  information on marital status, family members, social security
                  (or other taxpayer identification) number, office location and
                  other data.
                </p>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-lg-12">
                <h3>5. Use of Data</h3>
                <p>
                  <p>
                    FINDANIO LLC uses the collected data for various purposes:
                  </p>
                  <p>
                    (a) to provide and maintain our Service; <br />
                    (b) to notify you about changes to our Service; <br />
                    (c) to allow you to participate in interactive features of
                    our Service when you choose to do so;
                    <br /> (d) to provide customer support; <br />
                    (e) to gather analysis or valuable information so that we
                    can improve our Service; <br />
                    (f) to monitor the usage of our Service;
                    <br /> (g) to detect, prevent and address technical issues;
                    <br />
                    (h) to fulfill any other purpose for which you provide it;
                    <br />
                    (i) to carry out our obligations and enforce our rights
                    arising from any contracts entered into between you and us,
                    including for billing and collection; <br />
                    (j) to provide you with notices about your account and/or
                    subscription, including expiration and renewal notices,
                    email-instructions, etc.;
                    <br /> (k) to provide you with news, special offers and
                    general information about other goods, services and events
                    which we offer that are similar to those that you have
                    already purchased or enquired about unless you have opted
                    not to receive such information;
                    <br /> (l) in any other way we may describe when you provide
                    the information;
                    <br /> (m) for any other purpose with your consent.
                    <br />
                  </p>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>6. Retention of Data</h3>
                <p>
                  We will retain your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy. We
                  will retain and use your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your data to comply with applicable laws),
                  resolve disputes, and enforce our legal agreements and
                  policies.
                </p>
                <p>
                  We will also retain Usage Data for internal analysis purposes.
                  Usage Data is generally retained for a shorter period, except
                  when this data is used to strengthen the security or to
                  improve the functionality of our Service, or we are legally
                  obligated to retain this data for longer time periods.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>7. Transfer of Data</h3>
                <p>
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ from those of your
                  jurisdiction.
                </p>
                <p>
                  If you are located outside United States and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to United States and process it
                  there.
                </p>
                <p>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </p>
                <p>
                  FINDANIO LLC will take all the steps reasonably necessary to
                  ensure that your data is treated securely and in accordance
                  with this Privacy Policy and no transfer of your Personal Data
                  will take place to an organisation or a country unless there
                  are adequate controls in place including the security of your
                  data and other personal information.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>8. Disclosure of Data</h3>
                <p>
                  We may disclose personal information that we collect, or you
                  provide:
                </p>
                <p>
                  (a) <b>Disclosure for Law Enforcement.</b>
                  <br /> Under certain circumstances, we may be required to
                  disclose your Personal Data if required to do so by law or in
                  response to valid requests by public authorities.
                </p>
                <p>
                  (b) <b>Business Transaction.</b>
                  <br /> If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </p>
                <p>
                  (c) <b>Other cases.</b>
                  <br /> We may disclose your information also: <br />
                  (i) to our subsidiaries and affiliates; <br />
                  (ii) to contractors, service providers, and other third
                  parties we use to support our business; <br />
                  (iii) to fulfill the purpose for which you provide it;
                  <br /> (iv) for any other purpose disclosed by us when you
                  provide the information; <br />
                  (v) if we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers, or others.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>9. Security of Data</h3>
                <p>
                  The security of your data is important to us but remember that
                  no method of transmission over the Internet or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>
                  10. Your Data Protection Rights Under General Data Protection
                  Regulation (GDPR)
                </h3>
                <p>
                  If you are a resident of the European Union (EU) and European
                  Economic Area (EEA), you have certain data protection rights,
                  covered by GDPR. – See more at
                  <a className="anchor" href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                    {"  "}
                    https://eur-lex.europa.eu/eli/reg/2016/679/oj
                  </a>
                </p>
                <p>
                  We aim to take reasonable steps to allow you to correct,
                  amend, delete, or limit the use of your Personal Data.
                </p>
                <p>
                  If you wish to be informed what Personal Data we hold about
                  you and if you want it to be removed from our systems, please
                  email us at info@findanio.com.
                </p>
                <p>
                  In certain circumstances, you have the following data
                  protection rights:
                </p>
                <p>
                  (a) the right to access, update or to delete the information
                  we have on you; <br />
                  (b) the right of rectification. You have the right to have
                  your information rectified if that information is inaccurate
                  or incomplete; <br />
                  (c) the right to object. You have the right to object to our
                  processing of your Personal Data;
                  <br /> (d) the right of restriction. You have the right to
                  request that we restrict the processing of your personal
                  information; <br />
                  (e) the right to data portability. You have the right to be
                  provided with a copy of your Personal Data in a structured,
                  machine-readable and commonly used format;
                  <br />
                  (f) the right to withdraw consent. You also have the right to
                  withdraw your consent at any time where we rely on your
                  consent to process your personal information;
                  <p>
                    Please note that we may ask you to verify your identity
                    before responding to such requests. Please note, we may not
                    able to provide Service without some necessary data.
                  </p>
                  You have the right to complain to a Data Protection Authority
                  about our collection and use of your Personal Data. For more
                  information, please contact your local data protection
                  authority in the European Economic Area (EEA).
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>
                  11. Your Data Protection Rights under the California Privacy
                  Protection Act (CalOPPA)
                </h3>
                <p>
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law’s reach stretches well beyond California to
                  require a person or company in the United States (and
                  conceivable the world) that operates websites collecting
                  personally identifiable information from California consumers
                  to post a conspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  with whom it is being shared, and to comply with this policy.
                  – See more at:
                  <a className="anchor" href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                    https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                  </a>
                </p>
                <p>
                  According to CalOPPA we agree to the following: <br /> (a)
                  users can visit our site anonymously; <br /> (b) our Privacy
                  Policy link includes the word “Privacy”, and can easily be
                  found on the page specified above on the home page of our
                  website; <br /> (c) users will be notified of any privacy
                  policy changes on our Privacy Policy Page; <br /> (d) users
                  are able to change their personal information by emailing us
                  at info@findanio.com. <br />
                  <p>Our Policy on “Do Not Track” Signals: </p>
                  <p>
                    We honor Do Not Track signals and do not track, plant
                    cookies, or use advertising when a Do Not Track browser
                    mechanism is in place. Do Not Track is a preference you can
                    set in your web browser to inform websites that you do not
                    want to be tracked.
                  </p>
                  <p>
                    You can enable or disable Do Not Track by visiting the
                    Preferences or Settings page of your web browser.
                  </p>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>
                  12. Your Data Protection Rights under the California Consumer
                  Privacy Act (CCPA)
                </h3>
                <p>
                  If you are a California resident, you are entitled to learn
                  what data we collect about you, ask to delete your data and
                  not to sell (share) it. To exercise your data protection
                  rights, you can make certain requests and ask us:
                </p>
                <p>
                  <b>(a) What personal information we have about you.</b> If you
                  make this request, we will return to you:
                </p>
                <p>
                  (i) The categories of personal information we have collected
                  about you. <br />
                  (ii) The categories of sources from which we collect your
                  personal information.
                  <br /> (iii) The business or commercial purpose for collecting
                  or selling your personal information.
                  <br /> (iv) The categories of third parties with whom we share
                  personal information.
                  <br /> (v) The specific pieces of personal information we have
                  collected about you.
                  <br /> (vi) A list of categories of personal information that
                  we have sold, along with the category of any other company we
                  sold it to. If we have not sold your personal information, we
                  will inform you of that fact. <br />
                  (vii) A list of categories of personal information that we
                  have disclosed for a business purpose, along with the category
                  of any other company we shared it with.
                </p>
                <p>
                  Please note, you are entitled to ask us to provide you with
                  this information up to two times in a rolling twelve-month
                  period. When you make this request, the information provided
                  may be limited to the personal information we collected about
                  you in the previous 12 months.
                </p>
                <p>
                  <b>(b) To delete your personal information.</b>
                  <br /> If you make this request, we will delete the personal
                  information we hold about you as of the date of your request
                  from our records and direct any service providers to do the
                  same. In some cases, deletion may be accomplished through
                  de-identification of the information. If you choose to delete
                  your personal information, you may not be able to use certain
                  functions that require your personal information to operate.
                </p>
                <p>
                  <b>(c) To stop selling your personal information.</b> If you
                  submit a request to stop selling your personal information, we
                  will stop selling it. If you are a California resident, to
                  opt-out of the sale of your personal information, click “Do
                  Not Sell My Personal Information” at the bottom of our home
                  page to submit your request.
                </p>
                <p>
                  Please note, if you ask us to delete or stop selling your
                  data, it may impact your experience with us, and you may not
                  be able to participate in certain programs or membership
                  services which require the usage of your personal information
                  to function. But in no circumstances, we will discriminate
                  against you for exercising your rights.
                </p>
                <p>
                  To exercise your California data protection rights described
                  above, please send your request(s) by one of the following
                  means:
                </p>
                <p>
                  By email: info@findanio.com <br />
                  By visiting this page on our website:
                  <a className="anchor" href=" https://findanio.com/contact-us">

                    https://findanio.com/contact-us
                  </a>
                </p>
                <p>
                  Your data protection rights, described above, are covered by
                  the CCPA, short for the California Consumer Privacy Act. To
                  find out more, visit the official California Legislative
                  Information website. The CCPA took effect on 01/01/2020.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>13. Service Providers</h3>
                <p>
                  We may employ third party companies and individuals to
                  facilitate our Service (<b>“Service Providers”</b>), provide
                  Service on our behalf, perform Service-related services or
                  assist us in analysing how our Service is used.
                </p>
                <p>
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3> 14. Analytics</h3>
                <p>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>
                <p>
                  <b>Google Analytics</b>
                </p>
                <p>
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualise and personalise the ads of its
                  own advertising network.
                </p>
                <p>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:
                  <a className="anchor" href="https://policies.google.com/privacy?hl=en">

                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
                <p>
                  We also encourage you to review the Google's policy for
                  safeguarding your data:
                  <a className="anchor" href="https://support.google.com/analytics/answer/6004245">

                    https://support.google.com/analytics/answer/6004245.
                  </a>
                </p>
                <p>
                  <b>Firebase </b>
                </p>
                <p>Firebase is analytics service provided by Google Inc.</p>
                <p>
                  You may opt-out of certain Firebase features through your
                  mobile device settings, such as your device advertising
                  settings or by following the instructions provided by Google
                  in their Privacy Policy:
                  <a className="anchor" href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
                <p>
                  For more information on what type of information Firebase
                  collects, please visit the Google Privacy Terms web page:
                  <a className="anchor" href="https://policies.google.com/privacy?hl=en">
                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>15. CI/CD tools</h3>
                <p>
                  We may use third-party Service Providers to automate the
                  development process of our Service.
                </p>
                <p>
                  <b>GitHub</b>
                </p>
                <p>GitHub is provided by GitHub, Inc. </p>
                <p>
                  GitHub is a development platform to host and review code,
                  manage projects, and build software.
                  <br /> For more information on what data GitHub collects for
                  what purpose and how the protection of the data is ensured,
                  please visit GitHub Privacy Policy page:
                  <a className="anchor" href="https://help.github.com/en/articles/github-privacy-statement.">
                    {"  "}
                    https://help.github.com/en/articles/github-privacy-statement.
                  </a>
                </p>
                <p>
                  <b>Fastlane</b>
                </p>
                <p>Fastlane is provided by Google, Inc.</p>
                <p>
                  Fastlane is a continuous delivery tool for iOS and Android
                  apps.
                </p>
                <p>
                  For more information on what data Fastlane collects for what
                  purpose and how the protection of the data is ensured, please
                  visit the Google Privacy Policy page:
                  <a className="anchor" href=" https://policies.google.com/privacy">
                    {"  "}
                    https://policies.google.com/privacy
                  </a>
                </p>
                <p>
                  <b>CircleCI</b>
                </p>
                <p>CircleCI is provided by Circle Internet Services, Inc.</p>
                <p>
                  CircleCI is Continuous Integration, a development practice
                  which is being used by software teams allowing them to build,
                  test and deploy applications easier and quicker on multiple
                  platforms.
                </p>
                <p>
                  For more information on what data Circle CI collects for what
                  purpose and how the protection of the data is ensured, please
                  visit Circle CI Privacy Policy page:
                  <a className="anchor" href="https://circleci.com/privacy/">
                    {"  "}
                    https://circleci.com/privacy/
                  </a>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>16. Advertising </h3>
                <p>
                  We may use third-party Service Providers to show
                  advertisements to you to help support and maintain our
                  Service.
                </p>
                <p>
                  <b>Google AdSense DoubleClick Cookie</b>
                </p>
                <p>
                  Google, as a third party vendor, uses cookies to serve ads on
                  our Service. Google's use of the DoubleClick cookie enables it
                  and its partners to serve ads to our users based on their
                  visit to our Service or other websites on the Internet.
                </p>
                <p>
                  You may opt out of the use of the DoubleClick Cookie for
                  interest-based advertising by visiting the Google Ads Settings
                  web page:
                  <a className="anchor" href="http://www.google.com/ads/preferences/">

                    http://www.google.com/ads/preferences/
                  </a>
                </p>
                <p>
                  <b> Bing Ads </b>
                </p>
                <p>
                  Bing Ads is an advertising service provided by Microsoft Inc.
                  You can opt-out from Bing Ads by following the instructions on
                  Bing Ads Opt-out page:
                  <a className="anchor" href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">

                    "https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                  </a>
                  <br />
                  For more information about Bing Ads, please visit their
                  Privacy Policy:
                  <a className="anchor" href="https://privacy.microsoft.com/en-us/PrivacyStatement">

                    https://privacy.microsoft.com/en-us/PrivacyStatement
                  </a>
                </p>
                <p>
                  <b>AdMob by Google</b>
                </p>
                <p> AdMob by Google is provided by Google Inc.</p>
                <p>
                  You can opt-out from the AdMob by Google service by following
                  the instructions described by Google:
                  <a className="anchor" href="https://support.google.com/ads/answer/2662922?hl=en">

                    https://support.google.com/ads/answer/2662922?hl=en
                  </a>
                </p>
                <p>
                  For more information on how Google uses the collected
                  information, please visit the “How Google uses data when you
                  use our partners' sites or app” page:
                  <a className="anchor" href="http://www.google.com/policies/privacy/partners/">
                    {"  "}
                    http://www.google.com/policies/privacy/partners/
                  </a>
                  or visit the Privacy Policy of Google:
                  <a className="anchor" href="http://www.google.com/policies/privacy/">
                    {"  "}
                    http://www.google.com/policies/privacy/
                  </a>
                </p>
                <p>
                  <b>AdButler</b>
                </p>
                <p>
                  AdButler is an advertising service provided by Sparklit
                  Networks Inc. For more information about AdButler, please
                  visit their Privacy Policy:
                  <a className="anchor" href="https://www.sparklit.com/agreements.spark?agreement=privacy">
                    {"  "}
                    https://www.sparklit.com/agreements.spark?agreement=privacy
                  </a>
                </p>
                <p>
                  <b>Unity Ads</b>
                </p>
                <p>Unity Ads is provided by Unity Technologies.</p>
                <p>
                  You can opt-out from Unity Ads service by following the
                  instructions as described by Unity Technologies on their
                  Privacy Policy page:
                  <a className="anchor" href="https://unity3d.com/legal/privacy-policy">
                    {"  "}
                    https://unity3d.com/legal/privacy-policy
                  </a>
                  <br />
                  For more information about Unity Technologies, please visit
                  Unity Technologies Privacy Policy:
                  <a className="anchor" href=" https://unity3d.com/legal/privacy-policy">
                    {"  "}
                    https://unity3d.com/legal/privacy-policy
                  </a>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3> 17. Behavioral Remarketing</h3>
                <p>
                  FINDANIO LLC uses remarketing services to advertise on third
                  party websites to you after you visited our Service. We and
                  our third-party vendors use cookies to inform, optimise and
                  serve ads based on your past visits to our Service.
                </p>
                <p>
                  <b>Google Ads (AdWords) </b> <br />
                  Google Ads (AdWords) remarketing service is provided by Google
                  Inc.
                  <br /> You can opt-out of Google Analytics for Display
                  Advertising and customize the Google Display Network ads by
                  visiting the Google Ads Settings page:
                  <a className="anchor" href="http://www.google.com/settings/ads">

                    http://www.google.com/settings/ads
                  </a>
                  <br />
                  Google also recommends installing the Google Analytics Opt-out
                  Browser Add-on –
                  <a className="anchor" href="https://tools.google.com/dlpage/gaoptout">

                    https://tools.google.com/dlpage/gaoptout
                  </a>
                  – for your web browser. Google Analytics Opt-out Browser
                  Add-on provides visitors with the ability to prevent their
                  data from being collected and used by Google Analytics.
                  <br />
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:
                  <a className="anchor" href="https://policies.google.com/privacy?hl=en">

                    https://policies.google.com/privacy?hl=en
                  </a>
                </p>
                <p>
                  <b>Bing Ads </b>
                  <br />
                  Remarketing Bing Ads remarketing service is provided by
                  Microsoft Inc. <br /> You can opt-out of Bing Ads
                  interest-based ads by following their instructions:
                  <a className="anchor" href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">

                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                  </a>
                  <br />
                  You can learn more about the privacy practices and policies of
                  Microsoft by visiting their Privacy Policy page:
                  <a className="anchor" href="https://privacy.microsoft.com/en-us/PrivacyStatement">

                    https://privacy.microsoft.com/en-us/PrivacyStatement
                  </a>
                  <br />
                </p>
                <p>
                  <b>Twitter</b> <br /> Twitter remarketing service is provided
                  by Twitter Inc. <br /> You can opt-out from Twitter's
                  interest-based ads by following their instructions:
                  <a className="anchor" href="https://support.twitter.com/articles/20170405">

                    https://support.twitter.com/articles/20170405
                  </a>
                  <br />
                  You can learn more about the privacy practices and policies of
                  Twitter by visiting their Privacy Policy page:
                  <a className="anchor" href="https://twitter.com/privacy">

                    https://twitter.com/privacy
                  </a>
                </p>
                <p>
                  <b>Facebook</b> <br /> Facebook remarketing service is
                  provided by Facebook Inc.
                  <br /> You can learn more about interest-based advertising
                  from Facebook by visiting this page:
                  <a className="anchor" href="https://www.facebook.com/help/164968693837950">

                    https://www.facebook.com/help/164968693837950
                  </a>
                  <br />
                  To opt-out from Facebook's interest-based ads, follow these
                  instructions from Facebook:
                  <a className="anchor" href=" https://www.facebook.com/help/568137493302217">

                    https://www.facebook.com/help/568137493302217
                  </a>
                </p>
                <p>
                  Facebook adheres to the Self-Regulatory Principles for Online
                  Behavioural Advertising established by the Digital Advertising
                  Alliance. You can also opt-out from Facebook and other
                  participating companies through the Digital Advertising
                  Alliance in the USA
                  <a className="anchor" href="http://www.aboutads.info/choices/">

                    http://www.aboutads.info/choices/
                  </a>
                  , the Digital Advertising Alliance of Canada in Canada
                  <a className="anchor" href="  http://youradchoices.ca/ ">

                    http://youradchoices.ca/
                  </a>
                  or the European Interactive Digital Advertising Alliance in
                  Europe
                  <a className="anchor" href=" http://www.youronlinechoices.eu/">

                    http://www.youronlinechoices.eu/
                  </a>
                  , or opt-out using your mobile device settings.
                </p>
                <p>
                  For more information on the privacy practices of Facebook,
                  please visit Facebook's Data Policy:
                  <a className="anchor" href="https://www.facebook.com/privacy/explanation">

                    https://www.facebook.com/privacy/explanation
                  </a>
                </p>
                <p>
                  <b>Pinterest</b> <br />
                  Pinterest remarketing service is provided by Pinterest Inc.
                  <br />
                  You can opt-out from Pinterest's interest-based ads by
                  enabling the “Do Not Track” functionality of your web browser
                  or by following Pinterest instructions:
                  <a className="anchor" href="http://help.pinterest.com/en/articles/personalization-and-data">

                    http://help.pinterest.com/en/articles/personalization-and-data
                  </a>
                  <br />
                  You can learn more about the privacy practices and policies of
                  Pinterest by visiting their Privacy Policy page:
                  <a className="anchor" href=" https://about.pinterest.com/en/privacy-policy">

                    https://about.pinterest.com/en/privacy-policy
                  </a>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3> 18. Payments</h3>
                <p>
                  We may provide paid products and/or services within Service.
                  In that case, we use third-party services for payment
                  processing (e.g. payment processors). <br />
                  We will not store or collect your payment card details. That
                  information is provided directly to our third-party payment
                  processors whose use of your personal information is governed
                  by their Privacy Policy. These payment processors adhere to
                  the standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, Mastercard, American Express and Discover. PCI-DSS
                  requirements help ensure the secure handling of payment
                  information.
                </p>
                <p>
                  The payment processors we work with are: <br />
                  <b>Stripe</b>: <br />
                  Their Privacy Policy can be viewed at:
                  <a className="anchor" href=" https://stripe.com/us/privacy">

                    https://stripe.com/us/privacy
                  </a>
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>19. Links to Other Sites</h3>
                <p>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third party link, you will be
                  directed to that third party's site. We strongly advise you to
                  review the Privacy Policy of every site you visit.
                </p>
                <p>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>20. Children's Privacy</h3>
                <p>
                  Our Services are not intended for use by children under the
                  age of 18 (<b>“Child”</b> or <b>“Children”</b>).
                </p>
                <p>
                  We do not knowingly collect personally identifiable
                  information from Children under 18. If you become aware that a
                  Child has provided us with Personal Data, please contact us.
                  If we become aware that we have collected Personal Data from
                  Children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>21. Changes to This Privacy Policy</h3>
                <p>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page. <br />
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  “effective date” at the top of this Privacy Policy.
                  <br />
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
              </div>
            </div>
            <br />

            <br />
            <div className="row">
              <div className="col-lg-12">
                <h3>CONTACT US</h3>
                <p>
                  If you have questions or comments about this Privacy Policy,
                  please contact us:
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <p>By email: <a href="mailto:info@findanio.com">info@findanio.com</a></p>
                <p>
                  By visiting this page on our website :
                  <a className="anchor" href="https://findanio.com/contact-us">
                    {"  "} https://findanio.com/contact-us
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Privacy;
