import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { processSubscriptionConfirmationAction } from '../../store/actions/authActions'
import { Link } from 'react-router-dom'
import Breadcrumb from "../common/Breadcrumb";

class GetStarted extends Component {

    handleSubscription = (id, uid) => {
        if (id && uid) {
            this.props.processSubscriptionConfirmation(id, uid);
        }
    }

    componentDidMount() {
        const { auth, id } = this.props;
        if (auth && id) {
            this.handleSubscription(id, auth.uid);
        }
    }

    render() {
        const { auth, subError, status } = this.props;
        if (!auth.uid) return <Redirect to='/login' />
        if (subError) {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--50">
                                    <p className="description center">There was an issue processing the subscription. Please try again in some time.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else if (status && status === 'success') {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--50 ">
                                    <p className="description center">
                                        Your subscription is now active.
                                    </p>
                                    <br /><br />
                                    <p className="description center">
                                        <Link to="/subscription">
                                            <button className="rn-button-style--2 btn-solid">View subscription</button>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--50">
                                    <p className="description center">Processing... Please wait.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    return {
        id: id,
        auth: state.firebase.auth,
        subError: state.auth.subError,
        status: state.auth.status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        processSubscriptionConfirmation: (id, uid) => dispatch(processSubscriptionConfirmationAction(id, uid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted)
