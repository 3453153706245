import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createProject, clearCreatedProjectStatus } from '../../store/actions/projectActions'
import { Redirect } from 'react-router-dom'
import M from "materialize-css";
import MapWithADirectionsRenderer from "../maps/MapWithADirectionsRenderer";
import RideDistance from './RideDistance';
import RidePrice from './RidePrice';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Loader1 from '../loader/Loader1';
import ServiceHours from './ServiceHours'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectPaymentMethod from '../payment/selectPaymentMethod/SelectPaymentMethod';
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
// import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import ErrorMessage from "../errorMessage/ErrorMessage"
import DisclaimerMessages from "../disclaimerMessages/DisclaimerMessages"

const format = 'hh:mm a';
const coeff = 1000 * 60 * 5;

class BookNewRide extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState()
    this.handlePlaceSelectFrom = this.handlePlaceSelectFrom.bind(this)
    this.handlePlaceSelectTo = this.handlePlaceSelectTo.bind(this)
    this.handleCalculateDistance = this.handleCalculateDistance.bind(this)
    this.updateCalculatedPrice = this.updateCalculatedPrice.bind(this)
    this.onTimeChange = this.onTimeChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.autocompleteServiceFrom = null
    this.autocompleteServiceTo = null
  }

  initialState() {
    return {
      serviceFrom: '',
      serviceTo: '',
      serviceDate: new Date(Math.round((new Date().getTime() + 15 * 60000) / coeff) * coeff),
      serviceType: 'Point-to-Point',
      serviceHours: 2,
      serviceVehicle: 'Sedan',
      specialNotes: '',
      serviceVehiclePersons: 3,
      serviceVehicleBags: 3,
      fromlat: "",
      fromlng: "",
      tolat: "",
      tolng: "",
      calculatedDistance: -1,
      serviceFromState: '',
      loading: false,
      validServiceTo: false,
      validServiceFrom: false,
      selectedPaymentMethod: '',
      selectedPaymentMethodStr: '',
      requestorPrice: 0.00,
      requestorPriceError: false,
      passengerFirstName: '',
      passengerLastName: '',
      passengerPhone: '',
      calculatedPrice: {
        Sedan: 0.00,
        LuxurySedan: 0.00,
        SUV: 0.00,
        PassengerVan: 0.00,
        CorporateSprinter: 0.00,
        LimoSprinter: 0.00,
        Bus24Passenger: 0.00,
        Bus34Passenger: 0.00,
        CoachBus: 0.00,
        StretchLimo: 0.00,
        StretchSUV: 0.00
      },
      rateCalculated: false,
      error: false,
      calculatedRideDuration: ''
    }
  }

  componentDidMount() {

    let charCounter = document.querySelectorAll('.has-character-counter');
    M.CharacterCounter.init(charCounter);

    const google = window.google;
    var options = {
      componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] }
    };
    this.autocompleteServiceFrom = new google.maps.places.Autocomplete(document.getElementById('serviceFrom'), options)
    this.autocompleteServiceFrom.addListener("place_changed", this.handlePlaceSelectFrom);

    this.autocompleteServiceTo = new google.maps.places.Autocomplete(document.getElementById('serviceTo'), options)
    this.autocompleteServiceTo.addListener("place_changed", this.handlePlaceSelectTo);

  }

  componentDidUpdate(prevProps) {
    if ((this.props.rideCreationErr && !prevProps.rideCreationErr)
      || (this.props.rideCreationErr && this.state.loading)) {
      this.setState({
        loading: false
      });
    }

  }

  handlePhoneChange = (value, data, event) => {
    this.setState({
      passengerPhone: value.replace(/[^0-9]+/g, '')
    })
  }

  getServiceVehicleBags(vehicle) {
    switch (vehicle) {
      case 'Sedan': return 3;
      case 'LuxurySedan': return 3;
      case 'SUV': return 6;
      case 'PassengerVan': return 12;
      case 'CorporateSprinter': return 12;
      case 'LimoSprinter': return 12;
      case 'Bus24Passenger': return 24;
      case 'Bus34Passenger': return 34;
      case 'CoachBus': return 55;
      case 'StretchLimo': return 3;
      case 'StretchSUV': return 6;
      default: return 0;
    }
  }

  getServiceVehiclePersons(vehicle) {
    switch (vehicle) {
      case 'Sedan': return 3;
      case 'LuxurySedan': return 3;
      case 'SUV': return 6;
      case 'PassengerVan': return 12;
      case 'CorporateSprinter': return 12;
      case 'LimoSprinter': return 12;
      case 'Bus24Passenger': return 24;
      case 'Bus34Passenger': return 34;
      case 'CoachBus': return 55;
      case 'StretchLimo': return 8;
      case 'StretchSUV': return 16;
      default: return 0;
    }
  }

  getServiceHours(vehicle) {
    switch (vehicle) {
      case 'Sedan': return 2;
      case 'LuxurySedan': return 2;
      case 'SUV': return 2;
      case 'PassengerVan': return 3;
      case 'CorporateSprinter': return 3;
      case 'LimoSprinter': return 3;
      case 'Bus24Passenger': return 3;
      case 'Bus34Passenger': return 4;
      case 'CoachBus': return 5;
      case 'StretchLimo': return 3;
      case 'StretchSUV': return 3;
      default: return 2;
    }
  }

  onTimeChange(value) {
    if (value && value._d) {
      this.setState({
        serviceDate: new Date(new Date(new Date(this.state.serviceDate.getTime()).setHours(value._d.getHours())).setMinutes(value._d.getMinutes()))
      })
    }
  }

  onDateChange(value) {
    if (value) {
      this.setState({
        serviceDate: value,
      })
    }
  }

  handleChange = (e) => {
    this.props.clearCreatedProjectStatus();
    if (e.target.name === 'selectedPaymentMethod') {
      let paymentMethodDetails = e.target.value.split('::');
      this.setState({
        selectedPaymentMethod: paymentMethodDetails[0],
        selectedPaymentMethodStr: paymentMethodDetails[1],
      })
    } else if (e.target.id === 'serviceFrom') {
      this.setState({
        serviceFrom: e.target.value,
        serviceFromState: '',
        serviceFromShort: '',
        fromlat: '',
        fromlng: '',
        calculatedPrice: {
          Sedan: 0.00,
          LuxurySedan: 0.00,
          SUV: 0.00,
          PassengerVan: 0.00,
          CorporateSprinter: 0.00,
          LimoSprinter: 0.00,
          Bus24Passenger: 0.00,
          Bus34Passenger: 0.00,
          CoachBus: 0.00,
          StretchLimo: 0.00,
          StretchSUV: 0.00
        },
        calculatedDistance: -1,
        validServiceFrom: false,
        requestorPrice: 0.0,
        requestorPriceError: false,
        calculatedRideDuration: ''
      })
    } else if (e.target.id === 'serviceTo') {
      this.setState({
        serviceTo: e.target.value,
        serviceToShort: '',
        tolat: '',
        tolng: '',
        calculatedPrice: {
          Sedan: 0.00,
          LuxurySedan: 0.00,
          SUV: 0.00,
          PassengerVan: 0.00,
          CorporateSprinter: 0.00,
          LimoSprinter: 0.00,
          Bus24Passenger: 0.00,
          Bus34Passenger: 0.00,
          CoachBus: 0.00,
          StretchLimo: 0.00,
          StretchSUV: 0.00
        },
        calculatedDistance: -1,
        validServiceTo: false,
        requestorPrice: 0.0,
        requestorPriceError: false,
        calculatedRideDuration: ''
      })
    } else if (e.target.id === 'serviceVehicle') {
      let currentLoading = this.state.loading;
      if (this.state.serviceType === 'HourlyCharter') {
        this.setState({
          serviceVehicle: e.target.value,
          serviceVehiclePersons: this.getServiceVehiclePersons(e.target.value),
          serviceVehicleBags: this.getServiceVehicleBags(e.target.value),
          serviceHours: this.getServiceHours(e.target.value),
          loading: this.state.validServiceFrom === true && this.state.validServiceTo === true ? true : currentLoading
        })
      } else {
        this.setState({
          serviceVehicle: e.target.value,
          serviceVehiclePersons: this.getServiceVehiclePersons(e.target.value),
          serviceVehicleBags: this.getServiceVehicleBags(e.target.value),
          // serviceHours: this.getServiceHours(e.target.value),
          loading: this.state.validServiceFrom === true && this.state.validServiceTo === true ? true : currentLoading
        })
      }
    } else if (e.target.id === 'serviceType') {
      let currentLoading = this.state.loading;
      this.setState({
        serviceType: e.target.value,
        serviceHours: this.getServiceHours(this.state.serviceVehicle),
        loading: this.state.validServiceFrom === true && this.state.validServiceTo === true ? true : currentLoading
      })
    } else {
      this.setState({
        [e.target.id]: e.target.value
      })
    }
  }


  handleTravelerCharges = (event, value) => {
    let price = (+value).toFixed(2);
    // console.log(price, this.state.calculatedPrice[this.state.serviceVehicle], (0.90 * this.state.calculatedPrice[this.state.serviceVehicle]));
    if (price === 0.00) {
      this.setState({
        requestorPrice: 0.00,
        requestorPriceError: false
      })
    } else if (price > (this.state.calculatedPrice[this.state.serviceVehicle])) {
      this.setState({
        requestorPrice: price,
        requestorPriceError: false
      })
    } else if (price > (0.90 * this.state.calculatedPrice[this.state.serviceVehicle])) {
      this.setState({
        requestorPrice: price,
        requestorPriceError: false
      })
    } else {
      this.setState({
        requestorPrice: 0.00,
        requestorPriceError: true
      })
    }
  }

  // handleTravelerChargesOld = (value, name) => {
  //   let price = (+value).toFixed(2);
  //   console.log(price, this.state.calculatedPrice[this.state.serviceVehicle], (0.90 * this.state.calculatedPrice[this.state.serviceVehicle]));
  //   if (price === 0.00) {
  //     this.setState({
  //       requestorPrice: 0.00,
  //       requestorPriceError: false
  //     })
  //   } else if (price > (this.state.calculatedPrice[this.state.serviceVehicle])) {
  //     this.setState({
  //       requestorPrice: price,
  //       requestorPriceError: false
  //     })
  //   } else if (price > (0.90 * this.state.calculatedPrice[this.state.serviceVehicle])) {
  //     this.setState({
  //       requestorPrice: price,
  //       requestorPriceError: false
  //     })
  //   } else {
  //     this.setState({
  //       requestorPrice: 0.00,
  //       requestorPriceError: true
  //     })
  //   }
  // }

  handleCalculateDistance(distance, duration) {
    this.setState({
      calculatedDistance: distance,
      calculatedRideDuration: duration
    })
  }

  updateCalculatedPrice(obj) {
    if (obj.refresh) {
      this.setState({
        loading: false
      })
    } else {
      this.setState({
        calculatedPrice: obj,
        rateCalculated: true,
        loading: false
      })
    }
  }

  handlePlaceSelectFrom() {
    let addressObject = this.autocompleteServiceFrom.getPlace()
    let state = null;
    let locality = null;
    let postalCode = null;
    if (addressObject && addressObject.geometry) {
      addressObject.address_components.forEach(component => {
        component.types.forEach(type => {
          if (type === 'administrative_area_level_1') {
            state = component.short_name;
          }
          if (type === 'locality') {
            locality = component.short_name;
          }
          if (type === 'postal_code') {
            postalCode = component.short_name;
          }
        })
      })
      let currentLoading = this.state.loading;
      this.setState({
        serviceFrom: addressObject.formatted_address,
        // serviceFromShort: `${locality}, ${state}, ${postalCode}`,
        serviceFromShort: `${locality ? locality : ''}${state ? ', ' + state : ''}${postalCode ? ', ' + postalCode : ''}`,
        serviceFromState: state,
        validServiceFrom: true,
        fromlat: addressObject.geometry.location.lat(),
        fromlng: addressObject.geometry.location.lng(),
        loading: this.state.serviceTo !== '' && this.state.validServiceTo === true ? true : currentLoading
      })
    }
  }

  reviewRide = () => {
    var elems = document.getElementById('confirmRideCreationModal');
    M.Modal.init(elems, {});
    M.Modal.getInstance(elems).open();
  }

  handlePlaceSelectTo() {
    let addressObject = this.autocompleteServiceTo.getPlace();
    let currentLoading = this.state.loading;
    let state = null;
    let locality = null;
    let postalCode = null;
    if (addressObject && addressObject.geometry) {
      addressObject.address_components.forEach(component => {
        component.types.forEach(type => {
          if (type === 'administrative_area_level_1') {
            state = component.short_name;
          }
          if (type === 'locality') {
            locality = component.short_name;
          }
          if (type === 'postal_code') {
            postalCode = component.short_name;
          }
        })
      })
      this.setState({
        serviceTo: addressObject.formatted_address,
        serviceToShort: `${locality}, ${state}, ${postalCode}`,
        validServiceTo: true,
        tolat: addressObject.geometry.location.lat(),
        tolng: addressObject.geometry.location.lng(),
        loading: this.state.serviceFrom !== '' && this.state.validServiceFrom === true ? true : currentLoading
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    }, (() => {
      this.props.createProject(this.state)
        .then(() => {
          window.scrollTo(0, 0);
          this.setState({
            loading: false
          });
        });

    })
    );
  }

  componentWillUnmount() {
    this.props.clearCreatedProjectStatus();
    this.setState({
      loading: false,
    });
  }

  render() {
    const { auth, rideCreationStatus, createdRideId, rideCreationErr } = this.props;

    if (!auth.uid) {
      return <Redirect to='/login' />
    }

    let loader = null;
    if (this.state.loading === true && rideCreationErr === null) {
      loader = <Loader1 />
    }

    if ((rideCreationStatus && rideCreationStatus === true) && (rideCreationErr === null)) {
      let redirectUrl = '/ride/requested/' + createdRideId;
      return <Redirect to={redirectUrl} />
    }

    if (rideCreationErr) {
      var elems = document.getElementById('confirmRideCreationModal');
      M.Modal.getInstance(elems).close();
    }

    let conditionsAppliedTxt = ' (*Airport fees, tolls, parking, wait times and additional stops might apply)';
    let serviceTypeRelatedDiv = null;
    if (this.state.serviceType === 'HourlyCharter') {
      conditionsAppliedTxt = ' (*Hourly jobs booked garage-to-garage or check with your operator.)';
      serviceTypeRelatedDiv = (
        <div className="col-md-12 col-sm-12 ">
          <ServiceHours
            serviceHours={this.state.serviceHours}
            serviceVehicle={this.state.serviceVehicle}
            handleChange={this.handleChange}
          />
        </div>
      )
    } else {
      serviceTypeRelatedDiv = null;
    }

    return (
      <>
        {loader}
        <Breadcrumb title1={'Book a Ride'} parent='Rides' parentLink='/rides' />
        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
          <div className="main_container">
            <div className="section-title text-left mb--50">
              <p className="description">
                Book your ride in 3 easy steps. Simply enter the required details and submit the ride request.
              </p>
            </div>
            <div className="mb--40">
              {rideCreationErr ? <ErrorMessage errorMessageText={rideCreationErr}></ErrorMessage> : null}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <h3>Step 1: Enter ride details</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="serviceType">Service Type<span className="star">*</span></label>
                    <select id='serviceType' onChange={this.handleChange} >
                      <option value="Point-to-Point" defaultValue>
                        Point-to-Point
                      </option>
                      <option value="HourlyCharter">
                        Hourly Charter
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="serviceDate">Service Date<span className="star">*</span></label>
                    <br />
                    <DatePicker
                      id='serviceDate'
                      placeholderText="Click to select a date"
                      todayButton="Today"
                      dateFormat="MMMM d, yyyy"
                      showPopperArrow={false}
                      selected={this.state.serviceDate}
                      maxDate={new Date(new Date().setFullYear((new Date().getFullYear() + 1)))}
                      minDate={new Date()}
                      strictParsing
                      peekNextMonth={false}
                      onChange={this.onDateChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="serviceTime">Service Time<span className="star">*</span></label>
                    <br />
                    <TimePicker
                      showSecond={false}
                      defaultValue={moment(this.state.serviceDate)}
                      onChange={this.onTimeChange}
                      format={format}
                      use12Hours={true}
                      minuteStep={5}
                      id="serviceTime"
                      placement={'bottomRight'}
                      inputReadOnly />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="serviceFrom">Pick-Up Location<span className="star">*</span></label>
                    <input id="serviceFrom"

                      ref="input"
                      onChange={this.handleChange}
                      placeholder=""
                      type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="serviceTo">Drop-Off Location<span className="star">*</span></label>
                    <input id="serviceTo"

                      placeholder=""
                      ref="input"
                      onChange={this.handleChange}
                      type="text" />
                  </div>
                </div>
                <div className="row mb--30">
                  <div className="col-lg-6">
                    <label htmlFor="serviceVehicle">Vehicle Type<span className="star">*</span></label>
                    <select id='serviceVehicle' onChange={this.handleChange} >
                      <option value="Sedan" defaultValue>Sedan</option>
                      <option value="LuxurySedan">Luxury Sedan</option>
                      <option value="SUV">SUV</option>
                      <option value="PassengerVan">Passenger Van</option>
                      <option value="CorporateSprinter">Corporate Sprinter</option>
                      <option value="LimoSprinter">Limo Sprinter</option>
                      <option value="Bus24Passenger">24 Passenger Bus</option>
                      <option value="Bus34Passenger">34 Passenger Bus</option>
                      <option value="CoachBus">CoachBus</option>
                      <option value="StretchLimo">Stretch Limo</option>
                      <option value="StretchSUV">Stretch SUV</option>
                    </select>
                  </div>

                  <div className="col-lg-6 mt--30">
                    <div className="row">
                      <div className="col-md-2 col-sm-3 offset-md-1 col-3">
                        <i className="material-icons">person</i>
                      </div>
                      <div className="col-md-2 col-sm-3 col-3 ">{this.state.serviceVehiclePersons}</div>

                      <div className="col-md-2 col-sm-3 offset-md-2 col-3 ">
                        <i className="material-icons">card_travel</i>
                      </div>
                      <div className="col-md-2 col-sm-3 col-3 ">{this.state.serviceVehicleBags}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {serviceTypeRelatedDiv}
                </div>
              </div>
              <div className="col-lg-6" style={{ marginBottom: '10px' }}>
                <MapWithADirectionsRenderer
                  fromlat={this.state.fromlat}
                  fromlng={this.state.fromlng}
                  tolat={this.state.tolat}
                  tolng={this.state.tolng}
                  handleCalculateDistance={this.handleCalculateDistance}
                  suppressMarkers={false}
                  showCircle={false}
                />
              </div>
            </div>
            <div className="row mb--40">
              <div className="col-lg-12">
                <div>
                  {
                    this.state.calculatedPrice[this.state.serviceVehicle] < 0
                      ? <div>
                        Please submit a request to receive quotes.
                      </div>
                      :
                      <div>
                        <h3><b>FINDANIO recommended ride rate: </b> {this.state.rateCalculated ? `$${this.state.calculatedPrice[this.state.serviceVehicle]}` : 'N/A'}</h3>
                        <RideDistance
                          fromlat={this.state.fromlat}
                          fromlng={this.state.fromlng}
                          tolat={this.state.tolat}
                          tolng={this.state.tolng}
                          calculatedDistance={this.state.calculatedDistance}
                        />
                        <p>Duration: {this.state.calculatedRideDuration || 'N/A'}</p>
                      </div>
                  }
                  <RidePrice
                    updateCalculatedPrice={this.updateCalculatedPrice}
                    calculatedDistance={this.state.calculatedDistance}
                    serviceVehicle={this.state.serviceVehicle}
                    serviceType={this.state.serviceType}
                    serviceHours={this.state.serviceHours}
                  />
                </div>
                {this.state.calculatedPrice[this.state.serviceVehicle] !== 0.00
                  && this.state.calculatedPrice[this.state.serviceVehicle] > 0
                  ? (<p className="conditionsApplied">{conditionsAppliedTxt}</p>) : null}
              </div>
            </div>
            {/* <div className="row mt--50 mb--50">
                  <div className="col-lg-12">
                    <label htmlFor="validationCustom01">My Preferred Rate</label>
                    <CurrencyTextField
                      id='requestorPrice'
                      currencySymbol="$"
                      outputFormat="number"
                      textAlign="center"
                      placeholder="0.00"
                      leadingZero="deny"
                      minimumValue="0"
                      value={this.state.requestorPrice}
                      onChange={this.handleTravelerCharges}
                    />
                    <p><span className="error-text">{this.state.requestorPriceError ? "Preferred rate is only allowed upto 10% less than recommended rate $" + (0.90 * this.state.calculatedPrice[this.state.serviceVehicle]) : ""}</span></p>
                  </div>
                </div> */}
            <hr />
            <div className="row">
              <div className="col-12">
                <h3>Step 2: Enter Passenger details</h3>
              </div>
            </div>
            <div className="row mt--30 mb--40">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="passengerFirstName">Passenger First Name<span className="star">*</span></label>
                    <input type="text" id='passengerFirstName' value={this.state.passengerFirstName} onChange={this.handleChange} />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="passengerLastName">Passenger Last Name<span className="star">*</span></label>
                    <input type="text" id='passengerLastName' value={this.state.passengerLastName} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="passengerPhone">Passenger Phone<span className="star">*</span></label>
                    <PhoneInput
                      specialLabel=""
                      id='passengerPhone'
                      lab
                      onlyCountries={['us']}
                      country={'us'}
                      disableCountryCode={true}
                      disableSearchIcon={true}
                      disableDropdown={true}
                      placeholder={'(xxx) xxx-xxxx'}
                      value={this.state.passengerPhone}
                      onChange={this.handlePhoneChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="specialNotes">Special Notes</label>
                    <textarea id="specialNotes" className="materialize-textarea has-character-counter"
                      onChange={this.handleChange}
                      cols={5}
                      data-length="120"
                      maxLength="120"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <h3>Step 3: Select Payment Method<span className="star">*</span></h3>
              </div>
            </div>
            <div className="row mt--30">
              <div className="col-lg-12">
                <SelectPaymentMethod uid={auth.uid}
                  paymentUpdated={this.props.paymentUpdated}
                  selectedPaymentMethod={this.state.selectedPaymentMethod}
                  handleChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row mt--30">
              <div className="col-12">
                <DisclaimerMessages disclaimerMessagesArr={
                  [
                    'A $5.00 pre-authorization charge would apply on your payment method to secure this ride. This will be refunded in 7 business days.',
                    'Cancellation charges might be applicable based on your timing of cancellation and availability of Independent Operators.'
                  ]
                }></DisclaimerMessages>
              </div>
            </div>
            <div className="row mt--30">
              <div className="col-lg-12">
                <button
                  onClick={this.reviewRide}
                  className="rn-button-style--2 btn-solid">Review</button>
              </div>
            </div>
          </div>
          <div id="confirmRideCreationModal" className="modal">
            <div className="modal-content1 modalForm">
              <i className="material-icons modal-close float-right">close</i>

              <h4 className="center "> <b>Ride details</b></h4>
              <div>
                <div className="ml--20">
                  <b className="error-text">From</b>:{this.state.serviceFrom}<br /><hr />
                  <b className="error-text">To</b>:{this.state.serviceTo}<br />  <hr />
                  <b className="error-text">Date</b>: {moment(this.state.serviceDate).format("dddd, MMMM Do, YYYY")}{moment(this.state.serviceDate).format("LT")}<br />  <hr />
                  <b className="error-text">Service Type</b>: {this.state.serviceType}<br />  <hr />
                  <b className="error-text">Vehicle Type</b>: {this.state.serviceVehicle}<br />  <hr />
                  <b className="error-text">Passenger Name</b>: {this.state.passengerFirstName + ' ' + this.state.passengerLastName}<br />  <hr />
                  <b className="error-text">Passenger Phone</b>: {this.state.passengerPhone}<br />  <hr />
                  <b className="error-text">Special Notes</b>: {this.state.specialNotes}<br />  <hr />
                  <b className="error-text">Ride Rate</b>: {
                    this.state.requestorPrice > 0
                      ? "$" + this.state.requestorPrice
                      : this.state.calculatedPrice[this.state.serviceVehicle] < 0
                        ? 'N/A'
                        : "$" + this.state.calculatedPrice[this.state.serviceVehicle]
                  }<br />  <hr />
                  <b className="error-text">Payment Method</b>:  {this.state.selectedPaymentMethodStr.toUpperCase()} <br />  <hr />
                </div>
              </div>

              <div className="center">
                <button className="rn-button-style--2 btn-solid mt--5" onClick={this.handleSubmit}>Submit</button>
                <button className="rn-button-style--2 btn-solid modal-close mt--5 ml--10">Cancel</button>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    rideCreationErr: state.project.rideCreationErr,
    createdRideId: state.project.createdRideId,
    rideCreationStatus: state.project.rideCreationStatus,
    paymentUpdated: state.auth.paymentUpdated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createProject: (project) => dispatch(createProject(project)),
    clearCreatedProjectStatus: () => dispatch(clearCreatedProjectStatus()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookNewRide)
