import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Breadcrumb from "../common/Breadcrumb";
import { searchProfileList, clearSearchResults } from "../../store/actions/authActions";
import SearchResultList from "./SearchResultList";
import Loader1 from '../loader/Loader1';

class AdminSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchType: 'firstName',
            searchText: '',
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    componentDidMount() {
        this.props.clearSearchResults();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        }, (() => {
            this.props.searchProfileList(this.state)
                .then(() => {
                    this.setState({
                        loading: false
                    });
                });
        })
        );
    }

    render() {
        const { auth, searchResultList, searchError } = this.props;
        if (!auth.uid) return <Redirect to='/login' />
        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }
        return (
            <>
                {loader}
                <Breadcrumb title={'Search'} title1={'Search'} parent='Admin' parentLink='/admin' />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                    <div className="main_container">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row mt--30">
                                <div className="col-lg-8">
                                    <label htmlFor="profile"><h4>Select a category for search<span className="star">*</span></h4></label>
                                    <select id='searchType'
                                        onChange={this.handleChange} >
                                        <option value="firstName" defaultValue>
                                            First Name
                                        </option>
                                        <option value="lastName">
                                            Last Name
                                        </option>
                                        <option value="email">
                                            Email
                                        </option>
                                    </select>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 ">
                                    <label htmlFor="searchText"><h4>Enter Search Text<span className="star">*</span></h4></label>
                                    <input id="searchText"
                                        onChange={this.handleChange}
                                        placeholder=""
                                        type="text" />
                                </div>
                                <div className="col-lg-6 order-1 order-lg-1">
                                    <div className="about-inner inner">
                                        <div className="content mb--50">
                                            <button className="rn-button-style--2 btn-solid">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                        <div className="row">
                            <div className="col-lg-12">
                                <SearchResultList searchResultList={searchResultList} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p className="error-text">{searchError}</p>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchResultList: state.auth.searchResultList,
        searchError: state.auth.searchError,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSearchResults: () => dispatch(clearSearchResults()),
        searchProfileList: (data) => dispatch(searchProfileList(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearch)