import React from 'react'
import PasswordMask from 'react-password-mask';
import PhoneInput from 'react-phone-input-2';

class OperatorSignUpStep1 extends React.Component {

  render() {
    if (this.props.currentStep !== 1) { // Prop: The current step
      return null
    }
    return (
      <div className="form-group mt--20">
        <div className="row">
          <div className="col-12" style={{ fontSize: '80%' }}>
            <span className="star">*</span> Required
          </div>
        </div>

        <div className="row mt--20">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="firstName">First Name<span className="star">*</span></label>
            <input type="text" id='firstName' placeholder="Enter your first name" value={this.props.firstName} onChange={this.props.handleChange} />
          </div>
          <div className="col-md-6 col-sm-12">
            <label htmlFor="lastName">Last Name<span className="star">*</span></label>
            <input type="text" id='lastName' placeholder="Enter your last name" value={this.props.lastName} onChange={this.props.handleChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label htmlFor="email">Email<span className="star">*</span></label>
            <input type="email" id='email' placeholder="yours@example.com" value={this.props.email} onChange={this.props.handleChange} />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <label htmlFor="password">Password<span className="star">*</span></label>
            <PasswordMask
              id="password"
              name="password"
              placeholder="Set your Password"
              value={this.props.password}
              onChange={this.props.handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <div>
              <label htmlFor="phoneNumber">Cell Phone<span className="star">*</span></label>
              <PhoneInput
                specialLabel=""
                id='phoneNumber'
                onlyCountries={['us']}
                country={'us'}
                disableCountryCode={true}
                disableSearchIcon={true}
                disableDropdown={true}
                placeholder={'(xxx) xxx-xxxx'}
                value={this.props.phoneNumber}
                onChange={this.props.handlePhoneChange}
              />
            </div>
          </div>

          <div className="col-md-6 col-sm-12 ">
            <div>
              <label htmlFor="phoneNumber">Office Phone</label>
              <PhoneInput
                specialLabel=""
                id='officePhoneNumber'
                onlyCountries={['us']}
                country={'us'}
                disableCountryCode={true}
                disableSearchIcon={true}
                disableDropdown={true}
                placeholder={'(xxx) xxx-xxxx'}
                value={this.props.officePhoneNumber}
                onChange={this.props.handleOfficePhoneChange}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <label htmlFor="companyName">Company Name</label>
            <input type="text" id='companyName' placeholder="Enter your company name" value={this.props.companyName} onChange={this.props.handleChange} />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <label htmlFor="address">Address</label>
            <input type="text" id='address' placeholder="Enter your address" value={this.props.address} onChange={this.props.handleChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <label htmlFor="profileDescription">About you</label>
            <textarea id="profileDescription" className="materialize-textarea has-character-counter"
              placeholder="Enter something about yourself which would be seen by the requestors."
              value={this.props.profileDescription}
              onChange={this.props.handleChange}
              data-length="120"
              maxLength="120"></textarea>
          </div>
        </div>

      </div>
    )
  }
}

export default OperatorSignUpStep1