import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Breadcrumb from "../common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import ViewPaymentMethods from './viewPaymentMethods/ViewPaymentMethods'
import AddPaymentMethod from './addPaymentMethod/AddPaymentMethod'
import ViewPaymentMethodsInPage from './viewPaymentMethods/ViewPaymentMethodsInPage';

class Payment extends Component {

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        return (
            <>
                <Breadcrumb title={'Payment'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-12">
                                <Tabs>
                                    <div className="tablist-inner">
                                        <TabList className="pv-tab-button">
                                            <Tab><span>Add New Payment Method</span></Tab>
                                            <Tab><span>View Payment Methods</span></Tab>
                                        </TabList>
                                    </div>
                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <AddPaymentMethod />
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ViewPaymentMethodsInPage />
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)

