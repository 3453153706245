import React, { Component } from 'react'
import axios from 'axios'

import { baseURL } from "../constants/CommonConstants";

class RidePrice extends Component {

  componentDidUpdate(prevProps) {
    if ((prevProps.calculatedDistance !== this.props.calculatedDistance)
      || (prevProps.serviceType !== this.props.serviceType)
      || (prevProps.serviceHours !== this.props.serviceHours)) {
      if (this.props.serviceType !== '') {
        if (this.props.calculatedDistance <= 0 && this.props.serviceType === 'Point-to-Point') {
          this.props.updateCalculatedPrice({
            Sedan: 0.00,
            LuxurySedan: 0.00,
            SUV: 0.00,
            PassengerVan: 0.00,
            CorporateSprinter: 0.00,
            LimoSprinter: 0.00,
            Bus24Passenger: 0.00,
            Bus34Passenger: 0.00,
            CoachBus: 0.00,
            StretchLimo: 0.00,
            StretchSUV: 0.00
          });
          return;
        } else {
          let data = {
            distance: this.props.calculatedDistance,
            serviceVehicle: this.props.serviceVehicle,
            serviceType: this.props.serviceType,
            serviceHours: this.props.serviceHours
          };
          let url = baseURL + '/calculateRideRate';
          // let url = '/calculateRideRate';
          axios.post(url, data)
            .then(response => {
              // console.log('r=',response.data)
              this.props.updateCalculatedPrice(response.data);
            })
            .catch(err => {
              this.props.updateCalculatedPrice({
                error: true
              });
            })
        }
      }
    } else if ((prevProps.calculatedDistance === this.props.calculatedDistance)
      && (prevProps.serviceType === this.props.serviceType)
      && (prevProps.serviceHours === this.props.serviceHours)
      && (prevProps.serviceVehicle !== this.props.serviceVehicle)) {
      if (this.props.serviceType === 'HourlyCharter') {
        //get rate for hourlyCharter if serviceVehicle changes
        let data = {
          distance: this.props.calculatedDistance,
          serviceVehicle: this.props.serviceVehicle,
          serviceType: this.props.serviceType,
          serviceHours: this.props.serviceHours
        };
        let url = baseURL + '/calculateRideRate';
        // let url = '/calculateRideRate';
        axios.post(url, data)
          .then(response => {
            this.props.updateCalculatedPrice(response.data);
          })
          .catch(err => {
            this.props.updateCalculatedPrice({
              error: true
            });
          })
      } else {
        this.props.updateCalculatedPrice({ refresh: true });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default RidePrice
