import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
// import { toggleLoggedInOperatorMode } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom'

class Breadcrumb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
        // this.toggleOperatorMode = this.toggleOperatorMode.bind(this);
    }

    // toggleOperatorMode() {
    //     this.props.toggleLoggedInOperatorMode();
    //     this.setState({
    //         redirect: true
    //     })
    // }

    render() {
        const { title, parent, title1, parentLink } = this.props;
        // let toggleOperatorModeSection = null;
        // if (loggedInOperatorMode !== null) {
        //     if (loggedInOperatorMode === 'TravelerMode') {
        //         toggleOperatorModeSection = <button className="mt--20 rn-button-style--2 btn-primary-color" onClick={this.toggleOperatorMode}>Switch to Operator Mode</button>
        //     } else {
        //         toggleOperatorModeSection = <button className="mt--20 rn-button-style--2 btn-primary-color" onClick={this.toggleOperatorMode}>Switch to Traveler Mode</button>
        //     }
        // }

        if (this.state.redirect) {
            return <Redirect to='/home' />
        } else return (
            <React.Fragment>
                <div className="breadcrumb-area rn-bg-color pt--100 pb--50 bg_image bg_image--1" data-black-overlay="6">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h2 className="title">{title1 || title}</h2>
                                <ul className="page-list">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    {parentLink ?
                                        <li className="breadcrumb-item"><Link to={parentLink}>{parent}</Link></li>
                                        : parent ?
                                            <li className="breadcrumb-item">{parent}</li>
                                            : ''
                                    }
                                    {title ? <li className="breadcrumb-item active">{title}</li> : ''}
                                </ul>
                                {/* {toggleOperatorModeSection} */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // loggedInOperatorMode: state.auth.loggedInOperatorMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // toggleLoggedInOperatorMode: () => dispatch(toggleLoggedInOperatorMode()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
