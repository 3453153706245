import React from 'react'
import Breadcrumb from "../common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SAASAgreement from "./SAASAgreement";
import ServiceOverview from "./ServiceOverview";
import ServiceAgreement from "./ServiceAgreement";

const TNC = (props) => {
    let
        tab1 = "SAAS Agreement",
        tab2 = "Service Overview",
        tab3 = "User of Independent Operator Service Agreement";
    return (
        <>
            <Breadcrumb title={'Terms and Conditions'} />

            <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">

                <div className="section-title text-left mb--50">
                    <p className="description">Please read carefully, by using our services you accept FINDANIO Terms of Service. If you do not agree with our Terms of Service stated below please do not use our services. FINDANIO                       reserves the right to modify, alter or update these terms of service at any time.</p>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabList className="tab-style--1">
                                <Tab>{tab1}</Tab>
                                <Tab>{tab2}</Tab>
                                <Tab>{tab3}</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="single-tab-content mt--20">
                                    <SAASAgreement />
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="single-tab-content mt--20">
                                    <ServiceOverview />
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="single-tab-content mt--20">
                                    <ServiceAgreement />
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </main>
        </>
    )
}

export default TNC
