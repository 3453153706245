import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import NewRidesData from '../../rides/operator/NewRidesData'
import OperatorUpcomingRides from '../../rides/operator/OperatorUpcomingRides'
import OperatorHistoryRidesData from '../../rides/operator/OperatorHistoryRidesData'
import AcceptedPendingTravelerApprovalRidesData from './AcceptedPendingTravelerApprovalRidesData'
import Breadcrumb from "../../common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader1 from '../../loader/Loader1'

class OperatorRides extends Component {

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (!profile.isEmpty && profile.accountType !== 'Operator') {
            return <Redirect to='/404' />
        }

        if (!profile.isEmpty) {
            if (profile.approved) {
                return (
                    <>
                        <Breadcrumb title='Rides' />
                        <main className="page-wrapper contact-form--1 rn-contact-form-area pt--20 pb--40 bg_color--5">
                            <div className="container">
                                <div className="row row--35 align-items-start mt--20">
                                    <div className="col-12">
                                        <Tabs className="mb--40">
                                            <div className="tablist-inner">
                                                <TabList className="pv-tab-button">
                                                    <Tab><span>Confirmed Rides</span></Tab>
                                                    <Tab><span>New Rides</span></Tab>
                                                    <Tab><span>Pending Approval</span></Tab>
                                                    <Tab><span>Past Rides</span></Tab>
                                                </TabList>
                                            </div>
                                            <TabPanel>
                                                <div className="single-tab-content mt--20">
                                                    <OperatorUpcomingRides />
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="single-tab-content mt--20">
                                                    <NewRidesData />
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="single-tab-content mt--20">
                                                    <AcceptedPendingTravelerApprovalRidesData />
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="single-tab-content mt--20">
                                                    <OperatorHistoryRidesData />
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )
            } else {
                return (
                    <>
                        <Breadcrumb title='Rides' />
                        <main className="contact-form--1 main_container mt--80 mb--80 bg_color--1">
                            <div className="section-title text-left mb--50">
                                <h3 className="description error-text center">Please wait for your account to be reviewed.</h3>
                            </div>
                        </main>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Breadcrumb title='Rides' />
                    <main className="contact-form--1 main_container mt--80 mb--80 bg_color--1">
                        <div className="section-title text-left mb--50">
                            <p className="description">Loading</p>
                            <Loader1 />
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(OperatorRides)
