import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader1 from '../loader/Loader1';
import M from "materialize-css";
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
import { getOperatorProfile, confirmApproveOperator } from "../../store/actions/authActions";
import { StorageBaseURL, altMedia } from "../constants/CommonConstants"

class SearchOperatorViewProfile extends Component {

    componentDidMount() {
        this.props.getOperatorProfile(this.props.operatorId)
    }

    confirmApproveOperator = () => {
        this.props.confirmApproveOperator(this.props.operatorId)
    }

    approveOperator = () => {
        const elems = document.getElementById('approvedPendingModal');
        M.Modal.init(elems, {});
        M.Modal.getInstance(elems).open();
    }

    denyOperatorReason = () => {
        const elems = document.getElementById('denyPendingOperator');
        M.Modal.init(elems, {});
        M.Modal.getInstance(elems).open();
    }

    render() {
        const { auth, errorMsg, errorMsg1 } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        let profile = this.props.userProfile;

        if (profile && profile.uid !== '') {
            let updatedProfileImageUrl = profile.profileImageUrl ? StorageBaseURL + encodeURIComponent(profile.uid + '/profile/resized_' + profile.profileImageUrl) + altMedia : null;
            let updatedLicenseUrl = null;
            let updatedInsuranceUrl = null;
            let updatedVehicleImageUrl = null;
            let updatedVehicleRegistrationImageUrl = null;

            if (profile.accountType === 'Operator') {
                updatedLicenseUrl = profile.licenseImageUrl ? StorageBaseURL + encodeURIComponent(profile.uid + '/license/resized_' + profile.licenseImageUrl) + altMedia : null;
                updatedInsuranceUrl = profile.insuranceImageUrl ? StorageBaseURL + encodeURIComponent(profile.uid + '/insurance/resized_' + profile.insuranceImageUrl) + altMedia : null;
                updatedVehicleImageUrl = profile.vehicleImageUrl ? StorageBaseURL + encodeURIComponent(profile.uid + '/vehicle/resized_' + profile.vehicleImageUrl) + altMedia : null;
                updatedVehicleRegistrationImageUrl = profile.vehicleRegistrationImageUrl ? StorageBaseURL + encodeURIComponent(profile.uid + '/vehicleRegistration/resized_' + profile.vehicleRegistrationImageUrl) + altMedia : null;
            }

            const operatorProfile = profile.accountType === 'Operator' ? (
                <>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="companyName">Company Name</label>
                            <input type="text" disabled
                                id='companyName' value={profile.companyName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="profileDescription">About you</label>
                            <textarea id='profileDescription' className="materialize-textarea has-character-counter"
                                disabled
                                placeholder="Enter something about yourself which would be seen by the requestors."
                                value={profile.profileDescription}
                                data-length="120"
                                maxLength="120"></textarea>
                        </div>
                    </div>
                    <h2 className="title">Preferences</h2>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you want to receive text messages?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="receiveTexts" disabled type="radio" value="Yes"
                                    checked={profile.receiveTexts === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="receiveTexts" disabled type="radio" value="No"
                                    checked={profile.receiveTexts === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you want to receive email communications?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="receiveEmails" disabled type="radio" value="Yes"
                                    checked={profile.receiveEmails === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="receiveEmails" disabled type="radio" value="No"
                                    checked={profile.receiveEmails === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Pet Friendly?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="petFriendly" disabled type="radio" value="Yes"
                                    checked={profile.petFriendly === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="petFriendly" disabled type="radio" value="No"
                                    checked={profile.petFriendly === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you sanitize vehicle after each passenger?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q1" disabled type="radio" value="Yes"
                                    checked={profile.q1 === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q1" disabled type="radio" value="No"
                                    checked={profile.q1 === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you provide complimentary water for passengers?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q2" disabled type="radio" value="Yes"
                                    checked={profile.q2 === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q2" disabled type="radio" value="No"
                                    checked={profile.q2 === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you provide hand sanitizer for passengers?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q3" disabled type="radio" value="Yes"
                                    checked={profile.q3 === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q3" disabled type="radio" value="No"
                                    checked={profile.q3 === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you have a sneeze guards divider shield in your car?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q4" disabled type="radio" value="Yes"
                                    checked={profile.q4 === "Yes"}
                                />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q4" disabled type="radio" value="No"
                                    checked={profile.q4 === "No"}
                                />
                                <span>No</span>
                            </span>
                        </div>
                    </div>
                    <br />
                    <h2 className="title">Vehicle details</h2>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carPlate">License Plate</label>
                            <input type="text" id='carPlate' disabled
                                value={profile.carPlate} />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="vehicleVIN">Vehicle VIN</label>
                            <input type="text" id='vehicleVIN' disabled
                                value={profile.vehicleVIN} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carYear">Year</label>
                            <input type="text" disabled
                                id='carYear' value={profile.carYear} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carMake">Make</label>
                            <input type="text" disabled
                                id='carMake' value={profile.carMake} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carModel">Model</label>
                            <input type="text" disabled
                                id='carModel' value={profile.carModel} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carColor">Color</label>
                            <input type="text" disabled
                                id='carColor' value={profile.carColor} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="otherColor">Other Color</label>
                            <input type="text" disabled
                                id='otherColor' value={profile.otherColor} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carType">Vehicle Type</label>
                            <input type="text" disabled
                                id='carType' value={profile.carType} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="state">State</label>
                            <input type="text" disabled
                                id='state' value={profile.state} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="airports">Airports</label>
                            <input type="text" disabled
                                id='airports' value={profile.airports} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="liabilityInsuranceAmt">Liability Insurance Amount</label>
                            <input type="text" disabled
                                id='liabilityInsuranceAmt' value={profile.liabilityInsuranceAmt} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="cancellationPolicy">Cancellation Policy</label>
                            <input type="text" disabled
                                id='cancellationPolicy' value={profile.cancellationPolicy} />
                        </div>
                    </div>
                </>
            ) : null;

            const ImageContent = [
                {
                    images: updatedProfileImageUrl || 'http://via.placeholder.com/200x200',
                    title: 'Profile',
                    category: 'Profile'
                },
                {
                    images: updatedVehicleImageUrl || 'http://via.placeholder.com/200x200',
                    title: 'Vehicle (with plate)',
                    category: 'Vehicle'
                },

                {
                    images: updatedLicenseUrl || 'http://via.placeholder.com/200x200',
                    title: 'Drivers License',
                    category: 'License'
                },
                {
                    images: updatedInsuranceUrl || 'http://via.placeholder.com/200x200',
                    title: 'Vehicle proof of insurance',
                    category: 'Insurance'
                },
                {
                    images: updatedVehicleRegistrationImageUrl || 'http://via.placeholder.com/200x200',
                    title: 'Vehicle Registration',
                    category: 'VehicleRegistration'
                },
            ];

            const ImagesList = profile.accountType === 'Traveler' ? ImageContent.slice(0, 1) : ImageContent.slice(0, 5)
            let docs = ImagesList.map((value, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--10" key={i} >
                    <h4 className="title"><span>{value.title}</span></h4>
                    <img width="500" height="500" src={value.images} alt={value.title} />
                </div>
            ))

            return (
                <>
                    <Breadcrumb title={'Profile'} title1={'Profile'} parent='Search' parentLink='/admin/adminSearch' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mt--10">
                                        <h3 className="title">Personal details
                                        </h3>
                                    </div>
                                    <div className="row ptb--20">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label htmlFor="firstName">First Name</label>
                                                    <input type="text" disabled
                                                        id='firstName' value={profile.firstName} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="lastName">Last Name</label>
                                                    <input type="text" disabled
                                                        id='lastName' value={profile.lastName} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="email" disabled id='email' value={profile.email} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <PhoneInput
                                                        specialLabel="Cell Phone"
                                                        id='phoneNumber'
                                                        disabled={true}
                                                        onlyCountries={['us']}
                                                        country={'us'}
                                                        disableCountryCode={true}
                                                        disableSearchIcon={true}
                                                        disableDropdown={true}
                                                        placeholder={'(xxx) xxx-xxxx'}
                                                        value={profile.phoneNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <PhoneInput
                                                        specialLabel="Office Phone"
                                                        id='officePhoneNumber'
                                                        disabled={true}
                                                        onlyCountries={['us']}
                                                        country={'us'}
                                                        disableCountryCode={true}
                                                        disableSearchIcon={true}
                                                        disableDropdown={true}
                                                        placeholder={'(xxx) xxx-xxxx'}
                                                        value={profile.officePhoneNumber}
                                                    />
                                                </div>
                                            </div>
                                            {operatorProfile ? operatorProfile : null}
                                        </div>
                                    </div>
                                    <hr />
                                    <h3
                                        className="title mt--10">Uploaded Documents</h3>
                                    <div className="row mt--10" >
                                        {docs ? docs : null}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </main>
                </>
            )
        } else if (errorMsg) {
            return (
                <>
                    <Breadcrumb title={'Operator Profile'} title1={'Operator Profile'} parent='Admin' parentLink='/admin' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <p className="description">{errorMsg}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else if (errorMsg1) {
            return (
                <>
                    <Breadcrumb title={'Operator Profile'} title1={'Operator Profile'} parent='Admin' parentLink='/admin' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <p className="description">{errorMsg1}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title={'Operator Profile'} title1={'Operator Profile'} parent='Admin' parentLink='/admin' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <p className="description">Loading</p>
                                        <Loader1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        errorMsg: state.auth.err,
        errorMsg1: state.auth.err1,
        operatorId: id,
        userProfile: state.auth.userProfile

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOperatorProfile: (id) => dispatch(getOperatorProfile(id)),
        confirmApproveOperator: (id) => dispatch(confirmApproveOperator(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchOperatorViewProfile)