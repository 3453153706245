import React, { Component } from "react";
import Breadcrumb from "../common/Breadcrumb";
import { FiCheck } from "react-icons/fi";
import ModalVideo from 'react-modal-video';

const namesItemOne = [
    'Single-car independent operators',
    'Small, mid-size, and large transportation companies looking for work',
    'Affiliates',
    'Service providers',
];

const namesItemTwo = [
    'Hotels & airlines',
    'Companies & corporate travelers',
    'Event planners & travel agents',
    'Admin & executive assistants',
    'Transportation companies looking to get rides covered',
];

class AboutUs extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <>
                <Breadcrumb title={'About Us'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About Us</h2>
                                        <p className="description justify">
                                            FINDANIO is a subscription-based online platform of independent operators providing best-in-class transportation service. Need a ride? Submit a request and select your preferred chauffeur from a list of available operators based on your preferences. Think of us as your chauffeur matchmaker!
                                        </p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Operators:</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li className="justify" key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Travelers:</h4>
                                        <ul className="list-style--1">
                                            {namesItemTwo.map((name, index) => {
                                                return <li key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/aboutlogo.png" alt="About Images" />
                                    <ModalVideo channel='custom' isOpen={this.state.isOpen} url="/video/vid1.mp4" onClose={() => this.setState({ isOpen: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default AboutUs
