import React from 'react'
const { compose, withProps, lifecycle } = require("recompose");
const {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  // Marker,
  Circle
} = require("react-google-maps");

// const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
// const google = window.google;

const defaultMapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
}

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDy_1Y_wlsuiAN90bpIW8xkbpxgp990hv4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: new window.google.maps.LatLng(37.0902, -95.7129),
    zoom: 3,
    options: defaultMapOptions
  }),
  withGoogleMap,
  lifecycle({

    componentDidMount() {
      if (this.props.fromlat !== "" && this.props.fromlng !== "" &&
        this.props.tolat !== "" && this.props.tolng !== "") {
        const google = window.google;
        const DirectionsService = new google.maps.DirectionsService();
        DirectionsService.route({
          origin: new google.maps.LatLng(this.props.fromlat, this.props.fromlng),
          destination: new google.maps.LatLng(this.props.tolat, this.props.tolng),
          travelMode: google.maps.TravelMode.DRIVING,
        }, (result, status) => {
          // console.log('status1 = ', status, result);
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result,
              zoom: 6,
              center: new window.google.maps.LatLng(this.props.fromlat, this.props.fromlng),
            });
            let calculatedDistanceInMiles = result.routes[0].legs[0].distance.value * 0.000621371192;
            let calculatedRideDuration = result.routes[0].legs[0].duration.text;
            this.props.handleCalculateDistance && this.props.handleCalculateDistance(calculatedDistanceInMiles, calculatedRideDuration);
          }
        });
      }
    },

    componentDidUpdate(prevProps) {
      const { fromlat, fromlng, tolat, tolng } = this.props;
      if (prevProps.fromlat !== fromlat || prevProps.fromlng !== fromlng ||
        prevProps.tolat !== tolat || prevProps.tolng !== tolng) {
        if (this.props.fromlat !== "" && this.props.fromlng !== "" &&
          this.props.tolat !== "" && this.props.tolng !== "") {
          const google = window.google;
          const DirectionsService = new google.maps.DirectionsService();
          DirectionsService.route({
            origin: new google.maps.LatLng(this.props.fromlat, this.props.fromlng),
            destination: new google.maps.LatLng(this.props.tolat, this.props.tolng),
            travelMode: google.maps.TravelMode.DRIVING,
          }, (result, status) => {
            // console.log('status2 = ', status, result);
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result,
                zoom: 6
              });
              let calculatedDistanceInMiles = result.routes[0].legs[0].distance.value * 0.000621371192;
              let calculatedRideDuration = result.routes[0].legs[0].duration.text;
              this.props.handleCalculateDistance && this.props.handleCalculateDistance(calculatedDistanceInMiles, calculatedRideDuration);
            }
          });
        }
      }
    },
  })
)((props) => {
  if (props.hidden) {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
  else {
    return (
      <React.Fragment>
        <GoogleMap
          zoom={props.zoom}
          center={props.center}
          defaultOptions={props.defaultMapConfig}
          options={props.options}
        >
          {/* <MarkerWithLabel
            position={{ lat: props.fromlat, lng: props.fromlng }}
            labelAnchor={new google.maps.Point(0, 0)}

            labelStyle={{ backgroundColor: "red", fontSize: "32px", padding: "16px" }}
          >
            <div>Hello There!</div>
          </MarkerWithLabel> */}
          {/* 
          <MarkerWithLabel
            position={{ lat: props.tolat, lng: props.tolng }}
            labelStyle={{ backgroundColor: "green", fontSize: "16px", padding: "2px" }}
          >
            <div>Start</div>
          </MarkerWithLabel> */}
          {/* <Marker
            title="from"
            position={{
              lat: props.fromlat,
              lng: props.fromlng
            }}
          /> */}


          {
            props.fromlat && props.fromlng && props.showCircle &&
            <Circle
              defaultCenter={{
                lat: props.fromlat,
                lng: props.fromlng,
              }}
              options={{
                strokeColor: "#ff0000"
              }}
              radius={1000}
            />
          }

          {
            props.tolat && props.tolng && props.showCircle &&
            <Circle
              defaultCenter={{
                lat: props.tolat,
                lng: props.tolng
              }}
              radius={1000}
              options={{
                strokeColor: "green"
              }}
            />
          }
          {props.directions && <DirectionsRenderer directions={props.directions} options={{ suppressMarkers: props.suppressMarkers }} />}
        </GoogleMap>

      </React.Fragment>
    )
  }
}
);

export default MapWithADirectionsRenderer