import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Breadcrumb from "../common/Breadcrumb";
import { FiCheck, FiUsers, FiLogIn } from "react-icons/fi";
import ModalVideo from 'react-modal-video';

const namesItemTwo = [

    'Increase revenue & profits by collecting more per ride when eliminating the middleman',
    'Affordable and flexible subscription plan with unlimited visibility to other affiliates and clients worldwide',
    'Grow your business by accepting more work, building professional relationships with clients, and expanding your network of affiliates',
    'Set your own schedule and know in advance what you have for the next day, week, or month',
    'Marketing though the platform allows for a worldwide presence',
    'Convenient access through your mobile device, computer, or tablet'
];

const namesItemThree = [
    'Our IO model will cut your overhead costs (you can potentially save 60% to 80% on vehicle/wage expenses) & increase profit by going directly to an IO',
    'Access to a larger supply of vehicles worldwide at a lower cost',
    'Cover more on - demand rides worldwide',
    'Get chauffeur and vehicle details instantly without having to wait or ask',
    'Request multiple vehicles at the same time, unlike our competitors',
    'With the preferred chauffeur option, you can build your own fleet of operators',
    'Quickly find a vehicle in any city at any time',
    'Never have to refuse jobs due to shortage of chauffeurs / vehicles',
    'Convenient access through your mobile device, computer, or tablet'
];

class Operators extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen1: false,
            isOpen2: false,
        }
        this.openModal1 = this.openModal1.bind(this);
        this.openModal2 = this.openModal2.bind(this);
    }

    openModal1() {
        this.setState({ isOpen1: true })
    }

    openModal2() {
        this.setState({ isOpen2: true })
    }

    linkScrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        const { auth, profile } = this.props;
        if (auth.uid) {
            if (profile && !profile.isEmpty) {
                if (profile.accountType === 'Traveler') {
                    return <Redirect to='/home' />
                } else {
                    return <Redirect to='/home' />
                }
            }
        }

        return (
            <>
                <Breadcrumb title={'Operators'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Looking for more work? </h2>
                                        <p className="description justify">
                                            Join FINDANIO as independent operators to grow your business & build your clientele.
                                            Sign Up and become visible to travelers and transportation companies worldwide.
                                            Transportation Companies -  build a fleet of preferred chauffeurs and cover more rides.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            <div className="col-lg-6 order-1 order-lg-1">
                                <h4>Benefits for Independent Operators</h4>
                                <ul className="list-style--1">
                                    {namesItemTwo.map((name, index) => {
                                        return <li className="justify" key={index}><FiCheck /> {name}</li>;
                                    })}
                                </ul>
                            </div>
                            <div className="col-lg-6 order-2 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/img/mainInSuite.jpg" alt="Operator" />
                                    <ModalVideo channel='custom' isOpen={this.state.isOpen1} url="/video/vid3.mp4" onClose={() => this.setState({ isOpen1: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal1}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            <div className="col-lg-6 order-lg-2 order-md-2 order-sm-1">
                                <h4>Benefits for Transportation Companies</h4>
                                <ul className="list-style--1">
                                    {namesItemThree.map((name, index) => {
                                        return <li className="justify" key={index}><FiCheck /> {name}</li>;
                                    })}
                                </ul>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-md-1 order-sm-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/img/carwheel.jpg" alt="Transport Company" />
                                    <ModalVideo channel='custom' isOpen={this.state.isOpen2} url="/video/vid4.mp4" onClose={() => this.setState({ isOpen2: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal2}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-2">
                            </div>
                            <div className="col-lg-4 order-2 order-lg-2">
                                <NavLink className="text-center" onClick={this.linkScrollToTop} to='/signup/operator'>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            <FiUsers />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Sign Up</h3>
                                            <p>Create an account and get started</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-4 order-2 order-lg-2">
                                <NavLink className="text-center" onClick={this.linkScrollToTop} to='/login'>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            <FiLogIn />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Login</h3>
                                            {/* <p>Login now</p> */}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row mt--30 bg_color--5">
                            <div className="col-lg-12 mt--20">
                                <div className="about-inner inner">
                                    <div className="section-title center">
                                        <h2 className="title">Pricing Plan</h2>
                                        <p className="description">
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-3 col-12 mb--20">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            {/* <h4 className="title">Premium</h4> */}
                                            <div className="pricing">
                                                <span className="price">$49</span>
                                                <span className="subtitle">Per Month</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="pricing-body">
                                                    <ul className="list-style--1">
                                                        <li><FiCheck />Accept and fulfill unlimited rides</li>
                                                        <li><FiCheck />Be visible to travelers</li>
                                                        <li><FiCheck />Provide your own rate for rides and extra/additional stops</li>
                                                        <li><FiCheck />Become a preferred operator</li>
                                                        <li><FiCheck />View your rides history</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="pricing-body">
                                                    <ul className="list-style--1">
                                                        <li><FiCheck />Instant quotes</li>
                                                        <li><FiCheck />On-demand and future bookings</li>
                                                        {/* <li><FiCheck />Submit unlimited ride requests without paying ride booking fee</li> */}
                                                        <li><FiCheck />Provide your preferred ride rate</li>
                                                        {/* <li><FiCheck />Select an operator of choice for your ride</li> */}
                                                        <li><FiCheck />Easily manage your passengers for frequent booking</li>
                                                        <li><FiCheck />Modify or cancel subscription anytime</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Operators)
