import React, { Component } from 'react'
import Breadcrumb from "../common/Breadcrumb";
import { NavLink } from 'react-router-dom';
import { HiOutlineBriefcase } from "react-icons/hi";
import { IoCarOutline } from 'react-icons/io5';

class QuickGuide extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <Breadcrumb title={'Quick Guide'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="row service-one-wrapper">
                                    <div className="col-lg-6 order-1 order-lg-1">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/quickGuide/traveler'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <HiOutlineBriefcase />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Traveler</h3>
                                                        <p>Guide for Traveler</p>

                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 order-2 order-lg-2">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/quickGuide/operator'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <IoCarOutline />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Operator</h3>
                                                        <p>Guide for Operators</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default QuickGuide