import React, { Component } from 'react'
import axios from 'axios'

import { baseURL } from "../constants/CommonConstants";

class RidePrices extends Component {

  componentDidUpdate(prevProps) {
    if ((prevProps.calculatedDistance !== this.props.calculatedDistance) || (prevProps.serviceType !== this.props.serviceType)) {
      if (this.props.serviceType !== '' && this.props.serviceType === 'HourlyCharter') {
        //hourly rates have fixed base rate
        this.props.handleUpdateRates({
          rateCalculated: true,
          error: false
        });
        return;
      } else if (this.props.serviceType !== '' && this.props.serviceType === 'Point-to-Point') {
        let finalDist = this.props.calculatedDistance;
        if (finalDist < 0) {
          this.props.handleUpdateRates({
            Sedan: 0.00,
            LuxurySedan: 0.00,
            SUV: 0.00,
            PassengerVan: 0.00,
            CorporateSprinter: 0.00,
            LimoSprinter: 0.00,
            Bus24Passenger: 0.00,
            Bus34Passenger: 0.00,
            CoachBus: 0.00,
            StretchLimo: 0.00,
            StretchSUV: 0.00,
            rateCalculated: false,
            error: false
          });
          return;
        } else if (finalDist === 0) {
          this.props.handleUpdateRates({
            Sedan: 0.00,
            LuxurySedan: 0.00,
            SUV: 0.00,
            PassengerVan: 0.00,
            CorporateSprinter: 0.00,
            LimoSprinter: 0.00,
            Bus24Passenger: 0.00,
            Bus34Passenger: 0.00,
            CoachBus: 0.00,
            StretchLimo: 0.00,
            StretchSUV: 0.00,
            rateCalculated: false,
            error: true
          });
          return;
        } else {
          //get pt-2-pt rates
          let data = {
            distance: finalDist,
            serviceType: this.props.serviceType,
          };
          let url = baseURL + '/calculateRideRate';
          // let url = '/calculateRideRate';
          axios.post(url, data)
            .then(response => {
              // console.log('response  -', response.data)
              this.props.handleUpdateRates({
                ...response.data,
                rateCalculated: true,
                error: false
              });
              return;
            })
            .catch(err => {
              this.props.handleUpdateRates({
                Sedan: 0.00,
                LuxurySedan: 0.00,
                SUV: 0.00,
                PassengerVan: 0.00,
                CorporateSprinter: 0.00,
                LimoSprinter: 0.00,
                Bus24Passenger: 0.00,
                Bus34Passenger: 0.00,
                CoachBus: 0.00,
                StretchLimo: 0.00,
                StretchSUV: 0.00,
                rateCalculated: false,
                error: true
              });
              return;
            })
        }
      }
    }
  }

  render() {
    return (<React.Fragment></React.Fragment>)
  }
}

export default RidePrices
