import React from 'react'
import M from "materialize-css";
import Breadcrumb from "../common/Breadcrumb";
import { sendContactUsEmail, clearContactErrorAction } from '../../store/actions/authActions'
import { connect } from 'react-redux';
import { FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import ErrorMessage from "../errorMessage/ErrorMessage"

class ContactUs extends React.Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    }

    componentDidMount() {
        this.props.clearContactErrorAction();
        let charCounter = document.querySelectorAll('.has-character-counter');
        M.CharacterCounter.init(charCounter);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    back = (e) => {
        this.props.clearContactErrorAction();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.sendContactUsEmail({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
        });
    }

    render() {
        const { contactSuccess, contactError } = this.props;
        if (contactSuccess) {
            return (
                <>
                    <Breadcrumb title={'Contact Us'} />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-6 order-1 order-lg-1">
                                <div className="section-title text-left mb--50">
                                    <h2 className="title ml--10">Contact Us</h2>
                                    <br/>
                                    <p className="description ml--10">Thank you for contacting us, we have received your message!</p>
                                </div>
                                <br />
                                <div>
                                    <button
                                        onClick={this.back}
                                        className='rn-button-style--2 btn-solid'>Back</button>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title={'Contact Us'} />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area bg_color--1">

                        {/* Start Contact Top Area  */}
                        <div className="rn-contact-top-area ptb--60 bg_color--5">
                            <div className="container">

                                <div className="row">
                                    {/* Start Single Address  */}
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiPhoneCall />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Phone Number</h4>
                                                <p><a href="tel:800-664-5630">800-664-5630</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Address  */}

                                    {/* Start Single Address  */}
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiMail />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Email</h4>
                                                <p><a href="mailto:info@findanio.com">info@findanio.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Address  */}

                                    {/* Start Single Address  */}
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiMapPin />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Location</h4>
                                                <p>Chicago, IL 60611</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Address  */}

                                </div>
                            </div>
                        </div>
                        {/* End Contact Top Area  */}


                        <div className="container ptb--40">
                            <div className="row row--35">
                                <div className="col-lg-8 order-1 order-lg-1">
                                    <div className="section-title text-left mb--50">
                                        <h2 className="title ml--30">Contact Us</h2>
                                        <p className="description ml--40">We want to hear from you!</p>
                                    </div>
                                    <div className="ml--20">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="col-md-12">
                                                <label htmlFor="firstName">First Name<span className="star">*</span></label>
                                                <input type="text" id='firstName' value={this.state.firstName} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="lastName">Last Name<span className="star">*</span></label>
                                                <input type="text" id='lastName' value={this.state.lastName} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="email">Email<span className="star">*</span></label>
                                                <input type="email" value={this.state.email} id='email' onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="phone">Phone<span className="star">*</span></label>
                                                <input type="text" id='phone' value={this.state.phone} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="message">Message<span className="star">*</span></label>
                                                <textarea id="message" className="materialize-textarea has-character-counter"
                                                    onChange={this.handleChange}
                                                    value={this.state.message}
                                                    style={{ backgroundColor: '#fff' }}
                                                    data-length="250"
                                                    maxLength="250"></textarea>
                                            </div>
                                            <div className="col-md-12 center">
                                                <button
                                                    disabled={this.state.disabled}
                                                    className='rn-button-style--2 btn-solid'>Submit</button>
                                            </div>
                                            <br />
                                            {contactError ? <ErrorMessage errorMessageText={contactError}></ErrorMessage> : null}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.auth.contactSuccess,
        contactError: state.auth.contactError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendContactUsEmail: (obj) => dispatch(sendContactUsEmail(obj)),
        clearContactErrorAction: () => dispatch(clearContactErrorAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
