import React, { Component } from "react";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
// import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { NavLink } from 'react-router-dom'

const SocialShare = [
    // { Social: <FaFacebookF />, link: 'https://www.facebook.com/FINDANIO-111892444457232' },
    // { Social: <FaInstagram />, link: 'https://www.instagram.com/findanio/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/findanio' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/findanio/about/' },
]

class Footer extends Component {


    constructor(props) {
        super(props);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    }

    CLoseMenuTrigger() {
        window.scrollTo(0, 0);
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    footerLinkClicked() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <footer className="footer-area footer-right" data-black-overlay="6">
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-6 footer-link">
                        <ul className="ft-link">
                            <li><NavLink to='/about' onClick={this.CLoseMenuTrigger}>About Us</NavLink></li>
                            <li><NavLink to='/faqs' onClick={this.CLoseMenuTrigger}>FAQs</NavLink></li>
                            <li><NavLink to='/pricing' onClick={this.CLoseMenuTrigger}>Pricing</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-6 footer-link">
                        <div className="footer-link">
                            <ul className="ft-link">
                                <li><NavLink to='/locations' onClick={this.CLoseMenuTrigger}>Locations</NavLink></li>
                                <li><NavLink to='/quickGuide' onClick={this.CLoseMenuTrigger}>Quick Guide</NavLink></li>
                                <li><NavLink to='/contact-us' onClick={this.CLoseMenuTrigger}>Contact Us</NavLink></li>
                                <li><NavLink to='/marketingSubscription' onClick={this.CLoseMenuTrigger}>Marketing Subscription</NavLink></li>

                            </ul>
                        </div>
                    </div>
                </div>


                <div className="col-lg-12 ">
                    <div className="social-share-inner">
                        <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--20">
                            {SocialShare.map((val, i) => (
                                <li className="center" key={i}><a rel="noopener noreferrer" target='_blank' href={`${val.link}`}>{val.Social}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="row center">
                    <div className="copyright-text center">
                        <p>Copyright © {new Date().getFullYear()} FINDANIO&trade;. All rights reserved. <NavLink to='/privacy' onClick={this.footerLinkClicked}>Privacy</NavLink> | <NavLink to='/tnc' onClick={this.footerLinkClicked}>Terms and Conditions</NavLink></p>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;