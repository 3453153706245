import React, { Component } from 'react'
import ReactStars from "react-rating-stars-component";
import { StorageBaseURL, altMedia } from "../../constants/CommonConstants"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

class OperatorProfile extends Component {

    render() {
        const { project, operatorId, operatorProfile, selectedOperator, confirmedByOperatorId, operatorData } = this.props;
        // let preferredOperator = this.props.preferredOperator;
        if (operatorProfile) {
            if (operatorProfile === 'BadData' || operatorProfile.accountType === 'Traveler') {
                return (
                    <div>Something went wrong.</div>
                )
            } else {

                var updatedProfileImageUrl = operatorProfile.profileImageUrl ? StorageBaseURL + encodeURIComponent(operatorId + '/profile/thumb_' + operatorProfile.profileImageUrl) + altMedia : null;
                let selectedOperatorStatus = null;
                let buttonElement = null;
                let confirmedRides = (operatorProfile.confirmedRides && operatorProfile.confirmedRides.length) || 0;
                let acceptedRides = (operatorProfile.acceptedRides && operatorProfile.acceptedRides.length) || 0;
                let cancelledConfirmedRides = (operatorProfile.cancelledConfirmedRides && operatorProfile.cancelledConfirmedRides.length) || 0;
                let cancellationPolicy = operatorProfile.cancellationPolicy || 'Not Specified';

                if (project && project.cancelledConfirmedRideBy && project.cancelledConfirmedRideBy.includes(operatorId)) {
                    selectedOperatorStatus = (
                        <p className="red-text">No longer available</p>
                    )
                } else if (project && project.declinedBy && project.declinedBy.includes(operatorId)) {
                    selectedOperatorStatus = (
                        <p className="red-text">No longer available</p>
                    )
                } else if (project && project.deniedAfterAcceptingBy && project.deniedAfterAcceptingBy.includes(operatorId)) {
                    selectedOperatorStatus = (
                        <p className="red-text">No longer available</p>
                    )
                } else if (confirmedByOperatorId && confirmedByOperatorId === operatorId) {
                    selectedOperatorStatus = (
                        <p className="green-text">Confirmed ride</p>
                    )
                } else if (selectedOperator && selectedOperator === operatorId) {
                    selectedOperatorStatus = (
                        <p className="blue-text">Pending Operator confirmation</p>
                    )
                    buttonElement = (
                        <button className="rn-button-style--2 error-text"
                            onClick={() => this.props.cancelSelectOperatorFn(`${operatorProfile.firstName.toUpperCase()}`,
                                operatorId, operatorProfile.phoneNumber)}>Cancel IO</button>
                    )
                } else {
                    buttonElement = (
                        <button className="rn-button-style--2 btn-solid"
                            disabled={selectedOperator ? 'disabled' : null}
                            onClick={() => this.props.selectOperatorFn(`${operatorProfile.firstName.toUpperCase()}`,
                                operatorId, operatorProfile.phoneNumber)}>Select</button>
                    )
                }
                let reactStarId = operatorId + "starRating"
                return (
                    <Card variant="outlined" key={`operator${operatorId}`}>
                        <CardContent>
                            <div className="operator-response-section">
                                <div className="operator-response-section-photo">
                                    <img src={updatedProfileImageUrl || 'http://via.placeholder.com/200x200'} alt="profile" />
                                </div>
                                <div className="operator-response-section-personal-info">
                                    <h4>
                                        <u>{operatorProfile.firstName.toUpperCase()} {operatorProfile.lastName.toUpperCase()} </u>
                                        <span>
                                            <Tooltip title={
                                                <p>
                                                    Rides accepted: {acceptedRides} <br />
                                                    Rides completed: {confirmedRides} <br />
                                                    Rides cancelled: {cancelledConfirmedRides} <br />
                                                </p>
                                            }>
                                                <InfoIcon fontSize='small' />
                                            </Tooltip>
                                        </span>
                                    </h4>
                                    <h5>Ride Quote: ${operatorData ? operatorData.totalRate : project.calculatedPrice}
                                        <Tooltip title={
                                            <p>
                                                Recommended Rate: {project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}<br />
                                                Tolls: ${operatorData ? operatorData.additionalChargesTolls : '0.00'}<br />
                                                Parking: ${operatorData ? operatorData.additionalChargesParking : '0.00'}<br />
                                                Airport Fee: ${operatorData ? operatorData.additionalChargesAirportFee : '0.00'}<br />
                                                Other Charges: ${operatorData ? operatorData.additionalChargesMisc : '0.00'}<br />
                                            </p>
                                        }>
                                            <InfoIcon fontSize='small' />
                                        </Tooltip>
                                    </h5>
                                    {operatorProfile.numberOfRatings &&
                                        <>
                                            <ReactStars
                                                id={reactStarId}
                                                a11y={false}
                                                count={5}
                                                value={+operatorProfile.avgRating}
                                                size={16}
                                                isHalf={true}
                                                edit={false}
                                                activeColor="#D8B46C"
                                            />
                                            <p>{operatorProfile.avgRating} ({operatorProfile.numberOfRatings} reviews)</p>
                                        </>
                                    }
                                </div>
                                <div className="operator-response-section-ride-quote">
                                    <p>{operatorProfile.carYear} {operatorProfile.carColor.toUpperCase()} {operatorProfile.carMake.toUpperCase()} {operatorProfile.carModel.toUpperCase()}</p>
                                    <p><b>Vehicle Plate</b>: {operatorProfile.carPlate.toUpperCase()}</p>
                                    <p><b>Cancellation Policy</b>: {cancellationPolicy} hours prior to up time</p>
                                    <p><b>Special Notes</b>: {operatorData && operatorData.specialNotes ? operatorData.specialNotes : '-'}</p>
                                    <br />
                                    <p>{buttonElement}</p>
                                    {selectedOperatorStatus}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )

            }
        } else {
            return (
                <span>Loading</span>
            );
        }
    }
}


export default OperatorProfile
