import moment from 'moment';
import axios from 'axios'
import { baseURL } from "../../components/constants/CommonConstants";
import { generateRideId7 } from '../../components/util/rideUtils';

function isValid(element) {
  // console.log('validating - ' + element)
  return (element === null || element.toString().trim() !== '')
};

function updateHistory(firestore, projectId, userId, action) {
  firestore.collection('projects').doc(projectId).collection('history').add({
    timestamp: new Date(),
    user: userId,
    action: action
  });
}

function addChatMessage(firestore, projectId, userId, userName, msg) {
  firestore.collection('projects').doc(projectId).collection('chats').add({
    createdAt: new Date(),
    userId: userId,
    userName: userName,
    msg: msg
  });
}

async function createTask(firestore, data) {
  return firestore.collection('tasks').add({
    status: data.status,
    performAt: data.performAt,
    worker: data.worker,
    options: data.options,
    createdAt: new Date(),
  }).then(async (docRef) => {
    // console.log("TaskID: ", docRef.id);
    await firestore.collection('projects').doc(data.projectId).collection('tasks').add({
      taskId: docRef.id,
      status: data.status,
      performAt: data.performAt,
      worker: data.worker,
      options: data.options,
      createdAt: new Date(),
    });
    return docRef.id;
  }).catch(function (error) {
    // console.error("Error creating task: ", error);
    return null;
  });
}

function createUrgentTask(firestore, options) {
  // console.log('inside createUrgentTask - ', options)
  firestore.collection('urgentTasks').add({
    status: options.status,
    worker: options.worker,
    options: {
      projectId: options.projectId,
      amount: options.amount || 0
    },
    createdAt: new Date(),
  }).then(function (docRef) {
    // console.log("urgentTaskId: ", docRef.id);
    return docRef.id;
  }).catch(function (error) {
    // console.error("Error creating task: ", error);
  });
}

async function getLatestRideDetailsForOperator(firestore, projectId) {
  var ride = null;
  var rideDetails = await firestore.collection('projects').doc(projectId).get();
  if (rideDetails !== undefined && rideDetails.exists) {
    ride = rideDetails.data();
    if (ride.requestorPrice > 0.0) {
      ride.requestorBookingRate = ride.requestorPrice;
    } else {
      ride.requestorBookingRate = ride.calculatedPrice;
    }
    if (ride.authorId == null) {
      ride = 'BadData';
    } else {
      await firestore.collection('users').doc(ride.authorId).get()
        .then(doc => {
          var travelerDetails = doc.data();
          ride.travellerRequestedRideCount = (travelerDetails.requestedRides && travelerDetails.requestedRides.length) || 0
          ride.travellerCancelledRideCount = (travelerDetails.cancelledRides && travelerDetails.cancelledRides.length) || 0
        });


      await firestore.collection('projects').doc(projectId).collection('operatorData').get()
        .then(snapshot => {
          var operatorDataObj = {};
          snapshot.forEach(doc => {
            operatorDataObj[doc.id] = doc.data();
          });

          if (Object.keys(operatorDataObj).length > 0) {
            ride.operatorData = operatorDataObj;
          }
        });

      //load project chats
      await firestore.collection('projects').doc(projectId).collection('chats').orderBy("createdAt", "asc").get()
        .then(snapshot => {
          var chats = {};
          snapshot.forEach(doc => {
            chats[doc.id] = doc.data();
          });
          ride.chats = chats;
        });
    }
  } else {
    ride = 'BadData';
  }
  return ride;
}

export const clearRideDetails = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'CLEAR_RIDE_DETAILS' });
  }
};

async function getLatestRideDetailsForTraveler(firestore, projectId) {
  var ride = {};
  var rideDetails = await firestore.collection('projects').doc(projectId).get();
  if (rideDetails !== undefined && rideDetails.exists) {
    ride = rideDetails.data();
    if (ride.authorId === null) {
      ride = 'BadData';
      return ride;
    } else {
      var operatorProfile = {};
      const operatorsLoaded = (ride.acceptedBy && ride.acceptedBy.map(async (operatorId) => {
        const userProfile = await firestore.collection('users').doc(operatorId).get();
        operatorProfile[operatorId] = userProfile.data();
      })) || [];
      return Promise.all(operatorsLoaded).then(async (res) => {
        if (Object.keys(operatorProfile).length > 0) {
          ride.operatorProfile = operatorProfile;
        }
        //load operator accepted data
        await firestore.collection('projects').doc(projectId).collection('operatorData').get()
          .then(async (snapshot) => {
            var operatorDataObj = {};
            snapshot.forEach(doc => {
              operatorDataObj[doc.id] = doc.data();
            });
            if (Object.keys(operatorDataObj).length > 0) {
              ride.operatorData = operatorDataObj;
            }
          });

        //load project history
        await firestore.collection('projects').doc(projectId).collection('history').orderBy("timestamp", "desc").get()
          .then(snapshot => {
            var history = {};
            snapshot.forEach(doc => {
              history[doc.id] = doc.data();
            });
            if (Object.keys(history).length > 0) {
              ride.history = history;
            }
          });

        //get review
        if (ride.reviewId) {
          await firestore.collection('reviews').doc(ride.reviewId).get()
            .then(doc => {
              if (doc.exists) {
                ride.reviewDetails = doc.data();
              }
            });
        }

        //load project chats
        await firestore.collection('projects').doc(projectId).collection('chats').orderBy("createdAt", "desc").get()
          .then(snapshot => {
            var chats = {};
            snapshot.forEach(doc => {
              chats[doc.id] = doc.data();
            });
            ride.chats = chats;
          });

        // console.log('getLatestRideDetailsForTraveler -- ride -- ', ride)
        return ride;
      });
    }
  }
}

export const createProject = (project) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - create project', project)
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    const coeff = 1000 * 60 * 5;
    const minDateTime = new Date(Math.round((new Date().getTime() + 15 * 60000) / coeff) * coeff);
    if (!isValid(project.serviceFrom) || !isValid(project.serviceTo) || !isValid(project.serviceType)
      || !isValid(project.serviceDate)
      || !isValid(project.passengerFirstName)
      || !isValid(project.passengerLastName)
      || !isValid(project.passengerPhone)
      || !isValid(project.serviceVehicle) || !isValid(project.fromlat) || !isValid(project.fromlng)
      || !isValid(project.tolat) || !isValid(project.tolng) || !isValid(project.calculatedDistance)
      || !isValid(project.serviceFromState)) {
      // invalid ride details
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter all the required details and try again' });
    } else if (new Date(project.serviceDate) < minDateTime) {
      //validate service date time should be atleast 15 mins from now
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: `Service date-time should be at least by ${moment(minDateTime).format("dddd, MMMM Do, YYYY")} ${moment(minDateTime).format("LT")}` });
    } else if (project.validServiceFrom === false) {
      // validate pick up location
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter valid Pick-Up location' });
    } else if (project.validServiceTo === false) {
      //validate drop off location
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter valid Drop-Off location' });
    } else if ((project.serviceType === 'Point-to-Point') && (project.tolat === project.fromlat) && (project.tolng === project.fromlng)) {
      // validate pick and drop off location should not be same for point-to-point service type
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Pick-Up and Drop-Off location cannot be the same for Point-to-Point ride.' });
    } else if (project.passengerPhone.length !== 10) {
      // validate passengerPhone is 10 digits
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter a valid passenger phone number' });
    } else if (!isValid(project.selectedPaymentMethod)) {
      // validate payment method is selected
      dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please select a payment method' });
    } else {


      // preauthorize $5.00 ride booking fee
      let url = baseURL + '/newRideBookingPreauthorization';
      let data = {
        travelerId: authorId,
        paymentMethod: project.selectedPaymentMethod,
      }
      // console.log('payload of extraStopPaymentIntent API - ', data);
      let paymentIntentId = null;
      await axios.post(url, data)
        .then(async (response) => {
          paymentIntentId = response.data.id;
          // console.log('done with preauthorizations - paymentIntentId: ', paymentIntentId);

          let rideConfirmationCode = null;
          let uniqueRideConfirmationCode = false;
          while (uniqueRideConfirmationCode === false) {
            rideConfirmationCode = generateRideId7();
            // console.log('rideConfirmationCode = ', rideConfirmationCode);
            // check if rideId is unique
            await firestore.collection('projects').where('rideConfirmationCode', '==', rideConfirmationCode).get()
              .then((snapshot) => {
                if (snapshot.empty) {
                  uniqueRideConfirmationCode = true;
                }
                return;
              })
              .catch((error) => {
                // console.log('Error validating rideConfirmationCode :', rideConfirmationCode, error);
                return;
              });
          }
          //step 1 - create a new ride
          return await firestore.collection('projects').add({
            rideConfirmationCode: rideConfirmationCode,
            serviceFrom: project.serviceFrom,
            serviceFromShort: project.serviceFromShort,
            serviceTo: project.serviceTo,
            serviceToShort: project.serviceToShort,
            serviceDate: project.serviceDate,
            serviceType: project.serviceType,
            serviceHours: project.serviceHours,
            serviceVehicle: project.serviceVehicle,
            specialNotes: project.specialNotes,
            fromlat: project.fromlat,
            fromlng: project.fromlng,
            tolat: project.tolat,
            tolng: project.tolng,
            calculatedDistance: project.calculatedDistance,
            calculatedPrice: project.calculatedPrice[project.serviceVehicle],
            serviceFromState: project.serviceFromState,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName,
            authorId: authorId,
            status: 'PENDING',
            createdAt: new Date(),
            availableOperators: [],
            acceptedBy: [],
            paymentMethod: project.selectedPaymentMethod,
            passengerFirstName: project.passengerFirstName,
            passengerLastName: project.passengerLastName,
            passengerPhone: project.passengerPhone,
            requestorPrice: project.requestorPrice,
            calculatedRideDuration: project.calculatedRideDuration,
          })
            .then(async (res) => {
              let id = res.id;
              // console.log('projectid - ', id);

              //step 2 - update project history
              let action = profile.firstName + " requested a ride";
              await updateHistory(firestore, id, authorId, action)

              // create a task for auto cancellation charges
              let performAt = new Date(project.serviceDate);
              var taskOptions = {
                status: 'PENDING',
                performAt: performAt,
                worker: 'autoCancellation',
                options: {
                  projectId: id,
                }
              }
              // console.log('taskOptions - ', taskOptions);
              await createTask(firestore, taskOptions);

              // create firestore document
              let amount = 5; // $5.00
              console.log('paymentIntentId = ', paymentIntentId);
              await firestore.collection('projects').doc(id).collection('paymentIntents').doc(paymentIntentId).set({
                status: 'CONFIRMED',
                type: 'rideBookingPreauthorization',
                amount: amount,
                createdAt: new Date()
              });

              //step 3 - update user profile
              return firestore.collection('users').doc(authorId).update({
                requestedRides: firestore.FieldValue.arrayUnion(id)
              }).then((res2) => {
                // console.log('created successfully');
                dispatch({ type: 'CREATE_PROJECT_SUCCESS', id });
                return;
              }).catch(err2 => {
                // console.log('error while updating user requestedRides -', err2);
                dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'An error occurred. Please try again.' });
                return;
              });
            }).catch(err => {
              // console.log('error occurred while creating project - ', err)
              dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'An error occurred. Please try again.' });
              return;
            });
        })
        .catch(err => {
          // console.log(' paymentIntent failed', err);
          dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Selected payment method is invalid. Please use another payment method.' });
          return;
        })
    }
  }
};

export const denyRideAction = (project) => {
  // this is for rides which are not accepted by operator
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - denyRideAction', project)
    const firestore = getFirestore();
    const operatorId = project.deniedByOperatorId;
    const projectId = project.projectId

    //step 1 - update rides - denied by
    firestore.collection('projects').doc(projectId).update({
      deniedBy: firestore.FieldValue.arrayUnion(operatorId),
    }).then((res) => {

      //step 2 - update operator profile - denied rides
      firestore.collection('users').doc(operatorId).update({
        deniedRides: firestore.FieldValue.arrayUnion(projectId)
      }).then(async (res) => {
        //get latest ride details
        let ride = await getLatestRideDetailsForOperator(firestore, projectId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          dispatch({ type: 'SUCCESSFULLY_DENIED_RIDE', updatedProject: ride });
        }
      }).catch(err => {
        dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
    });
  }
};

export const acceptRideAction = (project) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('Inside acceptRideAction - ', project);
    const firestore = getFirestore();
    const operatorId = project.acceptedByOperatorId;
    const projectId = project.projectId

    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    let promiseList = [];

    // const createdAtPlus5Mins = new Date(Math.round(project.createdAt.getTime() + 5 * 60 * 1000));
    let createdAtPlus5Mins = new Date(projectDetails.data().createdAt.toDate());
    createdAtPlus5Mins.setHours(createdAtPlus5Mins.getMinutes() + 5);

    // promise 1 - update ride - accepted rides
    if ((projectDetails.data().acceptedBy && projectDetails.data().acceptedBy.length === 0)
      || (projectDetails.data().availableOperators && projectDetails.data().availableOperators.length === 0)) {
      // first operator responding - 
      let firstOperatorRespondedWithin5Mins = false;
      if (new Date().getTime() <= createdAtPlus5Mins) {
        firstOperatorRespondedWithin5Mins = true;
      }
      promiseList.push(firestore.collection('projects').doc(projectId).update({
        acceptedBy: firestore.FieldValue.arrayUnion(operatorId),
        availableOperators: firestore.FieldValue.arrayUnion(operatorId),
        firstOperatorRespondedWithin5Mins: firstOperatorRespondedWithin5Mins
      }));
    } else {
      promiseList.push(firestore.collection('projects').doc(projectId).update({
        acceptedBy: firestore.FieldValue.arrayUnion(operatorId),
        availableOperators: firestore.FieldValue.arrayUnion(operatorId),
      }));
    }

    // promise 2 - update current operatorData for this ride
    promiseList.push(firestore.collection('projects').doc(projectId).collection('operatorData').doc(operatorId).set({
      additionalChargesTolls: project.additionalChargesTolls,
      additionalChargesParking: project.additionalChargesParking,
      additionalChargesAirportFee: project.additionalChargesAirportFee,
      additionalChargesMisc: project.additionalChargesMisc,
      totalRate: project.totalRate,
      specialNotes: project.specialNotes.length > 120 ? project.specialNotes.substring(0, 120) : project.specialNotes,
      createdAt: new Date(),
      cancellationPolicy: operatorDetails.data().cancellationPolicy
    }));

    // promise 3- update project history
    let action = operatorDetails.data().firstName + " accepted this ride";
    promiseList.push(updateHistory(firestore, projectId, operatorId, action));

    // promise 4 - update operator profile - accepted rides
    promiseList.push(firestore.collection('users').doc(operatorId).update({
      acceptedRides: firestore.FieldValue.arrayUnion(projectId)
    }));

    // promise 5 - get latest ride details for operator
    promiseList.push(getLatestRideDetailsForOperator(firestore, projectId));

    Promise.all(promiseList).then((results) => {
      let ride = results[4];
      if (ride === 'BadData') {
        dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
      } else {
        let sendTravelerRideAcceptedMsg = false;
        if (ride.acceptedBy.length === 1) {
          sendTravelerRideAcceptedMsg = true;
        }
        dispatch({
          type: 'SUCCESSFULLY_ACCEPTED_RIDE',
          projectId: projectId,
          updatedProject: ride,
          operatorName: operatorDetails.data().firstName,
          operatorPhone: operatorDetails.data().phoneNumber,
          requestorPhone: requestorDetails.data().phoneNumber,
          sendTravelerRideAcceptedMsg: sendTravelerRideAcceptedMsg
        })
      }
    })
  }
}

export const denyAcceptedRideAction = (project) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - denyAcceptedRideAction', project)
    const firestore = getFirestore();
    const operatorId = project.deniedAcceptedRideByOperatorId;
    const projectId = project.projectId

    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    //step 1 - update ride - denied rides after accepting
    firestore.collection('projects').doc(projectId).update({
      deniedAfterAcceptingBy: firestore.FieldValue.arrayUnion(operatorId),
      availableOperators: firestore.FieldValue.arrayRemove(operatorId)
    }).then(async (res) => {

      //step 2 - update project history
      let action = operatorDetails.data().firstName + " is no longer available for this ride";
      await updateHistory(firestore, projectId, operatorId, action)

      //step 3 - update operator profile - denied ride after accpeting
      firestore.collection('users').doc(operatorId).update({
        deniedRidesAfterAccepting: firestore.FieldValue.arrayUnion(projectId)
      }).then(async (res) => {
        //get latest ride details
        let ride = await getLatestRideDetailsForOperator(firestore, projectId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          dispatch({ type: 'SUCCESSFULLY_DENIED_RIDE', updatedProject: ride });
        }
      }).catch(err => {
        dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
    });
  }
};

export const operatorSelectedAction = (operatorSelected) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - operatorSelectedAction', operatorSelected)
    const firestore = getFirestore();
    const operatorId = operatorSelected.selectedOperatorId;
    const projectId = operatorSelected.projectId
    const operatorPhone = operatorSelected.selectedOperatorPhone;
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    firestore.collection('projects').doc(projectId).update({
      selectedOperatorId: operatorId,
      status: 'PENDING'
    }).then(async (data) => {

      //update project history
      let action = operatorDetails.data().firstName + " is selected for this ride";
      await updateHistory(firestore, projectId, operatorId, action)

      //get latest ride details
      let ride = await getLatestRideDetailsForTraveler(firestore, projectId);

      // console.log('ride --- ', ride);

      if (ride === 'BadData') {
        dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
      } else {
        dispatch({
          type: 'SEND_SELECTED_OPERATOR_MSG',
          id: projectId,
          phone: operatorPhone,
          updatedProject: ride
        });
      }
    }).catch(err => {
      dispatch({ type: 'ERROR_ACCEPTING_RIDE' }, err);
    });
  }
};

export const cancelSelectedOperator = (operatorCancelled) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - cancelSelectedOperator', operatorCancelled)
    const firestore = getFirestore();
    const projectId = operatorCancelled.projectId
    const operatorPhone = operatorCancelled.selectedOperatorPhone;
    const operatorId = operatorCancelled.selectedOperatorId;
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    firestore.collection('projects').doc(projectId).update({
      selectedOperatorId: null,
      status: 'PENDING',
      unselectedOperators: firestore.FieldValue.arrayUnion(operatorId)
    }).then(async (data) => {
      //update project history
      let action = operatorDetails.data().firstName + " is un-selected for this ride";
      await updateHistory(firestore, projectId, operatorId, action)

      //get latest ride details
      let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
      if (ride === 'BadData') {
        dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
      } else {
        dispatch({
          type: 'CANCEL_SELECTED_OPERATOR_MSG',
          id: projectId,
          phone: operatorPhone,
          updatedProject: ride
        });
      }
    }).catch(err => {
      dispatch({ type: 'ERROR_ACCEPTING_RIDE' }, err);
    });
  }
};

export const confirmRideAction = (inputObj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - confirmRideAction', inputObj)
    const firestore = getFirestore();
    const operatorId = inputObj.confirmedByOperatorId;
    const projectId = inputObj.projectId

    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    //step 1 - update ride - confirmed by
    firestore.collection('projects').doc(projectId).update({
      confirmedByOperatorId: operatorId,
      status: 'CONFIRMED'
    }).then(async (data) => {

      //step 2 - update project history
      let action = operatorDetails.data().firstName + " has confirmed this ride";
      await updateHistory(firestore, projectId, operatorId, action)

      //get amount to preauthorize
      let operatorDataObj = await firestore.collection('projects').doc(projectId).collection('operatorData').doc(operatorId).get()
      let operatorData = operatorDataObj.data();

      //step 3 - create task
      let performAt = new Date(projectDetails.data().serviceDate.toDate());
      // console.log('performAt as serviceDate- ', performAt);

      performAt.setHours(performAt.getHours() - 48);
      // console.log('performAt - 48hours from service date - ', performAt);

      if (performAt.getTime() < new Date().getTime()) {
        // console.log('performAt is in the past, so preauthorize now');
        var urgentTaskOptions = {
          status: 'PENDING',
          worker: 'preauthorize',
          projectId: projectId,
          amount: operatorData.totalRate
        }
        // console.log('urgentTaskOptions - ', urgentTaskOptions)
        await createUrgentTask(firestore, urgentTaskOptions);
      } else {
        // console.log('performAt is in the future, so preauthorize later');
        var taskOptions = {
          status: 'PENDING',
          performAt: performAt,
          worker: 'preauthorize',
          options: {
            projectId: projectId,
            amount: operatorData.totalRate,
          }
        }
        // console.log('taskOptions - ', taskOptions);
        await createTask(firestore, taskOptions);
      }

      //step 4 - update operator profile - denied rides
      firestore.collection('users').doc(operatorId).update({
        confirmedRides: firestore.FieldValue.arrayUnion(projectId)
      }).then(async (res) => {

        //get latest ride details
        let ride = await getLatestRideDetailsForOperator(firestore, projectId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          dispatch({
            type: 'SUCCESSFULLY_CONFIRMED_RIDE',
            projectId: projectId,
            updatedProject: ride,
            operatorName: operatorDetails.data().firstName,
            operatorPhone: operatorDetails.data().phoneNumber,
            requestorPhone: requestorDetails.data().phoneNumber
          });
        }
      }).catch(err => {
        dispatch({ type: 'ERROR_CONFIRMING_RIDE' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'ERROR_CONFIRMING_RIDE' }, err);
    });
  }
};

export const declineRideConfirmationAction = (project) => {
  // this is when selected operator declines to confirm the ride
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - declineRideConfirmationAction', project)
    const firestore = getFirestore();
    const operatorId = project.declinedByOperatorId;
    const projectId = project.projectId

    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    //step 1 - update ride - denied rides by
    firestore.collection('projects').doc(projectId).update({
      declinedBy: firestore.FieldValue.arrayUnion(operatorId),
      availableOperators: firestore.FieldValue.arrayRemove(operatorId),
      confirmedByOperatorId: null,
      selectedOperatorId: null,
      status: 'PENDING'
    }).then(async (res) => {

      //step 2 - update project history
      let action = operatorDetails.data().firstName + " is no longer available for this ride";
      await updateHistory(firestore, projectId, operatorId, action)

      //step 2 - update operator profile denied by operator
      firestore.collection('users').doc(operatorId).update({
        declinedRides: firestore.FieldValue.arrayUnion(projectId)
      }).then(async (res) => {

        //get latest ride details
        let ride = await getLatestRideDetailsForOperator(firestore, projectId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          dispatch({
            type: 'OPERATOR_DECLINED_CONFIRMATION',
            projectId: projectId,
            updatedProject: ride,
            operatorName: operatorDetails.data().firstName,
            operatorPhone: operatorDetails.data().phoneNumber,
            requestorPhone: requestorDetails.data().phoneNumber
          });
        }
      }).catch(err => {
        dispatch({ type: 'OPERATOR_DECLINED_CONFIRMATION_ERR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'OPERATOR_DECLINED_CONFIRMATION_ERR' }, err);
    });
  }
};

export const cancelConfirmedRideAction = (project) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - cancelConfirmedRideAction', project)
    const firestore = getFirestore();
    const operatorId = project.cancelConfirmedRideByOperatorId;
    const projectId = project.projectId

    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();

    //step 1 - update operator's profile - denied rides
    firestore.collection('projects').doc(projectId).update({
      cancelledConfirmedRideBy: firestore.FieldValue.arrayUnion(operatorId),
      availableOperators: firestore.FieldValue.arrayRemove(operatorId),
      confirmedByOperatorId: null,
      selectedOperatorId: null,
      status: 'PENDING'
    }).then(async (res) => {

      //step 2 - update project history
      let action = operatorDetails.data().firstName + " is no longer available for this ride";
      await updateHistory(firestore, projectId, operatorId, action)

      //step 3 - update ride details - denied by operator
      firestore.collection('users').doc(operatorId).update({
        cancelledConfirmedRides: firestore.FieldValue.arrayUnion(projectId)
      }).then(async (res) => {

        //get latest ride details
        let ride = await getLatestRideDetailsForOperator(firestore, projectId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          dispatch({
            type: 'OPERATOR_CANCELLED_RIDE',
            projectId: projectId,
            updatedProject: ride,
            operatorName: operatorDetails.data().firstName,
            operatorPhone: operatorDetails.data().phoneNumber,
            requestorPhone: requestorDetails.data().phoneNumber
          });
        }
      }).catch(err => {
        dispatch({ type: 'OPERATOR_CANCELLED_RIDE_ERR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'OPERATOR_CANCELLED_RIDE_ERR' }, err);
    });
  }
};

export const cancelRideByTraveler = (project) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - cancelRideByTraveler', project)
    const firestore = getFirestore();
    const projectId = project.projectId
    const requestorId = project.requestorId
    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    var requestorDetails = await firestore.collection('users').doc(requestorId).get();
    var operatorDetails = null;
    // console.log('projectDetails.data().confirmedByOperatorId  - ', projectDetails.data().confirmedByOperatorId)
    if (projectDetails && projectDetails.data().confirmedByOperatorId && projectDetails.data().confirmedByOperatorId !== null) {
      operatorDetails = await firestore.collection('users').doc(projectDetails.data().confirmedByOperatorId).get();
    }
    let promiseList = [];
    // update project status
    promiseList.push(firestore.collection('projects').doc(projectId).update({
      status: 'CANCELLED'
    }));
    //update project history
    promiseList.push(updateHistory(firestore, projectId, requestorId, requestorDetails.data().firstName + " cancelled this ride"));
    // create task to check if user needs to be charged
    promiseList.push(createUrgentTask(firestore, {
      status: 'PENDING',
      worker: 'travelerCancelledRide',
      projectId: projectId,
    }));
    // update requestor cancelledRides
    promiseList.push(firestore.collection('users').doc(requestorId).update({
      cancelledRides: firestore.FieldValue.arrayUnion(projectId)
    }));
    Promise.all(promiseList).then(() => {
      getLatestRideDetailsForTraveler(firestore, projectId)
        .then(ride => {
          if (ride === 'BadData') {
            dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
          } else {
            dispatch({
              type: 'SUCCESSFULLY_CANCELLED_RIDE',
              projectId: projectId,
              operatorPhone: (operatorDetails !== null) ? operatorDetails.data().phoneNumber : null,
              requestorPhone: requestorDetails.data().phoneNumber,
              updatedProject: ride
            });
          }
        })
        .catch(err => {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        })
    });
  };
}

export const clearCreatedProjectStatus = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'CLEAR_PROJECT_STATUS' });
  }
}

export const getNewUpcomingRides = (obj) => {
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getNewUpcomingRides for state: ', obj)
    const firestore = getFirestore();
    let upcomingProjects = [];
    if (obj.operatorUsState) {
      firestore.collection('projects')
        .where('serviceDate', '>=', new Date())
        .where('serviceFromState', '==', obj.operatorUsState)
        .orderBy('serviceDate')
        .limit(100) // TODO : currently get 100 records only
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            dispatch({ type: 'ERROR_FETCHING_UPCOMING_RIDES', errorMsg: 'No rides found' });
          } else {
            snapshot.forEach(doc => {
              let tmp = doc.data();
              // console.log('tmp - ', doc.id, tmp.serviceVehicle)
              if (tmp.status === 'PENDING') {
                // filter new rides by the operator carType
                if (obj.carType === 'Sedan' && ['Sedan'].includes(tmp.serviceVehicle)) {
                  // Sedan operator can view only Sedan rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } if (obj.carType === 'SUV' && ['SUV', 'Sedan'].includes(tmp.serviceVehicle)) {
                  // SUV operator can view Sedan & SUV rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (obj.carType === 'LuxurySedan' && ['LuxurySedan', 'Sedan'].includes(tmp.serviceVehicle)) {
                  // LuxurySedan operator can view Sedan & LuxurySedan rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (['PassengerVan', 'CorporateSprinter', 'LimoSprinter'].includes(obj.carType) && ['PassengerVan', 'CorporateSprinter', 'LimoSprinter'].includes(tmp.serviceVehicle)) {
                  // PassengerVan/CorporateSprinter/LimoSprinter operators can view PassengerVan/CorporateSprinter/LimoSprinter rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (obj.carType === 'Passenger24Bus' && ['Passenger24Bus', 'Passenger34Bus', 'Passenger55CoachBus'].includes(tmp.serviceVehicle)) {
                  // Passenger24Bus operator can view Passenger24Bus, Passenger34Bus & Passenger55CoachBus rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (obj.carType === 'Passenger34Bus' && ['Passenger34Bus', 'Passenger55CoachBus'].includes(tmp.serviceVehicle)) {
                  // Passenger34Bus operator can view Passenger34Bus & Passenger55CoachBus rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (obj.carType === 'Passenger55CoachBus' && ['Passenger55CoachBus'].includes(tmp.serviceVehicle)) {
                  // Passenger55CoachBus operator can view Passenger55CoachBus rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (obj.carType === 'StretchLimo' && ['StretchLimo', 'StretchSUV'].includes(tmp.serviceVehicle)) {
                  // StretchLimo operator can view StretchLimo &  StretchSUV rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                } else if (obj.carType === 'StretchSUV' && ['StretchSUV'].includes(tmp.serviceVehicle)) {
                  // StretchSUV operator can view StretchSUV rides
                  tmp.id = doc.id;
                  upcomingProjects.push(tmp);
                }
                // else if (obj.carType === tmp.serviceVehicle) {
                //   // other vehicle types (example - operator with sedan should only see new sedan rides)
                //   tmp.id = doc.id;
                //   upcomingProjects.push(tmp);
                // }
              }
            });
            // console.log('upcomingProjects = ', upcomingProjects);
            dispatch({ type: 'UPCOMING_RIDES_FETCHED_SUCCESSFULLY', upcomingProjects: upcomingProjects });
          }
        })
        .catch(err => {
          dispatch({ type: 'ERROR_FETCHING_UPCOMING_RIDES', errorMsg: 'Error occurred while getting rides for ' + obj.operatorUsState });
        });
    } else {
      dispatch({ type: 'ERROR_FETCHING_UPCOMING_RIDES', errorMsg: 'State is empty' });
    }
  }
};

export const getTravelerRequestedRides = (uid) => {
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getTravelerRequestedRides for uid: ', uid)
    const firestore = getFirestore();
    let requestedProjects = [];
    if (uid) {
      firestore.collection('projects')
        .where('authorId', '==', uid)
        .orderBy('serviceDate', 'desc')
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            // console.log('getRequestedRides - No matching documents.');
            dispatch({ type: 'TRAVELER_REQUESTED_RIDES_FETCHED_SUCCESSFULLY', travelerRequestedProjects: requestedProjects });
          } else {
            // console.log('requested rides', snapshot)
            snapshot.forEach(doc => {
              // console.log(doc.id, '=>', doc.data());
              // requestedProjects[doc.id]=doc.data();
              let tmp = doc.data();
              if (moment(new Date()) > moment(tmp.serviceDate.toDate())) {
                if (tmp.status === 'PENDING' && tmp.acceptedBy && tmp.acceptedBy.length > 0) {
                  tmp.status = 'ACCEPTED'
                }
                tmp.id = doc.id;
                requestedProjects.push(tmp);
              }
            });
            // console.log('getRequestedRides - requestedProjects - ',requestedProjects)
            dispatch({ type: 'TRAVELER_REQUESTED_RIDES_FETCHED_SUCCESSFULLY', travelerRequestedProjects: requestedProjects });
          }
        })
        .catch(err => {
          // console.log('getRequestedRides - Error occurred - ' + err)
          dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'Error occurred while getting requested rides' });
        });
    } else {
      // console.log('getRequestedRides - No UID');
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};

export const getTravelerPendingApprovalRides = (uid) => {
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getTravelerPendingApprovalRides for uid: ', uid)
    const firestore = getFirestore();
    let requestedProjects = [];
    if (uid) {
      firestore.collection('projects')
        .where('authorId', '==', uid)
        .orderBy('serviceDate', 'desc')
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            // console.log('getPendingApprovalRides - No matching documents.');
            dispatch({ type: 'TRAVELER_PENDING_APPROVAL_RIDES_FETCHED_SUCCESSFULLY', travelerPendingApprovalRides: requestedProjects });
          } else {
            // console.log('requested rides', snapshot)
            snapshot.forEach(doc => {
              // console.log(doc.id, '=>', doc.data());
              // requestedProjects[doc.id]=doc.data();
              let tmp = doc.data();
              if (tmp.status === 'PENDING' && moment(new Date()) < moment(tmp.serviceDate.toDate())) {
                if (tmp.acceptedBy && tmp.acceptedBy.length > 0) {
                  tmp.status = 'ACCEPTED'
                }
                tmp.id = doc.id;
                requestedProjects.push(tmp)
              }
            });
            // console.log('getPendingApprovalRides - requestedProjects - ',requestedProjects)
            dispatch({ type: 'TRAVELER_PENDING_APPROVAL_RIDES_FETCHED_SUCCESSFULLY', travelerPendingApprovalRides: requestedProjects });
          }
        })
        .catch(err => {
          // console.log('getPendingApprovalRides - Error occurred - ' + err)
          dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'Error occurred while getting requested rides' });
        });
    } else {
      // console.log('getPendingApprovalRides - No UID');
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};


export const getAcceptedPendingTravelerApprovalRidesData = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getAcceptedPendingTravelerApprovalRidesData for uid: ', uid)
    const firestore = getFirestore();
    let pendingAcceptedRides = [];
    var operatorDetails = await firestore.collection('users').doc(uid).get();
    if (operatorDetails != null && operatorDetails.data()) {
      let acceptedRidesIdList = operatorDetails.data().acceptedRides || [];
      for (const rideId of acceptedRidesIdList) {
        let projectDetails = await firestore.collection('projects').doc(rideId).get();
        let tmp = projectDetails.data();

        if (tmp.status === 'PENDING' && moment(new Date()) < moment(tmp.serviceDate.toDate())) {
          tmp.id = projectDetails.id;
          pendingAcceptedRides.push(tmp);
        }
      };
      dispatch({ type: 'PENDING_ACCEPTED_RIDES_FETCHED_SUCCESSFULLY', pendingAcceptedRides: pendingAcceptedRides });
    } else {
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};

export const getRideDetails = (projectId) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside getRideDetails')
    const firestore = getFirestore();

    let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
    dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
  }
};

export const getRideDetailsForOperator = (projectId) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside getRideDetailsForOperator')
    const firestore = getFirestore();

    let ride = await getLatestRideDetailsForOperator(firestore, projectId);
    dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
  }
};

export const getMyAcceptedRidesAction = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getMyAcceptedRidesAction for uid: ', uid)
    const firestore = getFirestore();
    let acceptedRides = [];
    var operatorDetails = await firestore.collection('users').doc(uid).get();
    if (operatorDetails != null && operatorDetails.data()) {
      let acceptedRidesIdList = operatorDetails.data().acceptedRides || [];
      for (const rideId of acceptedRidesIdList) {
        let projectDetails = await firestore.collection('projects').doc(rideId).get();
        let tmp = projectDetails.data();
        tmp.id = projectDetails.id;
        acceptedRides.push(tmp);
      };
      dispatch({ type: 'ACCEPTED_RIDES_FETCHED_SUCCESSFULLY', acceptedRides: acceptedRides });
    } else {
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};

export const getOperatorPastRideHistoryAction = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getOperatorPastRideHistoryAction for uid: ', uid)
    const firestore = getFirestore();
    let operatorPastRides = [];
    var operatorDetails = await firestore.collection('users').doc(uid).get();
    if (operatorDetails != null && operatorDetails.data()) {
      let operatorPastRidesIdList = operatorDetails.data().confirmedRides || [];
      for (const rideId of operatorPastRidesIdList) {
        let projectDetails = await firestore.collection('projects').doc(rideId).get();
        let tmp = projectDetails.data();

        if (moment(new Date()) > moment(tmp.serviceDate.toDate())) {
          tmp.id = projectDetails.id;
          operatorPastRides.push(tmp);
        }

      };
      dispatch({ type: 'OPERATOR_PAST_RIDES_FETCHED_SUCCESSFULLY', operatorPastRides: operatorPastRides });
    } else {
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};


export const getOperatorUpcomingConfirmedRides = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getOperatorUpcomingConfirmedRides for uid: ', uid)
    const firestore = getFirestore();
    let operatorUpcomingRides = [];
    var operatorDetails = await firestore.collection('users').doc(uid).get();
    if (operatorDetails != null && operatorDetails.data()) {
      let operatorUpcomingRidesIdList = operatorDetails.data().confirmedRides || [];
      for (const rideId of operatorUpcomingRidesIdList) {
        let projectDetails = await firestore.collection('projects').doc(rideId).get();
        let tmp = projectDetails.data();
        // console.log(rideId, ' - days = ', moment(new Date()).diff(moment(tmp.serviceDate.toDate()), 'days'))
        if ((moment(tmp.serviceDate.toDate()).diff(moment(new Date()), 'days') >= 0)) {
          tmp.id = projectDetails.id;
          operatorUpcomingRides.push(tmp);
        }
      };
      // console.log('confirmedRides = ', confirmedRides);
      dispatch({ type: 'OPERATOR_UPCOMING_RIDES_FETCHED_SUCCESSFULLY', operatorUpcomingRides: operatorUpcomingRides });
    } else {
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};

export const updateOperatorCurrentRideStatus = (project) => {
  // console.log('inside updateOperatorCurrentRideStatus action ', project);
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const operatorId = project.operatorId;
    const projectId = project.projectId
    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();
    let action = '';
    let msg = '';

    // update ride - currentRideStatus, set history action and text message content for traveler
    switch (project.currentRideStatus) {
      case 'OPERATOR_ON_WAY_TO_PICKUP_LOCATION':
        action = operatorDetails.data().firstName + " is on the way to ride pick up location. ";
        msg = "Your FINDANIO operator " + operatorDetails.data().firstName + " is on the way to ride pick up location. ";
        await firestore.collection('projects').doc(projectId).update({
          currentRideStatus: project.currentRideStatus,
        })
        break;
      case 'OPERATOR_ON_LOCATION':
        action = operatorDetails.data().firstName + " is at location. ";
        msg = "Your FINDANIO operator " + operatorDetails.data().firstName + " is at ride pick up location. ";
        await firestore.collection('projects').doc(projectId).update({
          currentRideStatus: project.currentRideStatus,
        })
        break;
      case 'PASSENGER_ON_BOARD':
        action = "Ride started (passenger on board)";
        msg = "Your FINDANIO ride has started. ";
        await firestore.collection('projects').doc(projectId).update({
          currentRideStatus: project.currentRideStatus,
        })
        break;
      case 'ADD_EXTRA_STOPS':
        action = operatorDetails.data().firstName + " has added an extra stop for your current ride. ";
        msg = "Your FINDANIO operator " + operatorDetails.data().firstName + " has added an extra stop for your current ride which needs to be approved. ";
        await firestore.collection('projects').doc(projectId).update({
          currentRideStatus: project.currentRideStatus,
        })
        var operatorData = await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).get();
        let index = (operatorData.data() && operatorData.data().extraStops && Object.getOwnPropertyNames(operatorData.data().extraStops).length) || 0;
        // console.log('index = ', index)
        await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).update({
          [`extraStops.${index}`]: {
            extraStopsNotes: project.extraStopsNotes,
            extraStopsCharges: project.extraStopsCharges,
            status: 'PENDING TRAVELER APPROVAL'
          }
        })
        break;
      case 'RIDE_ENDED':
        action = "Ride ended";
        msg = "Your FINDANIO ride has completed. Please verify final charges. ";
        await firestore.collection('projects').doc(projectId).update({
          currentRideStatus: project.currentRideStatus,
          status: 'COMPLETED',
          finalRate: project.totalRateWithStops,
        });
        //create task to capture all paymentIntents for the ride
        await createUrgentTask(firestore, {
          status: 'PENDING',
          worker: 'capturePreAuthorizations',
          projectId: projectId,
        });
        break;
      default: break;
    }

    // update project history
    await updateHistory(firestore, projectId, operatorId, action)
    let ride = await getLatestRideDetailsForOperator(firestore, projectId);

    // return updated ride details
    if (ride === 'BadData') {
      dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
    } else {
      dispatch({
        type: 'SUCCESSFULLY_UPDATED_RIDE_STATUS',
        projectId: projectId,
        updatedProject: ride,
        operatorName: operatorDetails.data().firstName,
        requestorPhone: requestorDetails.data().phoneNumber,
        msg: msg
      });
    }
  }
};

export const getTravelerUpcomingRides = (uid) => {
  return (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - getTravelerUpcomingRides for uid: ', uid)
    const firestore = getFirestore();
    let requestedProjects = [];
    if (uid) {
      firestore.collection('projects')
        .where('authorId', '==', uid)
        .where('serviceDate', '>=', new Date(new Date().setHours(0, 0, 0, 0)))
        .orderBy('serviceDate', 'asc')
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            // console.log('getRequestedRides - No matching documents.');
            dispatch({ type: 'UPCOMING_REQUESTED_RIDES_FETCHED_SUCCESSFULLY', travelerUpcomingProjects: requestedProjects });
          } else {
            // console.log('requested rides', snapshot)
            snapshot.forEach(doc => {
              // console.log(doc.id, '=>', doc.data());
              // requestedProjects[doc.id]=doc.data();
              let tmp = doc.data();
              if (tmp.status === 'CONFIRMED' && moment(new Date()) < moment(tmp.serviceDate.toDate())) {
                tmp.id = doc.id;
                requestedProjects.push(tmp)
              }
            });
            // console.log('getRequestedRides - requestedProjects - ',requestedProjects)
            dispatch({ type: 'UPCOMING_REQUESTED_RIDES_FETCHED_SUCCESSFULLY', travelerUpcomingProjects: requestedProjects });
          }
        })
        .catch(err => {
          // console.log('getRequestedRides - Error occurred - ' + err)
          dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'Error occurred while getting requested rides' });
        });
    } else {
      // console.log('getRequestedRides - No UID');
      dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
    }
  }
};

export const handleExtraStopByTraveler = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - handleExtraStopByTraveler : ', obj)
    const firestore = getFirestore();
    const index = obj.extraStop.index;
    const projectId = obj.projectId;
    var projectDetails = await firestore.collection('projects').doc(projectId).get();
    const operatorId = projectDetails.data().confirmedByOperatorId;
    var operatorDetails = await firestore.collection('users').doc(operatorId).get();
    let action = '';
    let msg = '';

    if (obj.action === 'accept') {
      action = 'Approved extra stop: $' + obj.extraStop.extraStopsCharges;
      msg = 'Extra stop charges accepted - ' + obj.extraStop.extraStopsCharges;
      //update extra charge as accepted
      await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).update({
        [`extraStops.${index}.status`]: 'APPROVED'
      })

      //create a payment intent of extra stop
      await firestore.collection('newRidePayment').add({
        paymentMethod: projectDetails.data().paymentMethod,
        amount: obj.extraStop.extraStopsCharges,
        requestorId: projectDetails.data().authorId,
        projectId: projectId,
        operatorId: operatorId,
        createdAt: new Date(),
      }).then(async (extraStopPayment) => {
        // console.log('extraStopPayment created - ', extraStopPayment.id)
        let url = baseURL + '/extraStopPaymentIntent';
        let data = {
          extraStopPaymentId: extraStopPayment.id,
        }
        // console.log('payload of extraStopPaymentIntent API - ', data);
        await axios.post(url, data)
          .then(response => {
            // let paymentIntentId = response.data.paymentIntentId;
            // console.log('done with extra stop payment- paymentIntentId: ', paymentIntentId);
            return;
          })
          .catch(err => {
            // console.log('extra stop paymentIntent failed', err);
            dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
            return;
          })
      }).catch(err => {
        // console.log('error occurred ', err);
        dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        return;
      });
    } else {
      action = 'Denied extra stop: $' + obj.extraStop.extraStopsCharges;
      msg = 'Extra stop charges denied - ' + obj.extraStop.extraStopsCharges;
      //update extra charge as denied
      await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).update({
        [`extraStops.${index}.status`]: 'DENIED'
      })
    }

    // update project history
    // console.log('update project history after handling extra stop')
    await updateHistory(firestore, projectId, operatorId, action)

    //return updated ride details
    let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
    // console.log('updated ride - ', ride);
    if (ride === 'BadData') {
      dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
    } else {
      dispatch({
        type: 'UPDATED_EXTRA_STOP_BY_REQUESTOR_SUCCESSFUL',
        projectId: projectId,
        updatedProject: ride,
        // msg: action,
        operatorPhone: operatorDetails.data().phoneNumber,
        msg: msg
      });
    }
  }

};

export const choosePreferredOperator = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - choosePreferredOperator : ', obj)
    const firestore = getFirestore();
    firestore.collection('users').doc(obj.requestorId).update({
      preferredOperators: firestore.FieldValue.arrayUnion(obj.operatorId)
    }).then(async (res) => {
      // console.log('updated preferred operator')
    }).catch(err => {
      dispatch({ type: 'ERROR' }, err);
    });
  }
};

export const saveRatingReview = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    // console.log('inside project actions - saveRatingReview : ', obj);
    const firestore = getFirestore();

    if (obj.reviewId) {
      //update existing review
      await firestore.collection('reviews').doc(obj.reviewId).update({
        rating: obj.rating,
        review: obj.review,
        updatedTimestamp: new Date()
      }).then(async () => {
        // console.log('updated review')
        let ride = await getLatestRideDetailsForTraveler(firestore, obj.rideId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          // console.log('retrieved ride details - ', ride)
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
        }
      })
    } else {
      //insert in reviews collection
      await firestore.collection('reviews').add({
        rating: obj.rating,
        review: obj.review,
        operatorId: obj.operatorId,
        requestorId: obj.requestorId,
        rideId: obj.rideId,
        timestamp: new Date()
      }).then(async (reviewDoc) => {
        // console.log('created review', reviewDoc.id);

        //update project reviewId
        await firestore.collection('projects').doc(obj.rideId).update({
          reviewId: reviewDoc.id
        });

        //update traveler profile - review given
        await firestore.collection('users').doc(obj.requestorId).update({
          reviewsGiven: firestore.FieldValue.arrayUnion(reviewDoc.id)
        });

        //update operator profile - review received
        await firestore.collection('users').doc(obj.operatorId).update({
          reviewsReceived: firestore.FieldValue.arrayUnion(reviewDoc.id)
        });

        let ride = await getLatestRideDetailsForTraveler(firestore, obj.rideId);
        if (ride === 'BadData') {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
        } else {
          dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
        }

      }).catch(err => {
        // console.log('error - ', err)
        dispatch({ type: 'ERROR' }, err);
      });
    }
  }
};

export const sendChatMessage = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    await addChatMessage(firestore, obj.projectId, obj.userId, obj.userName, obj.msg);

    let ride = await getLatestRideDetailsForTraveler(firestore, obj.projectId);
    if (ride === 'BadData') {
      dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
    } else {
      dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
    }
  }
};

export const getLocationStates = (obj) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('operatorLocations').get()
      .then(snapshot => {
        var locations = {};
        snapshot.forEach(stateData => {
          let stateId = stateData.id;
          let operators = stateData.data().operators;
          if (operators) {
            for (const vehicleType in operators) {
              if (operators.hasOwnProperty(vehicleType) && operators[vehicleType].length > 0 && !locations.hasOwnProperty(stateId)) {
                // console.log('state = ', stateId, ' | operators = ', operators, ' | vehicleType = ', vehicleType, ' | operators[vehicleType]= ', operators[vehicleType]);
                locations[stateId] = {
                  fill: "navy"
                };
              }
            }
          }
        });
        // console.log('getLocationStates -> locations = ', locations)
        dispatch({ type: 'RETRIEVED_OPERATOR_LOCATIONS', locations });
      })
      .catch(err => {
        dispatch({ type: 'RETRIEVED_OPERATOR_LOCATIONS', locations: {} });
      })
  }
};